import React from 'react';

function Title(props: {children: React.ReactChild | React.ReactChild[]}) {
  const { children } = props;
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '26px',
      fontWeight: 'bold',
    }}
    >
      {children}
    </div>
  );
}

export default Title;
