import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import styles from '../Css/Footer.module.css';
import hanatour from '../../meta/hanatourIcon.png';
import kakao from '../../meta/kakaotalkIcon.png';
import band from '../../meta/bandIcon.png';
import logo from '../../meta/logobig.png';

function Footer() {
  const { data } = useSWR('/api/landing/company', async (url) => {
    const res = await axios.get<{result:{
      company_name: string
      [x:string]: string
    }}>(url);
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  if (!data) return <div />;
  return (
    <div className={styles.footerDiv}>
      <div className={styles.footerInner}>
        <div className={styles.left}>
          <div className={styles.leftLinks}>
            <Link to="/notice/18">회사소개</Link>
            <Link to="/notice/19">찾아오시는 길</Link>
            <Link to="/notice/20">개인정보처리방침</Link>
            <Link to="/notice/21">이용약관</Link>
          </div>
          <div className={styles.leftInfos}>
            <img src={logo} alt="알프스여행사 로고" />
            <div className={styles.leftInfoDiv}>
              <div className={styles.leftInfoTop}>
                {`${data.company_name}  |  대표: ${data.ceo_name}\n사업자 등록번호: ${data.business_number}\n통신판매업신고번호: ${data.business_report}\n주소: ${data.post_code} ${data.address}\nFax: ${data.fax}  |  Email: ${data.email}`}
              </div>
              <div className={styles.leftCopyright}>
                {`ⓒ 2018 ${data.company_name} ALL RIGHTS RESERVED.`}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTitle}>고객센터 운영안내</div>
          <div className={styles.rightPhone}>{data.phone}</div>
          <div className={styles.rightDay}>{`평일 ${data.officehour_weekday}`}</div>
          <div className={styles.rightDay}>{`${data.officehour_holiday} ${data.officehour_saturday}`}</div>
          <div className={styles.rightLinks}>
            <a href="http://alps.hanatour.com"><img src={hanatour} alt="하나투어 코브랜드" /></a>
            <a href="http://pf.kakao.com/_xnukHC"><img src={kakao} alt="카카오 플러스친구" /></a>
            <a href="https://band.us/band/82768578"><img src={band} alt="밴드" /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
