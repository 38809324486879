import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Link, useNavigate } from 'react-router-dom';
import { ko } from 'date-fns/locale';
import useSWR from 'swr';
import { productI } from '../../Mobile/Page/Category';
import { dateObjToString, eachDataI, monthDataI } from '../../Mobile/Page/Product';
import styles from '../Css/EachProduct.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../Mobile/Css/Calendar.css';
import sstyles from '../../Mobile/Css/Product.module.css';

function Schedule(props: { info: productI }) {
  const { info } = props;
  const [DateObj, setDateObj] = useState<Date | null>(new Date(info.first_departure_date || ''));
  const [YearMonth, setYearMonth] = useState<[string, string]>(info.first_departure_date ? [info.first_departure_date.split('-')[0], info.first_departure_date.split('-')[1]] : [`${(new Date()).getFullYear()}`, `${(new Date()).getMonth() + 1}`]);
  const navigate = useNavigate();
  const { data: monthData } = useSWR(`/api/good/schedule?idx=${info.idx}&year=${YearMonth[0]}&month=${YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<{result: monthDataI[]}>(url);
    const { data } = res;
    return data.result.reduce<{[dateString:string]:monthDataI}>(
      (acc, cur) => ({ ...acc, [cur.departure_date]: cur }),
      {},
    );
  });
  const { data: eachData, error: eachError } = useSWR(`/api/good/schedule/info?idx=${info.idx}&date=${dateObjToString(DateObj)}`, async (url: string) => {
    const res = await axios.get<{result: eachDataI}>(url);
    const { data } = res;
    return data.result;
  });

  const changeMonth = (diff: number) => {
    const today = new Date();
    let year = parseInt(YearMonth[0], 10);
    let month = parseInt(YearMonth[1], 10);
    month += diff;
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
    if (today.getFullYear() > year
     || (today.getFullYear() === year && today.getMonth() + 1 > month)) return;
    const textMonth = `${month}`.length === 1 ? `0${month}` : `${month}`;
    setYearMonth([`${year}`, `${textMonth}`]);
  };
  const arrowSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
    </svg>
  );

  const renderCustomHeader = (pprops: ReactDatePickerCustomHeaderProps) => {
    const {
      monthDate,
      decreaseMonth, increaseMonth,
      prevMonthButtonDisabled, nextMonthButtonDisabled,
    } = pprops;
    return (
      <div className={sstyles.monthPickerDiv}>
        <button type="button" className={`${prevMonthButtonDisabled && sstyles.btnDisable}`} onClick={() => { if (prevMonthButtonDisabled) return; decreaseMonth(); changeMonth(-1); }}>{arrowSvg}</button>
        <div className={sstyles.monthPickerText}>{`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`}</div>
        <button type="button" className={`${nextMonthButtonDisabled && sstyles.btnDisable}`} onClick={() => { if (nextMonthButtonDisabled) return; increaseMonth(); changeMonth(1); }}>{arrowSvg}</button>
      </div>
    );
  };

  const renderDayContents = (dayOfMonth: number, dateDay: Date|undefined) => {
    if (!dateDay) return <div />;
    const dateString = dateObjToString(dateDay);
    let status = 4;
    if (monthData && monthData[dateString]) {
      status = monthData[dateString].state;
    }
    const style = { '--dotColor': ['#FF0057', '#ACD2FF', '#0068FF', '#191919', 'transparent'][status] } as React.CSSProperties;
    return (
      <div className={`${sstyles.calenDayDiv} ${(status === 4 || status === 3) && sstyles.dayDisabled}`} style={style}>
        {dateDay.getDate()}
        <div />
      </div>
    );
  };

  return (
    <div className={styles.scheduleEachDiv}>
      <div className={styles.calendarWrapper}>
        <ReactDatePicker
          selected={DateObj}
          onChange={(dt) => {
            if (monthData
              && monthData[dateObjToString(dt)]
              && monthData[dateObjToString(dt)].state !== 3) {
              setDateObj(dt);
            }
          }}
          locale={ko}
          dateFormat="Pp"
          inline
          className="asdf"
          renderDayContents={renderDayContents}
          minDate={new Date()}
          renderCustomHeader={renderCustomHeader}
          disabledKeyboardNavigation
        />
        <div className={sstyles.dotInfoDiv}>
          <div>
            <div style={{ backgroundColor: '#FF0057' }} />
            마감임박
          </div>
          <div>
            <div style={{ backgroundColor: '#ACD2FF' }} />
            예약가능
          </div>
          <div>
            <div style={{ backgroundColor: '#0068FF' }} />
            출발확정
          </div>
          <div>
            <div style={{ backgroundColor: '#191919' }} />
            예약마감
          </div>
        </div>
      </div>
      <div className={styles.scheduleInfoDiv}>
        <div className={styles.scheduleTitle}>여행일정</div>
        <div className={styles.scheduleInfo}>
          <div>{`출발일: ${eachData?.departure_start_time || ''}`}</div>
          <div>{`도착일: ${eachData?.arrival_end_time || ''}`}</div>
        </div>
        <div className={styles.scheduleTitle}>예약현황</div>
        <div className={styles.scheduleInfo}>
          <div>{`정원: ${eachData?.maximum || 0}명`}</div>
          <div>{`현재 예약 인원: ${eachData?.current || 0}명`}</div>
          <div>{`예약 가능 인원: ${(eachData?.maximum || 0) - (eachData?.current || 0)}명`}</div>
          <div>{`최소 출발 인원: ${eachData?.minimum || 0}명`}</div>
        </div>
      </div>
      <Link className={styles.reserveBtn} to={`/product/${info.idx}/${dateObjToString(DateObj)}`}>예약하기</Link>
    </div>
  );
}

function EachProduct(props: { info: productI }) {
  const { info } = props;
  const [Show, setShow] = useState(false);
  return (
    <>
      <div className={styles.EachProductDiv}>
        <div className={styles.EachProductInner}>
          <img src={info.thumb_image} alt={info.title} />
          <div className={styles.EachProductText}>
            <div className={styles.title}>{info.title}</div>
            <div className={styles.desc}>{info.description}</div>
            <div className={styles.price}>{`${info.price.toLocaleString()}원`}</div>
          </div>
        </div>
        <button type="button" onClick={() => setShow((d) => !d)} className={`${styles.moreButton} ${Show && styles.buttonShow}`}>출발일정보기</button>
      </div>
      <div className={`${styles.scheduleDiv} ${Show && styles.showDiv}`}>
        {
          info.first_departure_date
            ? <Schedule info={info} />
            : <div className={styles.noSchedule}>출발 가능한 일정이 없습니다.</div>
        }
      </div>
    </>
  );
}

export default EachProduct;
