/* eslint-disable react/require-default-props */
import React from 'react';
import argsToPX from '../../Middle/argsToPX';

function WidthBlocker(props: { children: React.ReactChild, width?: string | number }) {
  const { children, width } = props;
  return (
    <div style={{
      width: argsToPX(width || '400px'), display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
    >
      {children}
    </div>
  );
}

export default WidthBlocker;
