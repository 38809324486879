/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ko } from 'date-fns/locale';
import TopBar from '../Components/TopBar';
import 'react-datepicker/dist/react-datepicker.css';
import '../Css/Calendar.css';
import styles from '../Css/Product.module.css';
import useLogin from '../../Middle/useLogin';
import '../Css/ckeditor.css';

export interface monthDataI {
  idx: number,
  departure_date: string,
  arrival_date: string,
  maximum: number,
  minimum: number,
  current: number,
  state: number
}

export interface eachDataI {
  'good_idx': number,
  'title': string,
  'images': string[],
  'description': string,
  'idx': number,
  'departure_start_time': string,
  'departure_start_time_korea': number,
  'departure_end_time': string,
  'departure_end_time_korea': number,
  'departure_transportation': string,
  'arrival_start_time': string,
  'arrival_start_time_korea': number,
  'arrival_end_time': string,
  'arrival_end_time_korea': number,
  'arrival_transportation': string,
  description_perks: string
  description_include: string
  description_exclude: string
  description_notice: string
  schedule_text: string
  'minimum': number,
  'maximum': number,
  'current': number,
  'nights': number,
  'days': number,
  'options': {
    'idx': number,
    'option_name': string,
    'adult_price': number,
    'child_price': number|null,
    'infant_price': number|null,
    'baby_price': number|null,
    'maximum': number|null
  }[],
  'additional_option': {
    'idx': number,
    'option_name': string,
    'price': number,
    'maximum': number|null
  }[],
}

const statusToString = ['마감임박', '예약가능', '출발확정', '예약마감'];

export const dateObjToString = (date: Date|null) => {
  if (!date) return 'null';
  date.setHours(22);
  return date.toISOString().slice(0, 10);
};

function Product() {
  const { idx, date } = useParams();
  const { loginData } = useLogin();
  const [DateObj, setDateObj] = useState<Date | null>(new Date(`${date}`));
  useEffect(() => {
    setDateObj(new Date(`${date}`));
  }, [date]);
  const [YearMonth, setYearMonth] = useState<[string, string]>(date ? [date.slice(0, 4), date.slice(5, 7)] : ['0000', '00']);
  const [InfoPage, setInfoPage] = useState<'info'|'schedule'|'caution'>('info');
  const navigate = useNavigate();
  const { data: monthData } = useSWR(`/api/good/schedule?idx=${idx}&year=${YearMonth[0]}&month=${YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<{result: monthDataI[]}>(url);
    const { data } = res;
    const dataObj: {[dateString:string]:monthDataI} = {};
    data.result.map((ed) => {
      dataObj[ed.departure_date] = ed;
      return ed;
    });
    return dataObj;
  });
  const { data: eachData, error: eachError } = useSWR(`/api/good/schedule/info?idx=${idx}&date=${date}`, async (url: string) => {
    const res = await axios.get<{result: eachDataI}>(url);
    const { data } = res;
    return data.result;
  });
  useEffect(() => {
    if (eachError) navigate('/');
  }, [eachError]);

  const changeMonth = (diff: number) => {
    const today = new Date();
    let year = parseInt(YearMonth[0], 10);
    let month = parseInt(YearMonth[1], 10);
    month += diff;
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
    if (today.getFullYear() > year
     || (today.getFullYear() === year && today.getMonth() + 1 > month)) return;
    const textMonth = `${month}`.length === 1 ? `0${month}` : `${month}`;
    setYearMonth([`${year}`, `${textMonth}`]);
  };
  const arrowSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
    </svg>
  );

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
      monthDate,
      decreaseMonth, increaseMonth,
      prevMonthButtonDisabled, nextMonthButtonDisabled,
    } = props;
    return (
      <div className={styles.monthPickerDiv}>
        <button type="button" className={`${prevMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (prevMonthButtonDisabled) return; decreaseMonth(); changeMonth(-1); }}>{arrowSvg}</button>
        <div className={styles.monthPickerText}>{`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`}</div>
        <button type="button" className={`${nextMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (nextMonthButtonDisabled) return; increaseMonth(); changeMonth(1); }}>{arrowSvg}</button>
      </div>
    );
  };

  const renderDayContents = (dayOfMonth: number, dateDay: Date|undefined) => {
    if (!dateDay) return <div />;
    const dateString = dateObjToString(dateDay);
    let status = 4;
    if (monthData && monthData[dateString]) {
      status = monthData[dateString].state;
    }
    const style = { '--dotColor': ['#FF0057', '#ACD2FF', '#0068FF', '#191919', 'transparent'][status] } as React.CSSProperties;
    return (
      <div className={`${styles.calenDayDiv} ${(status === 4 || status === 3) && styles.dayDisabled}`} style={style}>
        {dateDay.getDate()}
        <div />
      </div>
    );
  };

  if (!eachData) {
    return <div />;
  }

  const InfoPageInfo = (
    <div className={styles.infoPageInfo}>
      <details>
        <summary className={styles.infoOpenDiv}>
          상품특전
          {arrowSvg}
        </summary>
        <div
          className={`${styles.infoPageInfoContents} ck-content`}
          dangerouslySetInnerHTML={{ __html: eachData.description_perks }}
        />
      </details>
      <details>
        <summary className={styles.infoOpenDiv}>
          포함사항
          {arrowSvg}
        </summary>
        <div
          className={`${styles.infoPageInfoContents} ck-content`}
          dangerouslySetInnerHTML={{ __html: eachData.description_include }}
        />
      </details>
      <details>
        <summary className={styles.infoOpenDiv}>
          불포함사항
          {arrowSvg}
        </summary>
        <div
          className={`${styles.infoPageInfoContents} ck-content`}
          dangerouslySetInnerHTML={{ __html: eachData.description_exclude }}
        />
      </details>
    </div>
  );
  const InfoPageSchedule = <div className={`${styles.infoPageInfoContents} ck-content`} dangerouslySetInnerHTML={{ __html: eachData.schedule_text }} />;
  const InfoPageCaution = (
    <div
      className={`${styles.infoPageInfoCaution} ck-content`}
      dangerouslySetInnerHTML={{ __html: eachData.description_notice }}
    />
  );

  const InfoPageObj = {
    info: InfoPageInfo,
    schedule: InfoPageSchedule,
    caution: InfoPageCaution,
  };

  return (
    <div className={styles.productDiv}>
      <TopBar text={eachData.title} />
      <div className={styles.imagesDiv}>
        {eachData.images.map((d) => (d ? <img className={styles.slideEachImage} src={d} alt="asdf" /> : false))}
      </div>
      <div className={styles.productTitle}>{eachData.title}</div>
      <div className={styles.productDesc}>{eachData.description}</div>
      <div className={styles.productLine} />
      <div className={styles.productLittleTitle}>날짜 및 일정 선택</div>
      <DatePicker
        selected={DateObj}
        onChange={(dt) => {
          if (monthData
            && monthData[dateObjToString(dt)]
            && monthData[dateObjToString(dt)].state !== 3) {
            setDateObj(dt);
            navigate(`/product/${idx}/${dateObjToString(dt)}`);
          }
        }}
        locale={ko}
        dateFormat="Pp"
        inline
        className="asdf"
        renderDayContents={renderDayContents}
        minDate={new Date()}
        renderCustomHeader={renderCustomHeader}
        disabledKeyboardNavigation
      />
      <div className={styles.dotInfoDiv}>
        <div>
          <div style={{ backgroundColor: '#FF0057' }} />
          마감임박
        </div>
        <div>
          <div style={{ backgroundColor: '#ACD2FF' }} />
          예약가능
        </div>
        <div>
          <div style={{ backgroundColor: '#0068FF' }} />
          출발확정
        </div>
        <div>
          <div style={{ backgroundColor: '#191919' }} />
          예약마감
        </div>
      </div>
      <div className={styles.productLine} />
      <div className={styles.eachDateInfoDiv}>
        <div>
          <div>여행기간</div>
          <div>{`${eachData.nights}박 ${eachData.days}일`}</div>
        </div>
        <div>
          <div>출발일정</div>
          <div>
            <div>
              {eachData.departure_start_time}
              <div className={`${styles.eachTimePlace} ${eachData.departure_start_time_korea === 1 ? styles.eachTimeKorea : styles.eachTimeAbroad}`}>{eachData.departure_start_time_korea === 1 ? '한국기준' : '현지기준'}</div>
            </div>
            <div>{eachData.departure_transportation}</div>
          </div>
        </div>
        <div>
          <div>도착일정</div>
          <div>
            <div>
              {eachData.arrival_end_time}
              <div className={`${styles.eachTimePlace} ${eachData.arrival_end_time_korea === 1 ? styles.eachTimeKorea : styles.eachTimeAbroad}`}>{eachData.arrival_end_time_korea === 1 ? '한국기준' : '현지기준'}</div>
            </div>
            <div>{eachData.arrival_transportation}</div>
          </div>
        </div>
        <div>
          <div>예약현황</div>
          <div>
            <div>{`정원: ${eachData.maximum}명`}</div>
            <div>{`현재 예약: ${eachData.current}명`}</div>
            <div>{`예약 가능: ${eachData.maximum - eachData.current}명`}</div>
            <div>{`최소 출발: ${eachData.minimum}명`}</div>
          </div>
        </div>
      </div>
      <div className={styles.productLine} />
      <div className={styles.productLittleTitle}>상품 가격</div>
      <table className={styles.priceTable}>
        <thead>
          <tr>
            <td>구분</td>
            <td>성인</td>
            <td>소아</td>
            <td>유아</td>
            <td>영아</td>
          </tr>
        </thead>
        <tbody>
          {eachData.options.map((data) => (
            <tr>
              <td>{data.option_name}</td>
              <td>{`${data.adult_price.toLocaleString()}원`}</td>
              <td>{data.child_price ? `${data.child_price.toLocaleString()}원` : ''}</td>
              <td>{data.infant_price ? `${data.infant_price.toLocaleString()}원` : ''}</td>
              <td>{data.baby_price ? `${data.baby_price.toLocaleString()}원` : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.productLine} />
      <div className={styles.selectHeader}>
        <button type="button" className={`${InfoPage === 'info' && styles.selectedInfo}`} onClick={() => setInfoPage('info')}>상세정보</button>
        <button type="button" className={`${InfoPage === 'schedule' && styles.selectedInfo}`} onClick={() => setInfoPage('schedule')}>일정표</button>
        <button type="button" className={`${InfoPage === 'caution' && styles.selectedInfo}`} onClick={() => setInfoPage('caution')}>필독유의사항</button>
      </div>
      {
        InfoPageObj[InfoPage]
      }
      <div className={styles.reserveBtnDiv}>
        <Link to={`/reserve/${idx}/${date}`} className={styles.reserveBtn} type="button">{`${DateObj && DateObj.getMonth() + 1}월 ${DateObj && DateObj.getDate()}일 예약`}</Link>
        <button
          className={styles.likeBtn}
          type="button"
          onClick={async () => {
            try {
              if (!(loginData?.login)) {
                alert('로그인이 필요합니다.');
                return;
              }
              const res = await axios.post<{statusCode: number}>(`/api/good/like/${idx}`, {}, { ...loginData.axiosConfig, validateStatus: (status) => status < 500 });
              const { data } = res;
              const { statusCode } = data;
              if (statusCode === 201) alert('찜했습니다.');
              if (statusCode === 401) alert('이미 찜한 상품입니다.');
            } catch {
              // asdf
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg>
          찜하기
        </button>
      </div>
    </div>
  );
}

export default Product;
