import abroad from './abroad.png';
import community from './community.png';
import company from './company.png';
import free from './free.png';
import golf from './golf.png';
import good from './good.png';
import island from './island.png';
import jeju from './jeju.png';
import oneday from './oneday.png';
import sleep from './sleep.png';
import theme from './theme.png';

const imgObj = {
  abroad,
  community,
  company,
  free,
  golf,
  good,
  island,
  jeju,
  oneday,
  sleep,
  theme,
};

export const AbroadIcon = abroad;
export const CommunityIcon = community;
export const CompanyIcon = company;
export const FreeIcon = free;
export const GolfIcon = golf;
export const GoodIcon = good;
export const IslandIcon = island;
export const JejuIcon = jeju;
export const OnedayIcon = oneday;
export const SleepIcon = sleep;
export const ThemeIcon = theme;

export default imgObj;
