/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';

function AccountDelete() {
  const { loginData, loginMutate } = useLogin();
  const navigate = useNavigate();
  const doAccountDelete = async () => {
    if (loginData?.login) {
      await axios.delete('/api/user', loginData.axiosConfig);
      loginMutate();
    }
    navigate('/');
  };
  useEffect(() => {
    if (loginData?.login) {
      const question = confirm('정말로 탈퇴하시겠습니까?');
      if (question) doAccountDelete();
      else navigate(-1);
    }
  }, [loginData]);
  useLoginCheck();

  return (
    <div>
      잠시만 기다려주세요
    </div>
  );
}

export default AccountDelete;
