import React from 'react';
import { Link } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLoginCheck from '../../Middle/useLoginCheck';
import LoadMoreButton from '../Components/LoadMoreButton';
import TopBar from '../Components/TopBar';
import styles from '../Css/MyReservation.module.css';

const PAGE_SIZE = 10;

type reservationStateType = '예약접수' | '예약확정' | '결제완료' | '입금대기' | '취소요청' | '취소완료'

interface reservationI {
  idx: number,
  rsv_number: string,
  rsv_date: string,
  title: string,
  state: reservationStateType
}

function MyReservation() {
  const {
    issues, isLoadingMore, isReachingEnd, setSize, FullSize,
  } = useInfiniteLoad<reservationI>((idx) => `/api/reservation?page=${idx + 1}&limit=${PAGE_SIZE}`, 'reservation', PAGE_SIZE);
  const isActiveState = (state: reservationStateType) => (state === '결제완료' || state === '입금대기' || state === '예약접수' || state === '예약확정');
  useLoginCheck();
  return (
    <div className={styles.myResDiv}>
      <TopBar text="예약내역" noUnderLine />
      {
        issues.map((reserve) => (
          <div className={`${styles.eachResDiv} ${isActiveState(reserve.state) ? styles.active : styles.inactive}`}>
            <div className={styles.resNumDiv}>
              <div className={styles.resNumTitle}>예약번호</div>
              <div className={styles.resNumNum}>{reserve.rsv_number}</div>
            </div>
            <div className={styles.eachResTitle}><Link to={`./${reserve.idx}`}>{reserve.title}</Link></div>
            <div className={styles.eachResInfoDiv}>
              <div className={styles.eachResInfoTitle}>예약일자</div>
              <div className={styles.eachResInfoText}>{reserve.rsv_date}</div>
            </div>
            <div className={styles.eachResInfoDiv}>
              <div className={styles.eachResInfoTitle}>예약상태</div>
              <div className={styles.eachResInfoColor}>{reserve.state}</div>
            </div>
          </div>
        ))
      }
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default MyReservation;
