/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'antd';
import useSWRInfinite from 'swr/infinite';
import axios from 'axios';
import useCategory from '../../Middle/useCategory';
import TopBar from '../Components/TopBar';
import styles from '../Css/Category.module.css';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../Components/LoadMoreButton';

const PAGE_SIZE = 10;

export interface productI {
  idx: number,
  title: string,
  description: string,
  price: number,
  thumb_image: string,
  first_category: number,
  second_category?: number,
  first_departure_date?: string,
}

function EachProduct(props:{
  info: productI
}) {
  const { info } = props;
  const category = useCategory();
  const {
    idx, title, description, price, thumb_image, first_category,
  } = info;
  return (
    <Link
      to={info.first_departure_date ? `/product/${idx}/${info.first_departure_date}` : ''}
      className={styles.eachProdLink}
      onClick={() => {
        if (!(info.first_departure_date)) {
          alert('출발 가능한 일정이 없습니다.');
        }
      }}
    >
      <div className={styles.eachProdDiv}>
        <div className={styles.prodImgDiv}>
          <img src={thumb_image} alt={`${title} 이미지`} />
        </div>
        <div className={styles.prodTextDiv}>
          <div className={styles.prodCateDiv}>{title}</div>
          <div className={styles.prodDescDiv}>{description}</div>
          <div className={styles.prodPriceWrapper}>
            <div className={styles.prodPriceDiv}>
              {price.toLocaleString()}
              원
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

function CategoryInner(props: {
  firstIdx: number,
  secondIdx: number
}|{
  keyword: string
}) {
  const isCate = 'firstIdx' in props;
  const category = useCategory();
  // console.log(category);
  // const [FullSize, setFullSize] = useState<number>(-1);
  const orderInfo = {
    popular: '인기순',
    recent: '최신순',
    cheap: '최저가순',
    expensive: '최고가순',
  };
  const [Order, setOrder] = useState<'popular'|'recent'|'cheap'|'expensive'>('popular');
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<productI>(
    (index) => `/api/good${isCate ? `?first_category_idx=${props.firstIdx}${props.secondIdx !== 0 ? `&second_category_idx=${props.secondIdx}` : ''}` : `?keyword=${props.keyword}`}&order=${Order}&page=${index + 1}&limit=${PAGE_SIZE}`,
    'good',
    PAGE_SIZE,
  );
  const dropClick = (order: 'popular'|'recent'|'cheap'|'expensive') => {
    setOrder(order);
  };
  return (
    <div className={styles.categoryDiv}>
      {
        isCate
          ? <TopBar firstIdx={props.firstIdx} secondIdx={props.secondIdx} />
          : <TopBar text={`검색 결과 - ${props.keyword}`} />
      }
      <div className={styles.infoLineDiv}>
        <div className={styles.prodNumDiv}>
          상품
          <span className={styles.prodNumColor}>
            {FullSize}
            개
          </span>
        </div>
        {
          isCate
            ? (
              <Dropdown
                overlay={(
                  <div className={styles.dropListDiv}>
                    {
              (['popular', 'cheap', 'expensive', 'recent'] as any as ('popular'|'recent'|'cheap'|'expensive')[]).map((val) => <button className={`${styles.dropListBtn} ${Order === val && styles.selectedDrop}`} type="button" onClick={() => dropClick(val)}>{orderInfo[val]}</button>)
            }
                  </div>
        )}
                placement="bottomRight"
                trigger={['click']}
              >
                <div className={styles.dropClickDiv}>
                  {orderInfo[Order]}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                  </svg>
                </div>
              </Dropdown>
            )
            : <div />
        }

      </div>
      {issues.map((d, idx) => (
        <>
          <EachProduct info={d} />
          {idx < issues.length - 1 && <div className={styles.line} />}
        </>
      ))}
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

function CategorySearch(props: {
  keyword: string
}) {
  const { keyword } = props;
  return <CategoryInner keyword={keyword as string} />;
}

function CategoryDefault(props: {
  firstIdx: number,
  secondIdx: number
}) {
  const { firstIdx, secondIdx } = props;
}

function Category(props: {
  isSearch: boolean
}) {
  const category = useCategory();
  const params = useParams();
  const navigate = useNavigate();
  const loaded = category.firstCategoryIdxList.length !== 0;
  if (!(props.isSearch)) {
    const firstIdx = parseInt(params.firstIdx as string, 10);
    const secondIdx = parseInt(params.secondIdx as string, 10);
    const notExistNotify = () => {
      navigate('/');
    };
    let valid = false;
    if (loaded) {
      const firstIdxInvalid = !category[firstIdx];
      if (firstIdxInvalid) {
        notExistNotify();
      } else if (secondIdx !== 0) {
        const secondIdxInvalid = !category[firstIdx].secondCategory[secondIdx];
        if (secondIdxInvalid) {
          notExistNotify();
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
    }

    return loaded && valid ? <CategoryInner firstIdx={firstIdx} secondIdx={secondIdx} /> : <div />;
  }
  return loaded ? <CategoryInner keyword={params.keyword as string} /> : <div />;
}

export default Category;
