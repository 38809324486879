/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { personalInfoProtect, personalInfoUse, userLicense } from '../../meta/AgreeText';
import TopBar from '../Components/TopBar';
import styles from '../Css/Signup.module.css';
import useLogin from '../../Middle/useLogin';

const checkSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00000029">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </svg>
);

const agreeList = [
  {
    title: '이용약관 (필수)',
    contents: userLicense,
  },
  {
    title: '개인정보 취급방침 (필수)',
    contents: personalInfoProtect,
  },
  {
    title: '개인정보 수집 동의 (필수)',
    contents: personalInfoUse,
  },
];

function Agree(props: {setStep: (newStep: number) => void}) {
  const { setStep } = props;
  const [Checked, setChecked] = useState(agreeList.map(() => false));
  const allChecked = Checked.every((val) => val);
  return (
    <div className={styles.agreeDiv}>
      <div
        className={`${styles.agreeAllCheckDiv} ${allChecked && styles.agreeSelected}`}
        onClick={() => setChecked(
          (chkVal) => {
            if (chkVal.every((val) => val)) {
              return agreeList.map(() => false);
            }
            return agreeList.map(() => true);
          },
        )}
      >
        {checkSvg}
        <div>모든 약관에 동의합니다.</div>
      </div>
      <div className={styles.agreeAllCheckDesc}>모든 약관 및 개인정보활용 내용 확인 및 전체 동의</div>
      <div className={styles.agreeLine} />
      <div className={styles.agreeListDiv}>
        {
          agreeList.map(({ title, contents }, idx) => (
            <div className={`${styles.eachAgreeDiv} ${Checked[idx] && styles.agreeSelected}`}>
              <div
                className={styles.eachAgreeCheckDiv}
                onClick={() => setChecked((nowChk) => {
                  const nowVal = nowChk[idx];
                  const newChk = [...nowChk];
                  newChk[idx] = !nowVal;
                  return newChk;
                })}
              >
                {checkSvg}
                <div>{title}</div>
              </div>
              <div className={styles.eachAgreeContentsDiv}>
                {contents}
              </div>
            </div>
          ))
        }
      </div>
      <button
        type="button"
        className={`${styles.agreeNextBtn} ${allChecked && styles.agreeNextAvail}`}
        onClick={() => {
          if (allChecked) {
            setStep(2);
          }
        }}
      >
        다음
      </button>
    </div>
  );
}

interface formAlwaysInput {
  name: string
  english_last_name: string
  english_first_name: string
  email: string
  mobile: string
  gender: number | string
  birth: Date | string
  sms_check: number | string
  email_check: number | string
}

interface formInput extends formAlwaysInput {
  userid: string
  password: string
  password_check: string
}

export const idRegExp = /^[a-z0-9]{4,20}$/;
export const pwRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
export const emailRegExp = /^[A-Za-z0-9_]+([-_.]?[0-9a-zA-Z])*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
export const mobileRegExp = /^\d{10,11}$/;
export const nameRegExp = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,10}$/;
export const englishNameRegExp = /^[A-Za-z]{1,30}$/;

const getErrorMsg = (errorType: string) => {
  if (errorType === 'required') return '값을 입력해주세요';
  if (errorType === 'matches') return '입력 값이 규칙에 맞지 않습니다';
  if (errorType === 'typeError') return '값을 선택해주세요';
  if (errorType === 'oneOf') return '비밀번호를 동일하게 입력해주세요';
  return '';
};

function EachForm(props: {
  children: any, title: string, desc: string, error: any, inputName: string }) {
  const {
    children, title, desc, error, inputName,
  } = props;
  return (
    <div className={styles.eachFormDiv}>
      <div className={styles.eachFormTitle}>{title}</div>
      <div className={styles.eachFormChild}>
        {children}
        <div className={styles.eachFormDesc}>{desc}</div>
        {(inputName in error) && (
        <div className={styles.idAvailNo}>
          {getErrorMsg(error[inputName].type)}
        </div>
        )}
      </div>

    </div>
  );
}

function WriteInfo(props: {setStep: (newStep: number) => void, type: 'default' | 'kakao' | 'naver'}) {
  const { accessToken } = useParams();
  const navigate = useNavigate();
  const { setStep, type } = props;
  const formSchema = yup.object().shape({
    userid: yup.string().required().matches(idRegExp),
    name: yup.string().required().matches(nameRegExp),
    password: yup.string().required().matches(pwRegExp),
    password_check: yup.string().required().matches(pwRegExp).oneOf([yup.ref('password')]),
    english_last_name: yup.string().required().matches(englishNameRegExp),
    english_first_name: yup.string().required().matches(englishNameRegExp),
    email: yup.string().required().matches(emailRegExp),
    mobile: yup.string().required().matches(mobileRegExp),
    gender: yup.string().required(),
    birth: yup.date().required(),
    sms_check: yup.string().required(),
    email_check: yup.string().required(),
  });
  const formSocialSchema = yup.object().shape({
    name: yup.string().required().matches(nameRegExp),
    english_last_name: yup.string().required().matches(englishNameRegExp),
    english_first_name: yup.string().required().matches(englishNameRegExp),
    email: yup.string().required().matches(emailRegExp),
    mobile: yup.string().required().matches(mobileRegExp),
    gender: yup.string().required(),
    birth: yup.date().required(),
    sms_check: yup.string().required(),
    email_check: yup.string().required(),
  });

  const {
    register, handleSubmit, formState: { errors }, setFocus,
  } = useForm<formInput>({
    resolver: yupResolver(type === 'default' ? formSchema : formSocialSchema),
  });
  const { onBlur, ...rest } = register('userid');
  const [IDAvailable, setIDAvailable] = useState<null|boolean>(null);
  const [isLoading, setIsLoading] = useState(false);
  const checkId = (userid: string) => axios.post<{statusCode: 201, result: boolean} | {statusCode: 400}>('/api/user/sign/check', { userid }).then((res) => {
    const { data } = res;
    if (data.statusCode === 201 && data.result) return true;
    return false;
  });

  const defaultOnSubmit = async (data: formInput) => {
    if (isLoading) return;
    setIsLoading(true);
    const isIDOk = await checkId(data.userid);
    if (!isIDOk) {
      setIDAvailable(false);
      setFocus('userid');
      setIsLoading(false);
      return;
    }
    try {
      const reqData: formInput = {
        ...data,
        english_first_name: data.english_first_name.toLowerCase(),
        english_last_name: data.english_last_name.toLowerCase(),
        birth: (data.birth as Date).toISOString().slice(0, 10),
        gender: parseInt(data.gender as string, 10),
        sms_check: parseInt(data.sms_check as string, 10),
        email_check: parseInt(data.email_check as string, 10),
      };
      const res = await axios.post('/api/user/sign-up', reqData);
      if (res.data.statusCode === 201) {
        setStep(3);
      } else {
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  };
  const socialOnSubmit = async (data: formAlwaysInput) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const reqData: formAlwaysInput = {
        ...data,
        english_first_name: data.english_first_name.toLowerCase(),
        english_last_name: data.english_last_name.toLowerCase(),
        birth: (data.birth as Date).toISOString().slice(0, 10),
        gender: parseInt(data.gender as string, 10),
        sms_check: parseInt(data.sms_check as string, 10),
        email_check: parseInt(data.email_check as string, 10),
      };
      const res = await axios.post(`/api/user/sign-up/sns?type=${type}`, { ...reqData, token: accessToken });
      if (res.data.statusCode === 201) {
        setStep(3);
      } else {
        setIsLoading(false);
        navigate('/login');
      }
    } catch {
      setIsLoading(false);
      navigate('/login');
    }
  };

  return (
    <div className={styles.writeInfoDiv}>
      <div className={styles.writeInfoTitle}>가입 정보 입력 (필수)</div>
      <form
        action="submit"
        onSubmit={handleSubmit(type === 'default' ? defaultOnSubmit : socialOnSubmit)}
      >
        <EachForm title="아이디" desc={type === 'default' ? '(소문자 또는 숫자 조합 4-20자)' : ''} error={errors} inputName="userid">
          {
            type === 'default'
              ? (
                <>
                  <input
                    type="text"
                    onBlur={(e) => {
                      if (e.target.value.length > 0) {
                        checkId(e.target.value).then((val) => setIDAvailable(val));
                      }
                      onBlur(e);
                    }}
                    {...rest}
                  />
                  <div className={`${styles.idAvailDiv} ${IDAvailable === true && styles.idAvailYes} ${IDAvailable === false && styles.idAvailNo}`}>{IDAvailable ? '사용 가능한 ID입니다' : '사용할 수 없는 ID입니다'}</div>
                </>
              )
              : <div className={styles.loginSocialText}>{`${type === 'kakao' ? '카카오' : '네이버'} 아이디로 로그인`}</div>
          }

        </EachForm>
        <EachForm title="비밀번호" desc={type === 'default' ? '(소문자와 숫자 모두 사용 8-20자)' : ''} error={errors} inputName="password">
          {
            type === 'default'
              ? (
                <input type="password" {...register('password')} />
              )
              : <div className={styles.loginSocialText}>{`${type === 'kakao' ? '카카오' : '네이버'} 아이디로 로그인`}</div>
          }
        </EachForm>
        <EachForm title="비밀번호 확인" desc={type === 'default' ? '(비밀번호를 다시 한 번 입력해주세요)' : ''} error={errors} inputName="password_check">
          {
            type === 'default'
              ? (
                <input type="password" {...register('password_check')} />
              )
              : <div className={styles.loginSocialText}>{`${type === 'kakao' ? '카카오' : '네이버'} 아이디로 로그인`}</div>
          }
        </EachForm>
        <EachForm title="이름" desc="" error={errors} inputName="name">
          <input type="text" {...register('name')} />
        </EachForm>
        <EachForm title="영문 성" desc="여권에 기재된 영문 성을 입력해주세요" error={errors} inputName="english_last_name">
          <input type="text" placeholder="ex) Hong" {...register('english_last_name')} />
        </EachForm>
        <EachForm title="영문 이름" desc="여권에 기재된 영문 이름을 입력해주세요" error={errors} inputName="english_first_name">
          <input type="text" placeholder="ex) Gildong" {...register('english_first_name')} />
        </EachForm>
        <EachForm title="성별" desc="" error={errors} inputName="gender">
          <div className={styles.formsRadioDiv}>
            <div>
              <input type="radio" id="male" value={1} {...register('gender')} />
              <label htmlFor="male">남성</label>
            </div>
            <div>
              <input type="radio" id="female" value={0} {...register('gender')} />
              <label htmlFor="female">여성</label>
            </div>
          </div>
        </EachForm>
        <EachForm title="생년월일" desc="" error={errors} inputName="birth">
          <input type="date" {...register('birth')} />
        </EachForm>
        <EachForm title="연락처" desc="" error={errors} inputName="mobile">
          <input type="tel" placeholder="ex) 01000000000" {...register('mobile')} />
        </EachForm>
        <EachForm title="이메일" desc="" error={errors} inputName="email">
          <input type="email" placeholder="ex) example@alpstour.co.kr" {...register('email')} />
        </EachForm>
        <EachForm title="이메일 수신 동의" desc="" error={errors} inputName="email_check">
          <div className={styles.formsRadioDiv}>
            <div>
              <input type="radio" id="email_yes" value={1} {...register('email_check')} />
              <label htmlFor="email_yes">수신</label>
            </div>
            <div>
              <input type="radio" id="email_no" value={0} {...register('email_check')} />
              <label htmlFor="email_no">수신거부</label>
            </div>
          </div>
        </EachForm>
        <EachForm title="SMS 수신 동의" desc="" error={errors} inputName="sms_check">
          <div className={styles.formsRadioDiv}>
            <div>
              <input type="radio" id="sms_yes" value={1} {...register('sms_check')} />
              <label htmlFor="sms_yes">수신</label>
            </div>
            <div>
              <input type="radio" id="sms_no" value={0} {...register('sms_check')} />
              <label htmlFor="sms_no">수신거부</label>
            </div>
          </div>
        </EachForm>
        <button type="submit">가입하기</button>
      </form>
    </div>
  );
}

function SignupComplete(props: {setStep: (newStep: number) => void}) {
  const { setStep } = props;
  return (
    <div className={styles.completeDiv}>
      <div className={styles.completeTitle}>가입 완료</div>
      {checkSvg}
      <div className={styles.completeDesc}>회원가입이 완료되었습니다.</div>
      <div className={styles.completeBtnDiv}>
        <Link to="/"><div style={{ background: '#425571' }}>메인으로</div></Link>
        <Link to="/login"><div style={{ background: '#3386FF' }}>로그인하기</div></Link>
      </div>
    </div>
  );
}

function Signup(props: {type: 'default' | 'kakao' | 'naver'}) {
  const { type } = props;
  const [Step, setStep] = useState(1);
  const { loginData } = useLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (loginData?.login) {
      navigate('/');
    }
  }, [loginData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Step]);
  return (
    <div className={styles.signupDiv}>
      <TopBar text="회원가입" noUnderLine />
      <div className={styles.signupSubTopBarDiv}>
        <div className={`${Step === 1 && styles.nowStep}`}>약관동의</div>
        <div className={`${Step === 2 && styles.nowStep}`}>정보입력</div>
        <div className={`${Step === 3 && styles.nowStep}`}>가입완료</div>
      </div>
      {Step === 1 && <Agree setStep={setStep} />}
      {Step === 2 && <WriteInfo setStep={setStep} type={type} />}
      {Step === 3 && <SignupComplete setStep={setStep} />}
    </div>
  );
}

export default Signup;
