/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  emailRegExp, englishNameRegExp, mobileRegExp, nameRegExp, pwRegExp,
} from './Signup';
import styles from '../Css/Signup.module.css';
import useLogin from '../../Middle/useLogin';
import TopBar from '../Components/TopBar';
import BlankMaker from '../../Desktop/Components/BlankMaker';
import useLoginCheck from '../../Middle/useLoginCheck';

interface formInput {
  name: string
  english_last_name: string
  english_first_name: string
  email: string
  mobile: string
  gender: number | string
  birth: Date | string
  sms_check: number | string
  email_check: number | string
}

const getErrorMsg = (errorType: string) => {
  if (errorType === 'required') return '값을 입력해주세요';
  if (errorType === 'matches') return '입력 값이 규칙에 맞지 않습니다';
  if (errorType === 'typeError') return '값을 선택해주세요';
  if (errorType === 'oneOf') return '비밀번호를 동일하게 입력해주세요';
  return '';
};

function EachForm(props: {
  children: any, title: string, desc: string, error: any, inputName: string }) {
  const {
    children, title, desc, error, inputName,
  } = props;
  return (
    <div className={styles.eachFormDiv}>
      <div className={styles.eachFormTitle}>{title}</div>
      <div className={styles.eachFormChild}>
        {children}
        <div className={styles.eachFormDesc}>{desc}</div>
        {(inputName in error) && (
        <div className={styles.idAvailNo}>
          {getErrorMsg(error[inputName].type)}
        </div>
        )}
      </div>

    </div>
  );
}

function MyInfoChange() {
  const navigate = useNavigate();
  const { getLoginConfig, loginData } = useLogin();
  useLoginCheck();
  const formSchema = yup.object().shape({
    name: yup.string().required().matches(nameRegExp),
    english_last_name: yup.string().required().matches(englishNameRegExp),
    english_first_name: yup.string().required().matches(englishNameRegExp),
    email: yup.string().required().matches(emailRegExp),
    mobile: yup.string().required().matches(mobileRegExp),
    gender: yup.string().required(),
    birth: yup.date().required(),
    sms_check: yup.string().required(),
    email_check: yup.string().required(),
  });

  const {
    register, handleSubmit, formState: { errors }, setFocus,
  } = useForm<formInput>({
    resolver: yupResolver(formSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const defaultOnSubmit = async (data: formInput) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const reqData: any = {
        ...data,
        english_first_name: data.english_first_name.toLowerCase(),
        english_last_name: data.english_last_name.toLowerCase(),
        birth: (data.birth as Date).toISOString().slice(0, 10),
        gender: parseInt(data.gender as string, 10),
        sms_check: parseInt(data.sms_check as string, 10),
        email_check: parseInt(data.email_check as string, 10),
        travel_type: 0,
        travel_goal: 0,
      };
      const res = await axios.patch('/api/user/info', reqData, getLoginConfig());
      if (res.data.statusCode === 201) {
        alert('수정했습니다.');
        navigate(-1);
      } else {
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  };
  if (!loginData?.login) return <div />;
  return (
    <div className={styles.writeInfoDiv}>
      <TopBar text="회원정보 수정" />
      <BlankMaker height={20} />
      <form
        action="submit"
        onSubmit={handleSubmit(defaultOnSubmit)}
      >
        <EachForm title="이름" desc="" error={errors} inputName="name">
          <input defaultValue={loginData.info.name} type="text" {...register('name')} />
        </EachForm>
        <EachForm title="영문 성" desc="여권에 기재된 영문 성을 입력해주세요" error={errors} inputName="english_last_name">
          <input defaultValue={loginData.info.english_last_name} type="text" placeholder="ex) Hong" {...register('english_last_name')} />
        </EachForm>
        <EachForm title="영문 이름" desc="여권에 기재된 영문 이름을 입력해주세요" error={errors} inputName="english_first_name">
          <input defaultValue={loginData.info.english_first_name} type="text" placeholder="ex) Gildong" {...register('english_first_name')} />
        </EachForm>
        <EachForm title="성별" desc="" error={errors} inputName="gender">
          <div className={styles.formsRadioDiv}>
            <div>
              <input defaultChecked={loginData.info.gender === 1} type="radio" id="male" value={1} {...register('gender')} />
              <label htmlFor="male">남성</label>
            </div>
            <div>
              <input defaultChecked={loginData.info.gender === 0} type="radio" id="female" value={0} {...register('gender')} />
              <label htmlFor="female">여성</label>
            </div>
          </div>
        </EachForm>
        <EachForm title="생년월일" desc="" error={errors} inputName="birth">
          <input defaultValue={loginData.info.birth} type="date" {...register('birth')} />
        </EachForm>
        <EachForm title="연락처" desc="" error={errors} inputName="mobile">
          <input defaultValue={loginData.info.mobile} type="tel" placeholder="ex) 01000000000" {...register('mobile')} />
        </EachForm>
        <EachForm title="이메일" desc="" error={errors} inputName="email">
          <input defaultValue={loginData.info.email} type="email" placeholder="ex) example@alpstour.co.kr" {...register('email')} />
        </EachForm>
        <EachForm title="이메일 수신 동의" desc="" error={errors} inputName="email_check">
          <div className={styles.formsRadioDiv}>
            <div>
              <input defaultChecked={loginData.info.email_check === 1} type="radio" id="email_yes" value={1} {...register('email_check')} />
              <label htmlFor="email_yes">수신</label>
            </div>
            <div>
              <input defaultChecked={loginData.info.email_check === 0} type="radio" id="email_no" value={0} {...register('email_check')} />
              <label htmlFor="email_no">수신거부</label>
            </div>
          </div>
        </EachForm>
        <EachForm title="SMS 수신 동의" desc="" error={errors} inputName="sms_check">
          <div className={styles.formsRadioDiv}>
            <div>
              <input defaultChecked={loginData.info.sms_check === 1} type="radio" id="sms_yes" value={1} {...register('sms_check')} />
              <label htmlFor="sms_yes">수신</label>
            </div>
            <div>
              <input defaultChecked={loginData.info.sms_check === 0} type="radio" id="sms_no" value={0} {...register('sms_check')} />
              <label htmlFor="sms_no">수신거부</label>
            </div>
          </div>
        </EachForm>
        <button type="submit">수정하기</button>
      </form>
      <BlankMaker height={100} />
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const { value: first } = (e.target as unknown as {value: string}[])[0];
          const { value: second } = (e.target as unknown as {value: string}[])[1];
          if (first.trim() !== second.trim()) {
            alert('두 입력이 다릅니다.');
            return;
          }
          if (!pwRegExp.test(first.trim())) {
            alert('비밀번호가 조건에 맞지 않습니다.');
            return;
          }
          try {
            const res = await axios.patch<{statusCode: number}>('/api/user/password', { new_password: first.trim() }, getLoginConfig());
            if (res.data.statusCode !== 201) throw Error;
            alert('비밀번호를 변경했습니다.');
            navigate(-1);
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        <div className={styles.eachFormDiv}>
          <div className={styles.eachFormTitle}>새로운 비밀번호</div>
          <div className={styles.eachFormChild}>
            <input type="password" />
          </div>
        </div>
        <div className={styles.eachFormDiv}>
          <div className={styles.eachFormTitle}>새로운 비밀번호 확인</div>
          <div className={styles.eachFormChild}>
            <input type="password" />
          </div>
        </div>
        <button type="submit">비밀번호 변경</button>
      </form>
    </div>
  );
}

export default MyInfoChange;
