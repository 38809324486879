/* eslint-disable no-underscore-dangle */
import useSWR from 'swr';

let _menuState: 'none'|'header'|'product' = 'none';
let _showBlur: boolean = false;
let _closeMenuTrigger: boolean = false;

export function useMenuState() {
  const { data: menuState, mutate: mutateMenuState } = useSWR('menuState', () => _menuState);
  const { data: showBlur, mutate: mutateShowBlur } = useSWR('showBlur', () => _showBlur);
  const { data: closeMenuTrigger, mutate: mutateCloseMenuTrigger } = useSWR('closeMenuTrigger', () => _closeMenuTrigger);
  function setMenuState(newMenuState:'none'|'header'|'product') {
    _menuState = newMenuState;
    mutateMenuState();
  }
  function setShowBlur(newShowBlur: boolean) {
    _showBlur = newShowBlur;
    mutateShowBlur();
  }
  function closeAllMenu() {
    _menuState = 'none';
    _showBlur = false;
    _closeMenuTrigger = !_closeMenuTrigger;
    mutateMenuState();
    mutateShowBlur();
    mutateCloseMenuTrigger();
  }

  return {
    menuState,
    showBlur,
    closeMenuTrigger,
    setMenuState,
    setShowBlur,
    closeAllMenu,
  };
}

export default useMenuState;
