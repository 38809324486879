import React from 'react';
import { Link } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../Components/LoadMoreButton';
import TopBar from '../Components/TopBar';
import styles from '../Css/Notice.module.css';

const PAGE_SIZE = 10;

function Notice() {
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<{
    idx: number, title: string, views: number, created_at: string
  }>(
    (index) => `/api/board/notice?page=${index + 1}&limit=${PAGE_SIZE}`,
    'notice',
    PAGE_SIZE,
  );
  return (
    <div className={styles.noticeDiv}>
      <TopBar text="공지사항" noUnderLine />
      <div className={styles.grayTitleDiv}>
        <div className={styles.grayTitle}>제목</div>
        <div className={styles.grayDate}>작성일</div>
      </div>
      <div className={styles.noticeArticleDiv}>
        {
          issues.filter((d) => (
            d.idx !== 18 && d.idx !== 19 && d.idx !== 20 && d.idx !== 21
          )).map((data) => (
            <Link to={`./${data.idx}`} className={styles.noticeEachArticleDiv}>
              <div className={styles.badgeAndTitle}>
                <div className={styles.noticeBadge}>공지</div>
                <div className={styles.noticeTitle}>{data.title}</div>
              </div>
              <div className={styles.noticeInfo}>
                <div>작성: 관리자</div>
                <div>{data.created_at}</div>
              </div>
            </Link>
          ))
        }
      </div>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default Notice;
