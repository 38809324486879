/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import TopBar from '../Components/TopBar';
import styles from '../Css/EachNotice.module.css';

function EachQNAInner(props: { idx: number }) {
  const { idx } = props;
  const navigate = useNavigate();
  const { getLoginConfig } = useLogin();
  const { data, error } = useSWR(`/api/board/qna/${idx}`, async (url) => {
    const res = await axios.get<{ result: {
       content: string, title: string, author_name: string, created_at: string, is_answer: number
      } }>(url);
    return res.data.result;
  });
  if (error) navigate(-1);
  if (!data) return <div />;
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="질문과 답변" />
      <div className={styles.infoDiv}>
        <div className={styles.infoTitleDiv}>{data.title}</div>
        <div className={styles.infoInfoDiv}>
          <div>{`작성자: ${data.author_name}`}</div>
          <div>{`작성일: ${data.created_at}`}</div>
        </div>
      </div>
      <div className={`${styles.contentsDiv} ck-content`} dangerouslySetInnerHTML={{ __html: data.content }} />
      {data.is_answer === 0 && (
      <button
        type="button"
        onClick={async () => {
          if (!confirm('삭제하시겠습니까?')) return;
          try {
            const res = await axios.delete<{statusCode: number}>(`/api/board/qna/${idx}`);
            if (res.data.statusCode !== 201) throw Error;
            alert('삭제했습니다.');
            navigate(-1);
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
        className={styles.deleteButton}
      >
        삭제하기
      </button>
      )}
    </div>
  );
}

function CheckWithLogin(props: { idx: number }) {
  const { idx } = props;
  const { loginData } = useLogin();
  const [Pass, setPass] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const res = await axios.post<{result: boolean}>('/api/board/qna/auth', { idx }, loginData?.login ? loginData.axiosConfig : {});
      if (res.data.result) setPass(true);
      else {
        alert('권한이 없습니다.');
        navigate(-1);
      }
    })();
  }, [loginData]);
  if (Pass) return <EachQNAInner idx={idx} />;
  return <div>잠시만 기다려주세요</div>;
}

function CheckWithNotLogin(props: { idx: number }) {
  const { idx } = props;
  const { loginData } = useLogin();
  const [Pass, setPass] = useState(false);
  if (Pass) return <EachQNAInner idx={idx} />;
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="질문과 답변" />
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const { value } = (e.target as unknown as {value: string}[])[0];
          const res = await axios.post<{result: boolean}>('/api/board/qna/auth', { idx, password: value }, loginData?.login ? loginData.axiosConfig : {});
          if (res.data.result) setPass(true);
          else {
            alert('비밀번호가 틀립니다.');
          }
        }}
      >
        <div>비밀번호를 입력해주세요</div>
        <input type="password" />
        <button type="submit">확인</button>
      </form>
    </div>
  );
}

function EachQNA() {
  const { idx } = useParams();
  const { loginData } = useLogin();
  const IDX = parseInt(idx || '', 10);
  if (loginData?.login) return <CheckWithLogin idx={IDX} />;
  return <CheckWithNotLogin idx={IDX} />;
}

export default EachQNA;
