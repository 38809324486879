import axios from 'axios';
import { useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import useLogin from './useLogin';

export default function useInfiniteLoad<T>(
  urlFunc: (index: number) => string,
  listName: string,
  PAGE_SIZE: number,
) {
  const { loginData, loginIsValidating } = useLogin();
  const [FullSize, setFullSize] = useState(-1);
  const {
    data, error, mutate, size, setSize, isValidating,
  } = useSWRInfinite<T[]>(
    urlFunc,
    (url) => axios.get(url, ((loginData?.login) ? loginData.axiosConfig : {})).then((res) => {
      const { result } = res.data;
      setFullSize(result.count);
      return result[listName];
    }),
  );
  useEffect(() => {
    mutate();
  }, [loginData, loginIsValidating]);
  const issues = data ? ([] as (T[])).concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData
    || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty
   || (data && data[data.length - 1]?.length < PAGE_SIZE)
    || issues.length === FullSize;
  const isRefreshing = isValidating && data && data.length === size;
  return {
    issues,
    setSize,
    isLoadingMore,
    isReachingEnd,
    FullSize,
    mutate,
  };
}
