/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactChild } from 'react';
import argsToPX from '../../Middle/argsToPX';
import styles from '../Css/LeftRight.module.css';

function LeftRight(props: {
  children?: ReactChild[],
  leftWidth: string|number,
  haveLine?: boolean,
}) {
  const { children, leftWidth, haveLine } = props;
  const tmpStyle = { '--leftWidth': argsToPX(leftWidth) } as CSSProperties;
  return (
    <div className={`${styles.selectWindowDiv} ${haveLine && styles.haveLine}`} style={tmpStyle}>
      {
        haveLine
          ? children?.map((d) => (
            <>
              {d}
              <div className={styles.line} />
            </>
          ))
          : children
      }
    </div>
  );
}

export default LeftRight;
