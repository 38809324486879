/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import TopBar from '../Components/TopBar';
import styles from '../Css/MyReservation.module.css';

function Payment() {
  const { idx } = useParams();
  const { getLoginConfig, loginData } = useLogin();
  useLoginCheck();
  const [Mile, setMile] = useState(0);
  const navigate = useNavigate();
  const { data, error, isValidating } = useSWR(`/api/payment/${idx}`, async (url) => {
    const res = await axios.get<{
      result: {
        good: {
          good_title: string
          departure_date: string
        }
        payment: {
          rsv_number: string
          price: number
          name: string
          phone: string
          email: string
        }
        mileage: {
          mileage: number
          expected_mileage: number
        }
      }
    } | {error: string, message: string}
    >(url, { ...getLoginConfig(), validateStatus: (status) => (status < 500) });
    if ('error' in res.data) {
      return res.data;
    }
    return res.data.result;
  });
  if (loginData?.login && data && 'error' in data && (!isValidating)) {
    return (
      <>
        <div>오류가 발생했습니다</div>
        <div>{`(${data.message})`}</div>
      </>
    );
  }
  if (!data || 'error' in data) return <div />;
  const check = () => {
    if (Mile > 0 && Mile < 100) return '적립금 범위를 확인해주세요.';
    if (Mile > 30000) return '적립금 범위를 확인해주세요.';
    if (Mile > data.mileage.mileage) return '사용가능 적립금보다 사용할 적립금이 많습니다.';
    if (data.payment.price - Mile < 0) return '결제예정금액이 0보다 작습니다.';
    return '';
  };
  const doPayment = async () => {
    const checked = check();
    if (checked.length > 0) {
      alert(checked);
      return;
    }
    const { IMP } = window as any;
    IMP.request_pay({ // param
      pg: 'html5_inicis',
      pay_method: 'card',
      merchant_uid: data.payment.rsv_number,
      name: data.good.good_title,
      amount: data.payment.price - Mile,
      buyer_email: data.payment.email,
      buyer_name: data.payment.name,
      buyer_tel: data.payment.phone,
      m_redirect_url: `${location.origin}/paymentcallback/${idx}`,
    }, async (rsp: any) => {
      if (rsp.success) {
        const res = await axios.post<{result: {status: boolean, message: string}}>('/api/payment/validation', {
          imp_uid: rsp.imp_uid,
          merchant_uid: rsp.merchant_uid,
        });
        alert(res.data.result.message);
        navigate('./../');
      } else {
        alert('결제에 실패했습니다.');
      }
    });
  };
  return (
    <div className={styles.myResDetailDiv}>
      <TopBar text="예약상세" noUnderLine />
      <div className={`${styles.myResDetailInner}`}>
        <div className={styles.resNumDiv}>
          <div className={styles.resNumTitle}>예약번호</div>
          <div className={styles.resNumNum}>{data.payment.rsv_number}</div>
        </div>
        <div className={styles.eachResTitle}>{data.good.good_title}</div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>출발일</div>
          <div className={styles.eachResInfoText}>{data.good.departure_date}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>예약자명</div>
          <div className={styles.eachResInfoText}>{data.payment.name}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>전화번호</div>
          <div className={styles.eachResInfoText}>{data.payment.phone}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>이메일</div>
          <div className={styles.eachResInfoText}>{data.payment.email}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>총 금액</div>
          <div className={styles.eachResInfoText}>{`${data.payment.price.toLocaleString()}원`}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>
            사용가능
            <br />
            적립금
          </div>
          <div className={styles.eachResInfoText}>{`${data.mileage.mileage.toLocaleString()}원`}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>결제예정금액</div>
          <div className={styles.eachResInfoText}>{`${(data.payment.price - Mile).toLocaleString()}원`}</div>
        </div>
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>
            사용할
            <br />
            적립금
          </div>
          <div className={styles.eachResInfoText}><input className={styles.mile} type="number" value={Mile} onChange={(e) => setMile(parseInt(e.target.value, 10))} /></div>
        </div>
      </div>
      <button type="button" className={styles.payBtn} onClick={doPayment}>결제하기</button>
    </div>
  );
}

export default Payment;
