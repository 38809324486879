/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import TopBar from '../Components/TopBar';
import styles from '../Css/EachNotice.module.css';

function EachReview() {
  const { idx } = useParams();
  const navigate = useNavigate();
  const { getLoginConfig, loginData } = useLogin();
  const { data, error, mutate } = useSWR(`/api/board/review/${idx}`, async (url) => {
    const res = await axios.get<{ result: {
       idx: number, title: string, name: string, views: number, content: string, is_author: boolean
      } }>(url, getLoginConfig());
    return res.data.result;
  });
  useEffect(() => {
    mutate();
  }, [loginData]);
  if (error) navigate(-1);
  if (!data) return <div />;
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="여행후기" />
      <div className={styles.infoDiv}>
        <div className={styles.infoTitleDiv}>{data.title}</div>
        <div className={styles.infoInfoDiv}>
          <div>{`작성자: ${data.name}`}</div>
          <div>{`조회수: ${data.views.toLocaleString('ko-kr')}회`}</div>
        </div>
      </div>
      <div className={`${styles.contentsDiv} ck-content`} dangerouslySetInnerHTML={{ __html: data.content }} />
      {data.is_author && (
      <button
        type="button"
        onClick={async () => {
          if (!confirm('삭제하시겠습니까?')) return;
          try {
            const res = await axios.delete<{statusCode: number}>(`/api/board/review/${idx}`, getLoginConfig());
            if (res.data.statusCode !== 201) throw Error;
            alert('삭제했습니다.');
            navigate(-1);
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
        className={styles.deleteButton}
      >
        삭제하기
      </button>
      )}
    </div>
  );
}

export default EachReview;
