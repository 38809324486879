import React from 'react';
import styles from '../Css/LoadMoreButton.module.css';

function LoadMoreButton(props: {
  isLoadingMore: boolean|undefined,
  isReachingEnd: boolean|undefined,
  length: number,
  FullSize: number,
  setSize: (func: ((s: number) => number)) => void,
}) {
  const {
    isLoadingMore, isReachingEnd, length, FullSize, setSize,
  } = props;
  return (
    <button
      type="button"
      className={`${styles.loadmore} ${(isLoadingMore || isReachingEnd) && styles.noload}`}
      onClick={() => {
        if (!(isLoadingMore || isReachingEnd))setSize((s) => s + 1);
      }}
    >
      {`${length}건 / 총 ${FullSize}건`}
    </button>
  );
}

export default LoadMoreButton;
