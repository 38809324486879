/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Desktop/Components/BlankMaker';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import TopBar from '../Components/TopBar';
import styles from '../Css/Reserve.module.css';
import signupStyles from '../Css/Signup.module.css';

interface eachDataI {
  'good_idx': number,
  'title': string,
  'images': string[],
  'description': string,
  'idx': number,
  'departure_start_time': string,
  'departure_start_time_korea': number,
  'departure_end_time': string,
  'departure_end_time_korea': number,
  'departure_transportation': string,
  'arrival_start_time': string,
  'arrival_start_time_korea': number,
  'arrival_end_time': string,
  'arrival_end_time_korea': number,
  'arrival_transportation': string,
  description_perks: string
  description_include: string
  description_exclude: string
  description_notice: string
  schedule_text: string
  'minimum': number,
  'maximum': number,
  'current': number,
  'nights': number,
  'days': number,
  'options': {
    'idx': number,
    'option_name': string,
    'adult_price': number,
    'child_price': number|null,
    'infant_price': number|null,
    'baby_price': number|null,
    'maximum': number|null
  }[],
  'additional_option': {
    'idx': number,
    'option_name': string,
    'price': number,
    'maximum': number|null
  }[],
}

interface reserveI {
  'good_idx': number
  'event_detail_idx' : number,
  'total_price': number,
  'options' : {
    option_idx: number,
    adult_count?: number
    child_count?: number
    infant_count?: number
    baby_count?: number
  }[],
  'additional_options' : {
    'option_idx' : number,
    'count' : number
  }[],
  'rsv_name' : string,
  'rsv_gender' : number,
  'rsv_birth' : string,
  'rsv_phone' : string,
  'rsv_email' : string,
  'people_arr' : {
    'age_type' : number,
    'korean_name' : string,
    'english_last_name' : string,
    'english_first_name' : string,
    'birth' : string,
    'phone' : string,
    'gender' : number
  }[]
}

function EachForm(props: {
  children: any, title: string, desc: string }) {
  const {
    children, title, desc,
  } = props;
  return (
    <div className={signupStyles.eachFormDiv}>
      <div className={signupStyles.eachFormTitle}>{title}</div>
      <div className={signupStyles.eachFormChild}>
        {children}
        <div className={signupStyles.eachFormDesc}>{desc}</div>
      </div>
    </div>
  );
}

const checkSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00000029">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </svg>
);

function NumberChanger(props: {
  innerNum: number,
  onMinusClick: () => void, onPlusClick: () => void,
  isMinusAvail: boolean, isPlusAvail: boolean
}) {
  const {
    innerNum, onMinusClick, onPlusClick, isMinusAvail, isPlusAvail,
  } = props;
  return (
    <div className={styles.optionNumInfoDiv}>
      <button type="button" className={`${styles.optionBtn} ${isMinusAvail || styles.optionBtnDisable}`} onClick={() => onMinusClick()}>
        <svg viewBox="0 0 24 24">
          <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,13H17V11H7" />
        </svg>
      </button>
      <div className={styles.optionCount}>{innerNum}</div>
      <button type="button" className={`${styles.optionBtn} ${isPlusAvail || styles.optionBtnDisable}`} onClick={() => onPlusClick()}>
        <svg viewBox="0 0 24 24">
          <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
        </svg>
      </button>
    </div>
  );
}

type ageI = 'adult'|'child'|'infant'|'baby';

const ageToString = {
  adult: '성인',
  child: '소아',
  infant: '유아',
  baby: '영아',
};

const ageToNumber = {
  adult: 0,
  child: 1,
  infant: 2,
  baby: 3,
};

const emailRegExp = /^[A-Za-z0-9_]+([-_.]?[0-9a-zA-Z])*[@]{1}[A-Za-z0-9]+[A-Za-z0-9]*[.]{1}[A-Za-z]{1,3}$/;
const mobileRegExp = /^\d{10,11}$/;
const nameRegExp = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,10}$/;
const englishNameRegExp = /^[A-Za-z]{1,30}$/;

const ageList: ageI[] = ['adult', 'child', 'infant', 'baby'];

function Reserve() {
  const { idx, date } = useParams();
  const { loginData } = useLogin();
  const navigate = useNavigate();
  useLoginCheck();
  const [ScreenState, setScreenState] = useState<'optionNum'|'peopleNum'|'peopleInfo'|'loading'>('optionNum');
  const [OptionSelectNum, setOptionSelectNum] = useState<{
    'idx': number,
    'option_name': string,
    'adult_price': number,
    'child_price'?: number|null,
    'infant_price'?: number|null,
    'baby_price'?: number|null,
    'maximum'?: number|null,
    count: number
  }[]>([]);
  const [EachOptionSelectNum, setEachOptionSelectNum] = useState<{
    'idx': number,
    'option_name': string,
    price: {
      adult: number,
      child: number|boolean,
      infant: number|boolean,
      baby: number|boolean
    }
    'maximum'?: number|null,
    count: {
      adult: number,
      child: number,
      infant: number,
      baby: number,
      total: number
    }
  }[]>([]);
  const [AddiOptionSelectNum, setAddiOptionSelectNum] = useState<{
    'idx': number,
    'option_name': string,
    'price': number,
    'maximum'?: number|null,
    count: number
  }[]>([]);
  const [PeopleInfoList, setPeopleInfoList] = useState<{
    'age_type' : ageI,
    'korean_name' : string,
    'english_last_name' : string,
    'english_first_name' : string,
    'birth' : string,
    'phone' : string,
    'gender' : number,
    displayName: string
  }[]>([]);
  const [ReserverInfo, setReserverInfo] = useState<{
    'korean_name' : string,
    'birth' : string,
    'phone' : string,
    'gender' : number,
    'email': string
  }>({
    korean_name: '',
    birth: '',
    phone: '',
    gender: -1,
    email: '',
  });
  const [UseReserverInstead, setUseReserverInstead] = useState(false);
  const { data: productData, error: productError } = useSWR(`/api/good/schedule/info?idx=${idx}&date=${date}`, async (url: string) => {
    const res = await axios.get<{result: eachDataI}>(url);
    const { data } = res;
    return data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (productError) navigate('/');
  }, [productError]);

  useEffect(() => {
    if (productData) {
      setOptionSelectNum(productData.options.map((data) => ({ ...data, count: 0 })));
      setAddiOptionSelectNum(productData.additional_option.map((data) => ({ ...data, count: 0 })));
    }
  }, [productData]);

  if (!productData) return <div />;

  const onFirstNext = () => {
    const tmp = OptionSelectNum.map((data) => (
      [...Array(data.count)].map((_, i) => ({
        ...data,
        count: {
          adult: 0, child: 0, infant: 0, baby: 0, total: 0,
        },
        price: {
          adult: data.adult_price,
          child: typeof data.child_price === 'number' && data.child_price,
          infant: typeof data.infant_price === 'number' && data.infant_price,
          baby: typeof data.baby_price === 'number' && data.baby_price,
        },
      }))
    ));
    const newList = tmp.flat();
    if (newList.length === 0) {
      alert('선택된 옵션이 없습니다.');
      return;
    }
    setEachOptionSelectNum(newList);
    setScreenState('peopleNum');
  };

  const onSecondNext = () => {
    const maxPeopleNum = productData.maximum - productData.current;
    let totalPeopleNum = 0;
    const peopleNum = {
      adult: 0,
      child: 0,
      infant: 0,
      baby: 0,
    };
    const totalList = EachOptionSelectNum.map((data) => {
      ageList.map((age) => {
        peopleNum[age] += data.count[age];
        totalPeopleNum += data.count[age];
        return 0;
      });
      return data.count.total;
    });
    if (totalList.indexOf(0) !== -1) {
      alert('인원수가 0인 옵션이 있습니다.');
      return;
    }
    if (totalPeopleNum > maxPeopleNum) {
      alert(`해당 상품의 신청 가능 인원수를 초과하였습니다. 현재 신청 가능 인원수는 ${maxPeopleNum}명입니다.`);
      return;
    }
    const tmp = ageList.map((age) => [...Array(peopleNum[age])].map((_, i) => ({
      age_type: age,
      korean_name: '',
      english_last_name: '',
      english_first_name: '',
      birth: '',
      phone: '',
      gender: -1,
      displayName: `${ageToString[age]} ${i + 1}`,
    })));
    setPeopleInfoList(tmp.flat());
    setScreenState('peopleInfo');
  };

  const onThirdNext = () => {
    const error = {
      isError: false,
      person: '',
      place: '',
    };
    PeopleInfoList.map((data) => {
      const setError = (ePlace: string) => {
        error.isError = true;
        error.person = data.displayName;
        error.place = ePlace;
      };
      if (error.isError) return 0;
      if (!(`${data.gender}` === '0' || `${data.gender}` === '1')) setError('성별');
      else if (!nameRegExp.test(data.korean_name)) setError('이름');
      else if (!englishNameRegExp.test(data.english_last_name)) setError('영문 성');
      else if (!englishNameRegExp.test(data.english_first_name)) setError('영문 이름');
      else if (!mobileRegExp.test(data.phone)) setError('전화번호');
      else if (data.birth === '') setError('생년월일');
      return 0;
    });
    if (!UseReserverInstead && !error.isError) {
      const setError = (ePlace: string) => {
        error.isError = true;
        error.person = '예약자';
        error.place = ePlace;
      };
      if (!(`${ReserverInfo.gender}` === '0' || `${ReserverInfo.gender}` === '1')) setError('성별');
      else if (!nameRegExp.test(ReserverInfo.korean_name)) setError('이름');
      else if (!mobileRegExp.test(ReserverInfo.phone)) setError('전화번호');
      else if (ReserverInfo.birth === '') setError('생년월일');
    }
    if (!error.isError && !emailRegExp.test(ReserverInfo.email)) {
      error.isError = true;
      error.person = '예약자';
      error.place = '이메일';
    }
    if (error.isError) {
      alert(`${error.person}의 ${error.place}을(를) 확인하세요.`);
      return;
    }

    const priceInfo = {
      price: 0,
    };
    EachOptionSelectNum.map((d) => {
      ageList.map((age) => {
        if (typeof d.price[age] === 'number') priceInfo.price += (d.price[age] as number) * d.count[age];
        return 0;
      });
      return 0;
    });
    AddiOptionSelectNum.map((d) => {
      priceInfo.price += d.price * d.count;
      return 0;
    });

    const reserverRealInfo: {
      korean_name: string,
      gender: number,
      birth: string,
      phone: string
    } = UseReserverInstead ? PeopleInfoList[0] : ReserverInfo;
    const reserveData: reserveI = {
      event_detail_idx: productData.idx,
      good_idx: productData.good_idx,
      options: EachOptionSelectNum.map((d) => ({
        option_idx: d.idx,
        adult_count: d.count.adult,
        child_count: d.count.child,
        infant_count: d.count.infant,
        baby_count: d.count.baby,
      })),
      additional_options: AddiOptionSelectNum.map((d) => {
        if (d.count === 0) return [];
        return {
          option_idx: d.idx,
          count: d.count,
        };
      }).flat(),
      people_arr: PeopleInfoList.map((d) => ({
        ...d,
        age_type: ageToNumber[d.age_type],
      })),
      rsv_email: ReserverInfo.email,
      rsv_birth: reserverRealInfo.birth,
      rsv_gender: reserverRealInfo.gender,
      rsv_name: reserverRealInfo.korean_name,
      rsv_phone: reserverRealInfo.phone,
      total_price: priceInfo.price,
    };
    // console.log(JSON.stringify(reserveData));
    setScreenState('loading');
    (async () => {
      try {
        const res = await axios.post<{result: {insertId: number}}>('/api/reservation', reserveData, loginData?.login ? loginData.axiosConfig : {});
        navigate(`/mypage/reservation/${res.data.result.insertId}`);
      } catch {
        setScreenState('peopleInfo');
      }
    })();
  };

  const changeOptionNum = (type: 'option'|'addiOption', lisIdx: number, diff: number) => {
    const newArray = [...type === 'option' ? OptionSelectNum : AddiOptionSelectNum];
    const newEach = { ...newArray[lisIdx] };
    const newNum = newEach.count + diff;
    if (newNum >= 0) {
      newEach.count = newNum;
      newArray[lisIdx] = newEach;
      if (type === 'option') {
        setOptionSelectNum(newArray as unknown as any);
      } else if ((typeof newEach.maximum !== 'number') || (newNum <= newEach.maximum)) {
        setAddiOptionSelectNum(newArray as unknown as any);
      }
    }
  };

  const changePeopleNum = (lisIdx: number, diff: number, age: ageI) => {
    const newArray = [...EachOptionSelectNum];
    const newEach = { ...newArray[lisIdx] };
    const newNum = newEach.count[age] + diff;
    const newTotal = newEach.count.total + diff;
    if (newNum >= 0 && ((typeof newEach.maximum !== 'number' || newTotal <= newEach.maximum))) {
      const tmpCounts = { ...newEach.count };
      tmpCounts.total = newTotal;
      tmpCounts[age] = newNum;
      newEach.count = tmpCounts;
      newArray[lisIdx] = newEach;
      setEachOptionSelectNum(newArray);
    }
  };

  const inputOnChange = (lisIdx: number, type: 'korean_name' | 'english_last_name' | 'english_first_name' | 'birth' | 'phone' | 'gender', newValue: any) => {
    const newArray = [...PeopleInfoList];
    const newEach = { ...newArray[lisIdx] };
    (newEach[type] as any) = newValue;
    newArray[lisIdx] = newEach;
    // console.log(newEach);
    setPeopleInfoList(newArray);
  };

  const reserverOnChange = (type: 'korean_name' | 'birth' | 'phone' | 'gender' | 'email', newValue: any) => {
    const newObj = { ...ReserverInfo };
    (newObj[type] as any) = newValue;
    setReserverInfo(newObj);
  };

  const windowOptionNum = (
    <div className={`${styles.windowOptionNumDiv} ${styles.innerScreen}`}>
      <div className={styles.innerTitle}>옵션 선택</div>
      <div className={styles.optionsListDiv}>
        {
          OptionSelectNum.map((data, i) => (
            <div className={styles.optionSelectEach} key={`optionSelectNumOption${data.option_name}`}>
              <div className={styles.optionName}>{data.option_name}</div>
              <NumberChanger
                innerNum={data.count}
                onMinusClick={() => changeOptionNum('option', i, -1)}
                onPlusClick={() => changeOptionNum('option', i, 1)}
                isMinusAvail={data.count > 0}
                isPlusAvail
              />
            </div>
          ))
        }
      </div>
      <div className={styles.line} />
      <div className={styles.innerTitle}>추가옵션 선택</div>
      <div className={styles.optionsListDiv}>
        {
          AddiOptionSelectNum.map((data, i) => (
            <div className={styles.optionSelectEach} key={`optionSelectNumAddiOption${data.option_name}`}>
              <div className={styles.optionName}>{`${data.option_name} (${data.price.toLocaleString('ko-KR')}원)`}</div>
              <NumberChanger
                innerNum={data.count}
                onMinusClick={() => changeOptionNum('addiOption', i, -1)}
                onPlusClick={() => changeOptionNum('addiOption', i, 1)}
                isMinusAvail={data.count > 0}
                isPlusAvail={(typeof data.maximum !== 'number' || data.count < data.maximum)}
              />
            </div>
          ))
        }
      </div>
      <button type="button" className={styles.nextBtn} style={{ background: '#425571' }} onClick={() => onFirstNext()}>인원 선택</button>
    </div>
  );

  const windowPeopleNum = (
    <div className={`${styles.windowPeopleNumDiv} ${styles.innerScreen}`}>
      <div className={styles.innerTitle}>인원 선택</div>
      {
        EachOptionSelectNum.map((data, i) => (
          <div className={styles.eachOptionPeopleDiv}>
            <div className={styles.eachOptionTitleDiv}>
              <div className={styles.eachOptionTitleTitle}>{data.option_name}</div>
              {typeof data.maximum === 'number' && <div className={styles.eachOptionTitleMax}>{`최대 ${data.maximum}인`}</div>}
            </div>
            <div className={styles.eachOptionNumListDiv}>
              {
                ageList.map((age) => {
                  if (typeof data.price[age] !== 'number') return false;
                  return (
                    <div className={styles.optionSelectEach} key={`optionSelectNumPeople${data.option_name}${i}${age}`}>
                      <div className={styles.optionName}>{`${ageToString[age]}`}</div>
                      <NumberChanger
                        innerNum={data.count[age]}
                        onMinusClick={() => changePeopleNum(i, -1, age)}
                        onPlusClick={() => changePeopleNum(i, 1, age)}
                        isMinusAvail={data.count[age] > 0}
                        isPlusAvail={typeof data.maximum !== 'number' || data.count.total < data.maximum}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
        ))
      }
      <button type="button" className={styles.nextBtn} style={{ background: '#425571' }} onClick={() => onSecondNext()}>예약자 정보 입력</button>
    </div>
  );

  const windowPeopleInfo = (
    <div className={`${styles.windowPeopleInfoDiv} ${styles.innerScreen}`}>
      <div className={styles.innerTitle}>여행자 정보 입력</div>
      <div className={styles.eachPeopleInfoDiv}>
        <div className={styles.eachDisplayName}>
          예약자
          <div
            className={`${styles.eachAgreeCheckDiv} ${UseReserverInstead && styles.checkOn}`}
            onClick={() => setUseReserverInstead((val) => !val)}
          >
            {checkSvg}
            <div>{`${PeopleInfoList.length > 0 ? PeopleInfoList[0].displayName : 'asdf'} 정보와 동일`}</div>
          </div>
        </div>
        <div className={styles.eachPeopleFormDiv}>
          {
            UseReserverInstead
            || (
              <>
                <EachForm title="이름" desc="">
                  <input type="text" onChange={(e) => reserverOnChange('korean_name', e.target.value)} value={ReserverInfo.korean_name} />
                </EachForm>
                <EachForm title="성별" desc="">
                  <form className={signupStyles.formsRadioDiv}>
                    <div>
                      <input type="radio" id="male" name="gender" value={1} onChange={(e) => reserverOnChange('gender', e.target.value)} checked={`${ReserverInfo.gender}` === '1'} />
                      <label htmlFor="male">남성</label>
                    </div>
                    <div>
                      <input type="radio" id="female" name="gender" value={0} onChange={(e) => reserverOnChange('gender', e.target.value)} checked={`${ReserverInfo.gender}` === '0'} />
                      <label htmlFor="female">여성</label>
                    </div>
                  </form>
                </EachForm>
                <EachForm title="생년월일" desc="">
                  <input type="date" onChange={(e) => reserverOnChange('birth', e.target.value)} value={ReserverInfo.birth} />
                </EachForm>
                <EachForm title="전화번호" desc="">
                  <input type="tel" placeholder="ex) 01000000000" onChange={(e) => reserverOnChange('phone', e.target.value)} value={ReserverInfo.phone} />
                </EachForm>

              </>
            )
          }

          <EachForm title="이메일" desc="">
            <input type="email" placeholder="ex) example@alpstour.co.kr" onChange={(e) => reserverOnChange('email', e.target.value)} value={ReserverInfo.email} />
          </EachForm>
        </div>
      </div>
      {
        PeopleInfoList.map((data, i) => (
          <div className={styles.eachPeopleInfoDiv}>
            <div className={styles.eachDisplayName}>{data.displayName}</div>
            <div className={styles.eachPeopleFormDiv}>
              <EachForm title="이름" desc="">
                <input type="text" onChange={(e) => inputOnChange(i, 'korean_name', e.target.value)} value={data.korean_name} />
              </EachForm>
              <EachForm title="성별" desc="">
                <form className={signupStyles.formsRadioDiv}>
                  <div>
                    <input type="radio" id="male" name="gender" value={1} onChange={(e) => inputOnChange(i, 'gender', e.target.value)} checked={`${data.gender}` === '1'} />
                    <label htmlFor="male">남성</label>
                  </div>
                  <div>
                    <input type="radio" id="female" name="gender" value={0} onChange={(e) => inputOnChange(i, 'gender', e.target.value)} checked={`${data.gender}` === '0'} />
                    <label htmlFor="female">여성</label>
                  </div>
                </form>
              </EachForm>
              <EachForm title="영문 성" desc="">
                <input type="text" placeholder="ex) Hong" onChange={(e) => inputOnChange(i, 'english_last_name', e.target.value)} value={data.english_last_name} />
              </EachForm>
              <EachForm title="영문 이름" desc="">
                <input type="text" placeholder="ex) Gildong" onChange={(e) => inputOnChange(i, 'english_first_name', e.target.value)} value={data.english_first_name} />
              </EachForm>
              <EachForm title="생년월일" desc="">
                <input type="date" onChange={(e) => inputOnChange(i, 'birth', e.target.value)} value={data.birth} />
              </EachForm>
              <EachForm title="전화번호" desc="">
                <input type="tel" placeholder="ex) 01000000000" onChange={(e) => inputOnChange(i, 'phone', e.target.value)} value={data.phone} />
              </EachForm>
            </div>
          </div>
        ))
      }
      <button type="button" className={styles.nextBtn} style={{ background: '#3386FF' }} onClick={() => onThirdNext()}>예약하기</button>
    </div>
  );

  const windowLoading = (
    <div className={styles.innerScreen}>
      <div className={styles.innerTitle}>잠시만 기다려주세요</div>
    </div>
  );

  const stateToComponent = {
    optionNum: windowOptionNum,
    peopleNum: windowPeopleNum,
    peopleInfo: windowPeopleInfo,
    loading: windowLoading,
  };

  return (
    <div className={styles.reserveWrapper}>
      {
        productData && (
          <div className={styles.reserveSideDiv}>
            <img className={styles.sideImg} src={productData.images[0]} alt={productData.title} />
            <BlankMaker height={10} />
            <div className={styles.sideTitle}>{productData.title}</div>
            <BlankMaker height={10} />
            <div className={styles.sideDesc}>{productData.description}</div>
            <BlankMaker height={10} />
            <div className={styles.sideDateStart}>{`${productData.departure_start_time} 출발`}</div>
            <div className={styles.sideDateEnd}>{`~ ${productData.arrival_end_time} 도착`}</div>
          </div>
        )
      }
      <div className={styles.reserveDiv}>
        <TopBar text="예약하기" />
        {
          stateToComponent[ScreenState]
        }
      </div>
    </div>
  );
}

export default Reserve;
