import React from 'react';
import WithNav from './WithNav';

function WithCustomerNav(props: { children: React.ReactChild }) {
  const { children } = props;
  return (
    <WithNav
      title="고객센터"
      navList={[
        {
          title: '공지사항',
          url: '/notice',
        },
        {
          title: '자주 묻는 질문',
          url: '/faq',
        },
        {
          title: '1:1 문의',
          url: '/qna',
        },
        {
          title: '여행후기',
          url: '/review',
        },
      ]}
    >
      {children}
    </WithNav>
  );
}

export default WithCustomerNav;
