/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import TopBar from '../Components/TopBar';
import styles from '../Css/EachNotice.module.css';

function QNANew() {
  const navigate = useNavigate();
  const { loginData, getLoginConfig } = useLogin();
  const [Data, setData] = useState<{
    title: string
    content: string
    author_name: string
    password: string | null
  }>({
    title: '', content: '', author_name: '', password: null,
  });
  const check = () => {
    const ans = {
      isError: false,
      errorMsg: '',
    };
    const setError = (msg: string) => {
      ans.isError = true;
      ans.errorMsg = `${msg}을(를) 확인하세요`;
    };
    if (Data.title.trim().length === 0) setError('제목');
    else if (Data.author_name.trim().length === 0) setError('작성자명');
    else if (Data.content.trim().length === 0) setError('내용');
    else if ((!(loginData?.login)) && (!Data.password || Data.password.trim().length === 0)) setError('비밀번호');
    else if ((!(loginData?.login)) && (Data.password && Data.password.trim().length > 10)) {
      ans.isError = true;
      ans.errorMsg = '비밀번호는 최대 10자까지 가능합니다.';
    }
    return ans;
  };
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="질문과 답변 - 글쓰기" />
      <div className={styles.newQNADiv}>
        <input type="text" placeholder="제목" value={Data.title} onChange={(e) => setData({ ...Data, title: e.target.value })} />
        {(!(loginData?.login)) && <input type="text" placeholder="비밀번호" value={Data.password || ''} onChange={(e) => setData({ ...Data, password: e.target.value })} />}
        <input type="text" placeholder="작성자명" value={Data.author_name} onChange={(e) => setData({ ...Data, author_name: e.target.value })} />
        <textarea autoComplete="false" placeholder="내용" value={Data.content} onChange={(e) => setData({ ...Data, content: e.target.value })} />
      </div>
      <button
        className={styles.deleteButton}
        type="button"
        onClick={async () => {
          const checked = check();
          if (checked.isError) {
            alert(checked.errorMsg);
            return;
          }
          try {
            const res = await axios.post<{statusCode: number}>('/api/board/qna', Data, getLoginConfig());
            if (res.data.statusCode !== 201) throw Error;
            alert('등록했습니다.');
            navigate('/qna');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        글쓰기

      </button>
    </div>
  );
}

export default QNANew;
