import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from './useLogin';

export default function useLoginCheck(notLoginCallback?: ()=>void) {
  const { loginData, loginIsValidating } = useLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if ((!loginIsValidating) && !(loginData?.login)) {
      if (notLoginCallback) notLoginCallback();
      else navigate('/login');
    }
  }, [loginData, loginIsValidating]);
}
