import axios from 'axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Desktop/Components/BlankMaker';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import TopBar from '../Components/TopBar';
import styles from '../Css/MyReservation.module.css';

export interface resInfo {
  'reservation': {
      'rsv_number': string,
      'rsv_name': string,
      'rsv_email': string,
      'rsv_phone': string,
      'rsv_comment': string,
      'rsv_date': string,
  },
  'event': {
      'title': string,
      'days': number,
      'nights': number,
      'departure_start_time': string,
      'departure_end_time': string,
      'departure_transportation': string,
      'arrival_start_time': string,
      'arrival_end_time': string,
      'arrival_transportation': string,
      'total_price': number,
      'status': string
  },
  'options': {
    'option_name': string,
    count: number,
    price: number,
    total_price: number,
  }[],
  'people': {
    'age_type': '성인' | '소아' | '유아' | '영아',
    'korean_name': string,
    'english_last_name': string,
    'english_first_name': string,
    'birth': string,
    'phone': string,
    'gender': number
  }[],
  payment: {
    state: string,
    good_price: number,
    mileage: number,
    total_price: number,
    payment_info: string | null | undefined,
    payment_date: string | null | undefined,
  } | null | undefined
}

function MyResInfo() {
  const { idx } = useParams();
  const { loginData } = useLogin();
  const navigate = useNavigate();
  const { data, error, isValidating } = useSWR(loginData?.login && `/api/reservation/${idx}`, async (url) => {
    if (!loginData?.login) throw Error;
    const res = await axios.get<{result: resInfo}>(url, loginData.axiosConfig);
    return res.data.result;
  });
  useLoginCheck();
  if (loginData?.login && error && !isValidating) navigate('/');
  if (error || !data) return <div />;
  const isActiveState = (state: string) => (state === '결제완료' || state === '입금대기' || state === '예약접수' || state === '예약확정');
  const infoList = [
    ['여행기간', `${data.event.nights}박 ${data.event.days}일`],
    ['출발시간', `${data.event.departure_start_time}`],
    ['도착시간', `${data.event.arrival_end_time}`],
    ['예약일', `${data.reservation.rsv_date}`],
    ['예약자명', `${data.reservation.rsv_name}`],
    ['연락처', `${data.reservation.rsv_phone}`],
    ['이메일', `${data.reservation.rsv_email}`],
    ['요청사항', `${data.reservation.rsv_comment}`],
  ];
  return (
    <div className={styles.myResDetailDiv}>
      <TopBar text="예약상세" noUnderLine />
      <div className={`${styles.myResDetailInner} ${isActiveState(data.event.status) ? styles.active : styles.inactive}`}>
        <div className={styles.resNumDiv}>
          <div className={styles.resNumTitle}>예약번호</div>
          <div className={styles.resNumNum}>{data.reservation.rsv_number}</div>
        </div>
        <div className={styles.eachResTitle}>{data.event.title}</div>
        {
          infoList.map(([left, right]) => (
            <div className={styles.eachResInfoDiv}>
              <div className={styles.eachResInfoTitle}>{left}</div>
              <div className={styles.eachResInfoText}>{right}</div>
            </div>
          ))
        }
        <BlankMaker height={30} />
        <div className={styles.eachResInfoDiv}>
          <div className={styles.eachResInfoTitle}>가격</div>
          <div className={styles.eachResInfoText} />
        </div>
        <div className={styles.priceDiv}>
          {
            data.options.map((d) => (
              <div>
                {d.option_name}
                <span>{`${d.total_price.toLocaleString()}원`}</span>
                {`= ${d.price.toLocaleString()}원 x`}
                <span>{`${d.count.toLocaleString()}명`}</span>
              </div>
            ))
          }
          <BlankMaker height={5} />
          <div>
            총 결제금액
            {' '}
            <span>{`${data.event.total_price.toLocaleString()}원`}</span>
          </div>
        </div>

        {
          data.payment && (
            <>
              <BlankMaker height={30} />
              <div className={styles.eachResInfoDiv}>
                <div className={styles.eachResInfoTitle}>결제상태</div>
                <div className={styles.eachResInfoText}>{data.payment.state}</div>
              </div>
              {
                data.payment.payment_date && (
                  <div className={styles.eachResInfoDiv}>
                    <div className={styles.eachResInfoTitle}>결제일시</div>
                    <div className={styles.eachResInfoText}>{data.payment.payment_date}</div>
                  </div>
                )
              }
              <div className={styles.eachResInfoDiv}>
                <div className={styles.eachResInfoTitle}>총 금액</div>
                <div className={styles.eachResInfoText}>
                  {`${data.payment.good_price.toLocaleString()}원`}
                </div>
              </div>
              <div className={styles.eachResInfoDiv}>
                <div className={styles.eachResInfoTitle}>사용 적립금</div>
                <div className={styles.eachResInfoText}>
                  {`${data.payment.mileage.toLocaleString()}원`}
                </div>
              </div>
              <div className={styles.eachResInfoDiv}>
                <div className={styles.eachResInfoTitle}>최종결제금액</div>
                <div className={styles.eachResInfoText}>
                  {`${data.payment.total_price.toLocaleString()}원`}
                </div>
              </div>
              {
                data.payment.payment_info && (
                  <div className={styles.eachResInfoDiv}>
                    <div className={styles.eachResInfoTitle}>가상계좌</div>
                    <div className={styles.eachResInfoText}>{data.payment.payment_info}</div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
      {
        data.event.status === '예약확정' && (
          <button type="button" className={styles.payBtn} onClick={() => navigate('./payment')}>결제하기</button>
        )
      }
    </div>
  );
}

export default MyResInfo;
