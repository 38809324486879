import axios from 'axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import { resInfo } from '../../Mobile/Page/MyResInfo';
import BlankMaker from '../Components/BlankMaker';
import LeftRight from '../Components/LeftRight';
import Title from '../Components/Title';
import WithMypageNav from '../Components/WithMypageNav';
import styles from '../Css/MyResInfo.module.css';

function MyResInfo() {
  const { idx } = useParams();
  const { loginData } = useLogin();
  const navigate = useNavigate();
  useLoginCheck();
  const { data, error, isValidating } = useSWR(loginData?.login && `/api/reservation/${idx}`, async (url) => {
    if (!loginData?.login) throw Error;
    const res = await axios.get<{result: resInfo}>(url, loginData.axiosConfig);
    return res.data.result;
  });
  if (!data) return <WithMypageNav><div /></WithMypageNav>;
  return (
    <WithMypageNav>
      <>
        <Title>예약내역</Title>
        <BlankMaker height={20} />
        <div className={styles.littleTitle}>01. 기본정보</div>
        <div className={styles.wrapper}>
          <LeftRight leftWidth={80} haveLine>
            <div>
              <div>
                <div>예약번호</div>
                <div>{data.reservation.rsv_number}</div>
              </div>
              <div>
                <div>예약일자</div>
                <div>{data.reservation.rsv_date}</div>
              </div>
            </div>
            <div>
              <div>
                <div>예약자명</div>
                <div>{data.reservation.rsv_name}</div>
              </div>
              <div>
                <div>전화번호</div>
                <div>{data.reservation.rsv_phone}</div>
              </div>
              <div>
                <div>이메일</div>
                <div>{data.reservation.rsv_email}</div>
              </div>
            </div>
            <div>
              <div>
                <div>요청사항</div>
                <div>{data.reservation.rsv_comment}</div>
              </div>
            </div>
          </LeftRight>
        </div>
        <BlankMaker height={100} />
        <div className={styles.littleTitle}>02. 예약정보</div>
        <div className={styles.wrapper}>
          <LeftRight leftWidth={100} haveLine>
            <div>
              <div>
                <div>상품명</div>
                <div>{data.event.title}</div>
              </div>
            </div>
            <div>
              <div>
                <div>여행기간</div>
                <div>{`${data.event.nights}박 ${data.event.days}일`}</div>
              </div>
            </div>
            <div>
              <div>
                <div>출발일정</div>
                <div>{`${data.event.departure_start_time} ~ ${data.event.departure_end_time} (${data.event.departure_transportation})`}</div>
              </div>
            </div>
            <div>
              <div>
                <div>도착일정</div>
                <div>{`${data.event.arrival_start_time} ~ ${data.event.arrival_end_time} (${data.event.arrival_transportation})`}</div>
              </div>
            </div>
            <div>
              <div>
                <div>가격</div>
                <div className={styles.priceDiv}>
                  {
                    data.options.map((d) => (
                      <div>
                        {d.option_name}
                        <span>{`${d.total_price.toLocaleString()}원`}</span>
                        {`= ${d.price.toLocaleString()}원 x`}
                        <span>{`${d.count.toLocaleString()}명`}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>총 결제금액</div>
                <div>{`${data.event.total_price.toLocaleString()}원`}</div>
              </div>
            </div>
          </LeftRight>
        </div>
        <BlankMaker height={100} />
        <div className={styles.littleTitle}>03. 여행자정보</div>
        {
          data.people.map((d) => (
            <div className={`${styles.wrapper} ${styles.peopleDiv}`}>
              <LeftRight leftWidth={80} haveLine>
                <div>
                  <div>
                    <div>구분</div>
                    <div>{d.age_type}</div>
                  </div>
                  <div>
                    <div>성별</div>
                    <div>{d.gender === 0 ? '여' : '남'}</div>
                  </div>
                  <div>
                    <div>생년월일</div>
                    <div>{d.birth}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>한글명</div>
                    <div>{d.korean_name}</div>
                  </div>
                  <div>
                    <div>영문명</div>
                    <div>{`성: ${d.english_last_name} / 이름: ${d.english_first_name}`}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>전화번호</div>
                    <div>{d.phone}</div>
                  </div>
                </div>
              </LeftRight>
            </div>
          ))
        }
        {
          data.payment && (
            <>
              <BlankMaker height={100} />
              <div className={styles.littleTitle}>04. 결제정보</div>
              <div className={styles.wrapper}>
                <LeftRight leftWidth={100} haveLine>
                  <div>
                    <div>
                      <div>결제상태</div>
                      <div>{data.payment.state}</div>
                    </div>
                    {
                      data.payment.payment_date && (
                        <div>
                          <div>결제일시</div>
                          <div>{data.payment.payment_date}</div>
                        </div>
                      )
                    }
                  </div>
                  <div>
                    <div>
                      <div>총 금액</div>
                      <div>{`${data.payment.good_price.toLocaleString()}원`}</div>
                    </div>
                    <div>
                      <div>사용 적립금</div>
                      <div>{`${data.payment.mileage.toLocaleString()}원`}</div>
                    </div>
                    <div>
                      <div>결제금액</div>
                      <div>{`${data.payment.total_price.toLocaleString()}원`}</div>
                    </div>
                  </div>
                  {
                    data.payment.payment_info ? (
                      <div>
                        <div>
                          <div>요청사항</div>
                          <div>{data.reservation.rsv_comment}</div>
                        </div>
                      </div>
                    ) : ''
                  }
                </LeftRight>
              </div>
            </>
          )
        }
        <BlankMaker height={100} />
        {
          data.event.status === '예약확정' && (
            <button type="button" className={styles.payBtn} onClick={() => navigate('./payment')}>결제하기</button>
          )
        }
      </>
    </WithMypageNav>
  );
}

export default MyResInfo;
