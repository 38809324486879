import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useMenuState from './useMenuState';

export function PageChangeEvent() {
  const { pathname } = useLocation();
  const { closeAllMenu } = useMenuState();

  useEffect(() => {
    window.scrollTo(0, 0);
    closeAllMenu();
  }, [pathname]);

  return null;
}

export default PageChangeEvent;
