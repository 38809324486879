/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import styles from '../Css/Login.module.css';

function Login() {
  const [ID, setID] = useState('');
  const [PW, setPW] = useState('');
  const [isError, setIsError] = useState<boolean|string>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loginData, loginMutate } = useLogin();
  const navigate = useNavigate();
  if (loginData?.login) {
    navigate('/');
  }
  const doLogin = async () => {
    const params = new URLSearchParams();
    params.append('id', ID);
    params.append('password', PW);
    try {
      const res = await axios.post<{statusCode: 201, result: {
        result: string,
        token: {
          accessToken: string,
          refreshToken: string
        }
      }} | {statusCode: 403}>('/api/user/sign-in', params);
      if (res.data.statusCode === 201) {
        localStorage.setItem('accessToken', res.data.result.token.accessToken);
        loginMutate();
      } else {
        setIsError('아이디 또는 비밀번호가 일치하지 않습니다.');
      }
    } catch (e) {
      setIsError('아이디 또는 비밀번호가 일치하지 않습니다.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={`${styles.loginDiv} ${isLoading && styles.loginLoading}`}>
      <div className={styles.titleDiv}>로그인</div>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (ID.trim().length > 0 && PW.trim().length > 0 && !isLoading) {
          setIsLoading(true);
          doLogin();
        }
      }}
      >
        <input type="text" placeholder="아이디" value={ID} onChange={(e) => setID(e.target.value)} />
        <input type="password" placeholder="비밀번호" value={PW} onChange={(e) => setPW(e.target.value)} />
        {
          isError && <div>{isError}</div>
        }
        <button type="submit">로그인</button>
      </form>
      <button
        type="button"
        className={`${styles.socialButton} ${styles.socialNaver}`}
        onClick={() => {
          location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=Da5mVpkiTLNqfU_vYLk4&state=STATE_STRING&redirect_uri=${location.origin}/logincallback/naver`;
        }}
      >
        <svg
          version="1.1"
          id="naver"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 200 200"
        >
          <polygon className="logo" fill="#FFFFFF" points="115.9,145.8 83.7,98.4 83.7,145.8 50,145.8 50,54.3 84.2,54.3 116.4,101.6 116.4,54.3    150,54.3 150,145.8 115.9,145.8" />
        </svg>
        <div>네이버 로그인</div>
      </button>
      <button
        type="button"
        className={`${styles.socialButton} ${styles.socialKakao}`}
        onClick={() => {
          (window as any).Kakao.Auth.authorize({
            redirectUri: `${location.origin}/logincallback/kakao`,
          });
        }}
      >
        <svg
          id="kakao"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-75 -90 350 350"
        >
          <polygon className="kakao logo" fill="#3c1e1e" points="45 140 40 185 90 150 45 140" />
          <ellipse className="kakao logo" fill="#3c1e1e" cx="100" cy="80" rx="100" ry="80" />
        </svg>
        <div>카카오 로그인</div>
      </button>
      <div className={styles.linksDiv}>
        {/* <div className={styles.link}><Link to="/">비밀번호 찾기</Link></div>
        <div className={styles.linkLine} /> */}
        <div className={styles.link}><Link to="/signup">회원가입</Link></div>
      </div>
    </div>
  );
}

export default Login;
