/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMenuState from '../../Middle/useMenuState';
import Menu from '../Components/Menu';
import styles from '../Css/Header.module.css';
import Logo from '../../meta/logo.png';

function Header() {
  const {
    menuState, setShowBlur, setMenuState, closeAllMenu, closeMenuTrigger,
  } = useMenuState();
  const [OpenSearch, setOpenSearch] = useState(false);
  const [SearchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    setOpenSearch(false);
    setSearchValue('');
  }, [closeMenuTrigger, menuState]);
  return (
    <>
      <div
        className={`${styles.headerDiv} ${OpenSearch && styles.searchOpenHeader}`}
        onClick={() => {
          if (menuState === 'header') {
            closeAllMenu();
          }
        }}
      >
        <div className={styles.iconsDiv}>
          <button
            type="button"
            className={styles.iconBtn}
            onClick={() => {
              if (menuState === 'header') {
                closeAllMenu();
              } else {
                setShowBlur(true);
                setMenuState('header');
              }
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" className={styles.iconSvg}>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
            </svg>
          </button>
        </div>
        <Link to="/">
          <img className={styles.logoImg} src={Logo} alt="알프스여행사 로고" />
        </Link>
        <div className={styles.iconsDiv}>
          <button type="button" className={`${styles.iconBtn} ${styles.searchBtn}`} onClick={() => { setOpenSearch((val) => !val); }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" className={styles.iconSvg}>
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
          </button>
          <Link to="/mypage">
            <button type="button" className={styles.iconBtn}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" className={styles.iconSvg}>
                <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg>
            </button>
          </Link>
        </div>
      </div>
      <div className={`${styles.searchDiv} ${OpenSearch && styles.searchOpen}`}>
        <div className={styles.searchWhiteDiv}>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (SearchValue.trim().length > 0) {
              navigate(`/search/${SearchValue.trim()}`);
            }
          }}
          >
            <input
              type="text"
              placeholder="여행지 및 키워드를 입력하여 검색해주세요."
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={SearchValue}
            />
            <button type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" className={styles.iconSvg}>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </button>
          </form>

        </div>
      </div>
      <Menu />
    </>
  );
}

export default Header;
