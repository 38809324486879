export const userLicense = `제 1장. 총칙
제1조(목적)
1. 이 약관은 전기통신사업법 및 동법 시행령에 의하여 '(주)알프스여행사'(이하 당사라 한다)가 제공하는 인터넷 여행 서비스(이하 서비스)인 (주)알프스여행사' 의 이용조건, 절차, 그리고 회원규칙에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합시다.
제2조(약관의 효력과 준용)
1. 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로서 효력을 발생합니다.
2. 당사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 규정함으로써 효력을 발생합니다.
제3조(약관 외 준칙)
1. 이 약관에 명시되지 않은 사항은 전기통신본법, 전기통신사업사업법 및 기타관계 법령의 규정에 따릅니다.
제4조(용어의 정의)
1. 이 규정에서 사용하는 용어의 정의는 다음과 같습니다.
1. 이 규정에서 사용하는 용어의 정의는 다음과 같습니다.
가. 이용자 : 당사가 제공하는 서비스를 이용하는 모든 자
나. 회원 : 이용자 중 당사와 서비스 이용계약을 체결한 자
다. 아이디 : 회원식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 문자 또는 이루어진 조합
라. 비밀번호 : 회원이 부여받은 아이디(ID)와 일치된 회원임을 확인하고, 회원 자신의 비밀을 보호하기 위하여 정한 문자와 숫자의 조합
마. 해지 : 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것
제 2장. 회원가입과 서비스 이용 계약
제5조(이용계약의 성립)
1. 이용 계약은 이용자의 이용 신청에 대한 당사의 이용 응낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.
2. 회원에 가입하여 서비스를 이용하고자 하는 희망자는 당사에서 요청하는 개인 신상정보를 제공해야 합니다.
3. 회원에 가입하여 서비스를 이용하고자 하는 희망자는 지정된 온라인 가입신청 양식에 정보를 필수로 제공하며, 그 외의 정보는 선택사항으로 합니다.
-이용자 번호(ID) -비밀번호(PASSWORD) -이름(실명/영문명) -전화번호와 주소
4. 위의 내용 이외에도 당사가 필요하다고 인정되는 내용을 추가로 덧붙일 수 있으며, 이 사실을 공고합니다.
제6조(이용신청의 승낙)
1. 당사는 이용자가 제2장 3조에서 정한 사항을 정확히 기재하여 이용신청을 하였을 때 승낙합니다.
2. 당사는 다음에 해당하는 이용신청에 대하여서는 승낙을 유보할 수 있습니다.
- 설비에 여유가 없거나 기술상 지장이 있는 경우
- 기타 본사가 필요하다고 인정되는 경우
3. 당사는 다음에 해당하는 이용신청에 대하여서는 이를 응낙하지 아니할 수 있습니다.
- 이름이 실명이 아닌 경우
- 다른 사람의 명의를 사용하여 신청한 경우
- 이용신청시 필요내용을 허의로 기재하여 신청한 경우
- 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
- 기타 당사가 정한 이용신청요건이 미비된 경우
- 기타 당사가 정한 이용신청요건이 미비되었을 때
제7조(계약사항의 변경)
1. 회원은 이용신청시 기재한 사항이 변경되었을 경우에는 온라인을 통해 수정을 해야합니다. 수저을 하지 않아서 발생하는 이용자의 피해에 대한 책임은 이용자에게 있습니다.
제8조(개인정보의 보호)
1. 당사는 서비스 이용자의 정보수집시 반드시 당해 이용자의 동의를 얻어 필요한 최소한의 정보만을 수집하며, 이용자는 온라인을 통하여 수시로 본인의 신장정보를 열람, 정정 또는 삭제할 수 있습니다.
2. 당사는 이용고객의 개인 신상정보를 본인의 동이없이는 절대 제3자에게 제공, 분실 또는 유출하여서는 아니되며 이를 지키지 않아서 발생하는 고객의 모든 피해에 대한 책임은 당사에 있습니다. 단, 다음의 각호에 해당하는 경우에는 예외로 합니다.
가. 전기통신기본법 등 관계법령에 의해 국가기관 등의 요구가 있는 경우
나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우
다. 배송 업무상 배송업체에게 고객의 정보를 알려주는 경우
라. 은행업무상 관련사항에 한하여 일부 정보를 공유하는 경우
마. 특정인을 식별할 수 없는 통계작성, 홍보자료, 학술연구 등의 목적인 경우
바. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
이용자의 동의 없이 개인정보가 유출되는 등 부당한 사례에 발견했을 때에는 전화 02-654-4111, 전자우편 speedtour@speedtour.co.kr, 한국소비자보호원, 경실련 등을 통해 신고할 수 있으며, 이에 대해 본사는 적절한 조치를 취할 의무가 있습니다.
제 3장. 서비스 이용
제9조(회원 아이디, 이메일, 비밀번호 관리에 대한 의무)
아이디, 이메일과 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID), 이메일(E-mail), 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
2. 자신의 아이디(ID), 이메일(E-mail)과 비밀번호가 부정하게 사용된 경우 회원은 반드시 당사에 그 사실을 통보해야 합니다.
제10조(서비스 종류)
1. 당사는 서비스의 내용과 종류를 변경할 수 있으며, 변경사항은 공주사항을 통하여 공지합니다. 서비스의 종류는 아래와 같습니다.
가. 인터넷 온라인을 통한 항공/호텔예약/판매
나. 인터넷 온라인을 통한 여행상품 예약/판매
다. 기타 여행관련 상품 및 정보 서비스
제11조(정보의 제공)
1.당사는 이용자가 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편, 유선매체, 서신우편 등의 방법으로 이용자에게 제공할 수 있습니다.
제12조(서비스 제공의 중지)
1. 당사는 다음 각 호에 해당하는 경우 서비스의 일부 혹은 전부의 제공을 중지할 수 있습니다
가. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
나. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우
다. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주등으로 정상적인 서비스 이용에 지장이 있는 경우
제13조(당사의 서비스 유지책임)
1. 당사는 서비스 제공설비를 항상 운용가능한 상태로 유지하고 서비스 제공을 위한 설비에 장애가 발생하거나 또는 그 설비가 멸실될 때에는 이를 신속하게 수리, 복구합니다.
제14조(이용자의 의무)
1. 이용자는 서비스를 이용할 때 다음 각호의 행위를 하지 않아야 합니다.
가. 부정한 ID를 사용하는 행위
나. 서비스에서 얻은 정보를 회사의 사전승낙없이 회원의 이용이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위
다. 제3자의 저작권 등 기타 권리를 침해하는 행위
리. 범죄와 결부된다고 객관적으로 판단되는 행위
마. 기타 관계법령에 위배되는 행위
2. 이용자는 이 규정에서 규정하는 사항과 서비스 이용안내, 공지사항에 기재하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.
3. 이용자는 회사의 사전승낙없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 이용자가 규정에 위반한 영업활동을 이용하여 발생한 결과에 대하여 책임을 지지 않습니다.
제 4장. 서비스 이용요금
제15조(요금의 체계 등)
1. 당사가 제공하는 서비스 요금체계는 다음과 같습니다.
-가입 : 무료
-여행관련 정보열람 : 무료
-여행관련 서비스 예약 및 판매 : 유료
-당사는 서비스 요금을 변경할 수 있으며, 요금에 관한 세부사항은 따로 공지합니다.
제 5장. 계약의 해지 및 서비스 이용제한
제16조(계약해지)
1. 회원이 이용계약을 해지하고자 하는 때에는 회원본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.
2. 당사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지없이 이용계약을 해지하거나 기간을 정하여 서비스 이용을 중지할 수 있습니다.
가. 부정한 ID를 사용하거나 이메일(E-mail) 및 비밀번호를 도용한 경우
나. 서비스 운영을 고의로 방해한 경우
다. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
라. 타인의 명예를 손상시키거나 불이익을 주는 경우
마. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우
바. 기타 회사가 정한 이용조건에 위배되는 경우
제17조(이용제한에 관한 사항)
1. 당사가 제 5 장 제1조 2항 각호의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유과 일시, 기간, 기타사항을 정하여 이메일(E-mail)이나 기타방법에 의하여 해당 이용자나 대리인에게 통지합니다. 단, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.
2. 제1항의 규정에 의해 이용제한의 통지를 받은 이용자 또는 대리인은 그 이용정지의 통지에 대하여 이의가 있을 경우 이의신청을 할 수 있습니다.
3. 당사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
4. 회사는 이용정지 기간중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시 해제합니다.
제 6장. 손해배상 및 면책조항
제18조(손해배상에 관한 사항)
1. 당사는 무료 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 관하여도 책임지지 않습니다.
2. 당사는 유료로 제공되는 서비스와 관련되어 회사의 귀책사유로 발생하는 모든 손해에 관하여 관계법률이 정하는 손해배상을 해당회원에게 하여야 합니다.
제19조(면책조항)
1. 당사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2. 당사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
3. 당사는 회원이 서비스를 이용하여 기대되는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
4. 당사는 회원이 서비스에 게제한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.
제20조(관할법원)
1. 서비스의 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.`;

export const personalInfoProtect = `'(주)알프스여행사'는 (이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

수집하는 개인정보 항목
회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
ο 수집항목 : 이름 , 생년월일 , 성별 , 로그인ID , 비밀번호 , 비밀번호 질문과 답변 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 ,  회사전화번호 , 결혼여부 , 기념일 , 법정대리인정보 ,  은행계좌 정보 , 서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP 정보 , 결제기록
ο 개인정보 수집방법 : 홈페이지 , 서면양식 , 전화/팩스를 통한 회원가입 , 경품 행사 응모 , 배송 요청 , 제휴사로부터의 제공 , 생성정보 수집 툴을 통한 수집
개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 , 금융거래 본인 인증 및 금융 서비스 , 요금추심
ο 회원 관리
회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인 , 불만처리 등 민원처리 , 고지사항 전달
ο 마케팅 및 광고에 활용
신규 서비스(제품) 개발 및 특화 , 이벤트 등 광고성 정보 전달 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
개인정보의 보유 및 이용기간
원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
보존 항목 : 이름 , 생년월일 , 휴대전화번호
보존 근거 : 고객관리
보존 기간 : 5년
그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)
계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률
개인정보의 파기절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
ο 파기절차 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
ο 파기방법
- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
개인정보 제공
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
- 이용자들이 사전에 동의한 경우
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
수집한 개인정보의 위탁
회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
이용자 혹은 만 14세 미만 아동의 개인정보 조회/수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭 하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “회사 가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
쿠키(Cookie)를 설치, 운영하고 있는 이용자는 이를 거부할 수 있습니다.
쿠키는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.
ο 쿠키란 무엇일까요?
쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.
ο 쿠키를 왜 사용하나요?
쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한
이용을 위해 서비스 개선에 활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를 이용할 수 있게 됩니다.
또한 여러 서비스의 접속 빈도, 방문 시간, 각종 이벤트 참여 정도, 방문 회수 등을 분석하여 이용자의 취향과 관심분야를 파악합니다. 이를 바탕으로 광고를 포함한 개인 맞춤형 서비스를 제공합니다.
ο 쿠키를 수집하지 못하게 거부하고 싶다면?
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써
모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.
다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
설정 방법의 예
1) Internet Explorer의 경우 :
웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정
2) Chrome의 경우 :
웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키
개인정보에 관한 민원서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
고객서비스담당 부서 : -
전화번호 : -
이메일 : -
개인정보관리책임자 성명 : {shop_personal_infor_manager}
이메일 : {shop_personal_infor_email}
귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
1.개인분쟁조정위원회 (www.1336.or.kr/1336)
2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)`;

export const personalInfoUse = `제1조 (목적)

이 약관은 (주)알프스여행사(이하 ‘당사’라 함)와 여행자가 체결한 국내여행계약의 세부 이행 및 준수사항을 정함을 목적으로 합니다.

제2조(여행의 종류 및 정의)

여행의 종류와 정의는 다음과 같습니다.

일반모집여행 : 여행사가 수립한 여행조건에 따라 여행자를 모집하여 실시하는 여행.
희망여행 : 여행자가 희망하는 여행조건에 따라 여행사가 실시하는 여행.
위탁모집여행 : 여행사가 만든 모집여행상품의 여행자 모집을 타 여행업체에 위탁하여 실시하는 여행.

제3조 (‘당사’와 여행자 의무)

‘당사’는 여행자에게 안전하고 만족스러운 여행서비스를 제공하기 위하여 여행알선 및 안내, 운송, 숙박 등 여행계획의 수립 및 실행과정에서 맡은 바 임무를 충실히 수행하여야 합니다.
여행자는 안전하고 즐거운 여행을 위하여 여행자간 화합도모 및 ‘당사’의 여행질서 유지에 적극 협조하여야 합니다.

제4조(계약의 구성)

여행계약은 여행계약서(붙임)와 여행약관,여행일정표(또는 여행설명서)를 계약내용으로 합니다.
여행계약서에는 ‘당사’의 상호, 소재지 및 관광진흥법 제9조에 따른 보증보험 등의 가입(또는 영업보증금의 예치 현황) 내용이 포함되어야 합니다.
여행일정표(또는 여행설명서)에는 여행일자별 여행지와 관광내용·교통수단·쇼핑횟수·숙박장소·식사 등 여행실시일정 및 ‘당사’ 제공 서비스 내용과 여행자 유의사항이 포함되어야 합니다.

제5조(계약체결 거절)
‘당사’는 여행자에게 다음 각 호의 1에 해당하는 사유가 있을 경우에는 여행자와의 계약체결을 거절할 수 있습니다.

질병, 신체이상 등의 사유로 개별관리가 필요하거나, 단체여행(다른 여행자의 여행에 지장을 초래하는 등)의 원활한 실시에 지장이 있다고 인정되는 경우
계약서에 명시한 최대행사인원이 초과된 경우

제6조(특약)
‘당사’와 여행자는 관련법규에 위반되지 않는 범위내에서 서면(전자문서를 포함한다. 이하 같다)으로 특약을 맺을 수 있습니다. 이 경우 ‘당사’는 특약의 내용이 표준약관과 다르고 표준약관보다 우선 적용됨을 여행자에게 설명하고 별도의 확인을 받아야 합니다.


제7조(계약서 등 교부 및 안전정보 제공)
‘당사’는 여행자와 여행계약을 체결한 경우 계약서와 여행약관, 여행일정표(또는 여행설명서)를 각 1부씩 여행자에게 교부하고, 여행목적지에 관한 안전정보를 제공하여야 합니다. 또한 여행 출발 전 해당 여행지에 대한 안전정보가 변경된 경우에도 변경된 안전정보를 제공하여야 합니다.


제8조(계약서 및 약관 등 교부 간주)
다음 각 호의 경우에는 ‘당사’가 여행자에게 여행계약서와 여행약관 및 여행일정표(또는 여행설명서)가 교부된 것으로 간주합니다.

여행자가 인터넷 등 전자정보망으로 제공된 여행계약서, 약관 및 여행일정표(또는 여행설명서)의 내용에 동의하고 여행계약의 체결을 신청한 데 대해 ‘당사’가 전자정보망 내지 기계적 장치 등을 이용하여 여행자에게 승낙의 의사를 통지한 경우
‘당사’가 팩시밀리 등 기계적 장치를 이용하여 제공한 여행계약서, 약관 및 여행일정표(또는 여행설명서)의 내용에 대하여 여행자가 동의하고 여행계약의 체결을 신청하는 서면을 송부한 데 대해 ‘당사’가 전자정보망 내지 기계적 장치 등을 이용하여 여행자에게 승낙의 의사를 통지한 경우

제9조(여행요금)
여행계약서의 여행요금에는 다음 각 호가 포함됩니다. 다만, 희망여행은 당사자간 합의에 따르며, 다음의 1~8호는 여행자 본인이 직접 여행지에서 지불하여야 할 금액이나 ‘당사’가 여행자 편의를 위하여 수탁받아 이를 대신 지불합니다.
항공기, 선박, 철도 등 이용운송기관의 운임(보통운임기준)
공항, 역, 부두와 호텔사이 등 송영버스요금
숙박요금 및 식사요금
안내자경비
여행 중 필요한 각종세금
국내 공항·항만 이용료
일정표내 관광지 입장료
기타 개별계약에 따른 비용
여행알선수수료
여행자는 계약 체결시 계약금(여행요금 중 계약금으로 별도 고지된 금액)을 ‘당사’에게 지급하여야 하며, 계약금은 여행요금 또는 손해배상액의 전부 또는 일부로 취급합니다.
여행자는 제1항의 여행요금 중 계약금을 제외한 잔금을 여행출발 전일까지 ‘당사’에게 지급하여야 합니다.
여행자는 제1항의 여행요금을 당사자가 약정한 바에 따라 카드, 계좌이체 또는 무통장입금 등의 방법으로 지급하여야 합니다.
희망여행요금에 여행자 보험료가 포함되는 경우 ‘당사’는 보험회사명, 보상내용 등을 여행자에게 설명하여야 합니다.

제10조(여행조건의 변경요건 및 요금 등의 정산)
계약서 등에 명시된 여행조건은 다음 각 호의 1의 경우에 한하여 변경될 수 있습니다.
여행자의 안전과 보호를 위하여 여행자의 요청 또는 현지사정에 의하여 부득이하다고 쌍방이 합의한 경우
천재지변, 전란, 정부의 명령, 운송 숙박기관 등의 파업 휴업 등으로 여행의 목적을 달성할 수 없는 경우
‘당사’가 계약서 등에 명시된 여행일정을 변경하는 경우에는 해당 날짜의 일정이 시작되기 전에 여행자의 서면 동의를 받아야 합니다. 이때 서면동의서에는 변경일시, 변경내용, 변경으로 발생하는 비용이 포함되어야 합니다.
천재지변, 사고, 납치 등 긴급한 사유가 발생하여 여행자로부터 여행일정 변경 동의를 받기 어렵다고 인정되는 경우에는 제2항에 따른 일정변경 동의서를 받지 아니할 수 있습니다. 다만, ‘당사’는 사후에 서면으로 그 변경 사유 및 비용 등을 설명하여야 합니다.
제1항의 여행조건 변경으로 인하여 제9조제1항의 여행요금에 증감이 생기는 경우에는 여행출발 전 변경 분은 여행출발 이전에, 여행 중 변경 분은 여행종료 후 10일 이내에 각각 정산(환급)하여야 합니다.
제1항의 규정에 의하지 아니하고 여행조건이 변경되거나 제13조 내지 제15조의 규정에 의한 계약의 해제. 해지로 인하여 손해배상액이 발생한 경우에는 여행출발 전 발생 분은 여행출발이전에, 여행 중 발생 분은 여행 종료 후 10일 이내에 각각 정산(환급)하여야 합니다.
여행자는 여행출발후 자기의 사정으로 숙박, 식사, 관광 등 여행요금에 포함된 서비스를 제공받지 못한 경우 ‘당사’에게 그에 상응하는 요금의 환급을 청구할 수 없습니다. 다만, 여행이 중도에 종료된 경우에는 제15조에 준하여 처리합니다.

제11조(여행자 지위의 양도)
여행자가 개인사정 등으로 여행자의 지위를 양도하기 위해서는 ‘당사’의 승낙을 받아야 합니다. 이때 ‘당사’는 여행자 또는 여행자의 지위를 양도받으려는 자가 양도로 발생하는 비용을 지급할것을 조건으로 양도를 승낙할 수 있습니다.
전항의 양도로 발생하는 비용이 있을 경우 ‘당사’는 기한을 정하여 그 비용의 지급을 청구하여야 합니다.
‘당사’는 계약조건 또는 양도하기 어려운 불가피한 사정 등을 이유로 제1항의 양도를 승낙하지 않을 수 있습니다.
제1항의 양도는 ‘당사’가 승낙한 때 효력이 발생합니다. 다만, ‘당사’가 양도로 인해 발생한 비용의 지급을 조건으로 승낙한 경우에는 정해진 기한내에 비용이 지급되는 즉시 효력이 발생합니다.
여행자의 지위가 양도되면, 여행계약과 관련한 여행자의 모든 권리 및 의무도 그 지위를 양도 받는 자에게 승계됩니다.

제12조(‘당사’의 책임)
여행자는 여행에 하자가 있는 경우에 ‘당사’에게 하자의 시정 또는 대금의 감액을 청구할 수 있습니다. 다만, 그 시정에 지나치게 많은 비용이 들거나 그 밖에 시정을 합리적으로 기대할 수 없는 경우에는 시정을 청구할 수 없습니다.
여행자는 시정 청구, 감액 청구를 갈음하여 손해배상을 청구하거나 시정 청구, 감액 청구와 함께 손해배상을 청구 할 수 있습니다.
제1항 및 제2항의 권리는 여행기간 중에도 행사할 수 있으며, 여행종료일부터 6개월 내에 행사하여야 합니다.
‘당사’는 여행 출발시부터 도착시까지 ‘당사’ 본인 또는 그 고용인, 현지여행사 또는 그 고용인 등(이하 ‘사용인’이라 함)이 제3조제1항에서 규정한 ‘당사’ 임무와 관련하여 여행자에게 고의 또는 과실로 손해를 가한 경우 책임을 집니다.
‘당사’는 항공기, 기차, 선박 등 교통기관의 연발착 또는 교통체증 등으로 인하여 여행자가 입은 손해를 배상하여야 합니다. 다만, ‘당사’가 고의 또는 과실이 없음을 입증한 때에는 그러하지 아니합니다.
‘당사’는 자기나 그 사용인이 여행자의 수하물 수령, 인도, 보관 등에 관하여 주의를 해태하지 아니하였음을 증명하지 아니 하는 한 여행자의 수하물 멸실, 훼손 또는 연착으로 인하여 발생한 손해를 배상하여야 합니다.

제13조(여행출발 전 계약해제)
‘당사’ 또는 여행자는 여행출발전 이 여행계약을 해제할 수 있습니다. 이 경우 발생하는 손해액은 '소비자분쟁해결기준'(공정거래위원회 고시)에 따라 배상합니다.
‘당사’ 또는 여행자는 여행출발 전에 다음 각 호의 1에 해당하는 사유가 있는 경우 상대방에게 제1항의 손해배상액을 지급하지 아니하고 이 여행계약을 해제할 수 있습니다.
‘당사’가 해제할 수 있는 경우
제10조 제1항 제1호 및 제2호 사유의 경우
여행자가 다른 여행자에게 폐를 끼치거나 여행의 원활한 실시에 현저한 지장이 있다고 인정될 때
질병 등 여행자의 신체에 이상이 발생하여 여행에의 참가가 불가능한 경우
여행자가 계약서에 기재된 기일까지 여행요금을 지급하지 아니하는 경우
여행자가 해제할 수 있는 경우
제10조 제1항 제1호 및 제2호 사유의 경우
‘당사’가 제18조에 따른 공제 또는 보증보험에 가입하지 아니 하였거나 영업보증금을 예치하지 않은 경우
여행자의 3촌이내 친족이 사망한 경우
가.친족을 확인할 수 있는 서류(가족관계증명서 등)
나.진단서 또는 사체검안서(사망진단서)
다.그 밖에 필요한 자료
질병 등 여행자의 신체에 이상이 발생하여 여행에의 참가가 불가능한 경우
가.진단서
나.그밖에 필요한 자료
배우자 또는 직계존비속이 신체이상으로 3일 이상 병원(의원)에 입원하여 여행 출발시까지 퇴원이 곤란한 경우 그 배우자 또는 보호자 1인
가.친족을 확인할 수 있는 서류(가족관계증명서 등)
나.진단서
다.그밖에 필요한 자료
‘당사’의 귀책사유로 계약서에 기재된 여행일정대로의 여행실시가 불가능해진 경우

제14조(최저 행사인원 미 충족시 계약해제)
‘당사’는 최저행사인원이 충족되지 아니하여 여행계약을 해제하는 경우 당일여행의 경우 여행출발 24시간 이전까지, 1박2일 이상인 경우에는 여행출발 48시간 이전까지 여행자에게 통지하여야 합니다.
‘당사’가 여행참가자 수의 미달로 전항의 기일내 통지를 하지 아니하고 계약을 해제하는 경우 이미 지급받은 계약금 환급 외에 계약금 100% 상당액을 여행자에게 배상하여야 합니다.

제15조(여행출발 후 계약해지)
‘당사’ 또는 여행자는 여행출발 후 부득이한 사유가 있는 경우 각 당사자는 여행계약을 해지할 수 있습니다. 다만, 그 사유가 당사자 한쪽의 과실로 인하여 생긴 경우에는 상대방에게 손해를 배상하여야 합니다.
제1항에 따라 여행계약이 해지된 경우 귀환운송 의무가 있는 ‘당사’는 여행자를 귀환운송 할 의무가 있습니다.
제1항의 계약해지로 인하여 발생하는 추가비용은 그 해지사유가 어느 당사자의 사정에 속하는 경우에는 그 당사자가 부담하고, 양 당사자 누구의 사정에도 속하지 아니하는 경우에는 각 당사자가 추가비용의 50%씩을 부담합니다.
여행자는 여행에 중대한 하자가 있는 경우에 그 시정이 이루어지지 아니하거나 계약의 내용에 따른 이행을 기대할 수 없는 경우에는 계약을 해지할 수 있습니다.
제4항에 따라 계약이 해지된 경우 ‘당사’는 대금청구권을 상실합니다. 다만, 여행자가 실행된 여행으로 이익을 얻은 경우에는 그 이익을 ‘당사’에게 상환하여야 합니다.
제4항에 따라 계약이 해지된 경우 ‘당사’는 계약의 해지로 인하여 필요하게된 조치를 할 의무를 지며, 계약상 귀환운송 의무가 있으면 여행자를 귀환운송하여야 합니다. 이 경우 귀환운송비용은 원칙적으로 ‘당사’가 부담하여야 하나, 상당한 이유가 있는 때에는 ‘당사’는 여행자에게 그 비용의 일부를 청구할 수 있습니다.

제16조(여행의 시작과 종료)
여행의 시작은 출발하는 시점부터 시작하며 여행일정이 종료하여 최종목적지에 도착함과 동시에 종료합니다. 다만, 계약 및 일정을 변경할 때에는 예외로 합니다.


제17조(설명의무)
‘당사’는 이 계약서에 정하여져 있는 중요한 내용 및 그 변경사항을 여행자가 이해할 수 있도록 설명하여야 합니다.


제18조(보험가입 등)
‘당사’는 여행과 관련하여 여행자에게 손해가 발생 한 경우 여행자에게 보험금을 지급하기 위한 보험 또는 공제에 가입하거나 영업 보증금을 예치하여야 합니다.

제19조(기타사항)
이 계약에 명시되지 아니한 사항 또는 이 계약의 해석에 관하여 다툼이 있는 경우에는 ‘당사’와 여행자가 합의하여 결정하되, 합의가 이루어지지 아니한 경우에는 관계법령 및 일반관례에 따릅니다.
특수지역에의 여행으로서 정당한 사유가 있는 경우에는 이 표준약관의 내용과 다르게 정할 수 있습니다.`;

export default {
  userLicense,
  personalInfoProtect,
  personalInfoUse,
};
