/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactChild } from 'react';
import argsToPX from '../../Middle/argsToPX';
import styles from '../Css/CustomTable.module.css';

function CustomTable(props: {
  children: ReactChild | ReactChild[],
  haveVerticalLine?: boolean,
  eachHeight?: number|string,
}) {
  const { children, haveVerticalLine, eachHeight } = props;
  const tmpStyle = { '--eachHeight': argsToPX(eachHeight) || '60px' } as CSSProperties;
  return (
    <table style={tmpStyle} className={`${styles.customTable} ${haveVerticalLine && styles.verticalLine}`}>
      {children}
    </table>
  );
}

export default CustomTable;
