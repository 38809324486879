import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function PaymentCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const { idx } = useParams();
  useEffect(() => {
    (async () => {
      const res = await axios.get<{result: {status: boolean, message: string}}>(`/api/payment/validation/mobile${location.search}`);
      alert(res.data.result.message);
      navigate(`/mypage/reservation/${idx}`);
    })();
  }, []);
  return (
    <div>잠시만 기다려주세요</div>
  );
}

export default PaymentCallback;
