/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../Components/BlankMaker';
import styles from '../Css/Main.module.css';
import reservation from '../../meta/mainpage/reservation.png';
import review from '../../meta/mainpage/review.png';
import qna from '../../meta/mainpage/qna.png';
import faq from '../../meta/mainpage/faq.png';
import sponsor from '../../meta/mainpage/sponsor.png';

const emptyCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <g><rect fill="none" height="24" width="24" /></g>
    <g><path d="M12,2C6.47,2,2,6.47,2,12c0,5.53,4.47,10,10,10s10-4.47,10-10C22,6.47,17.53,2,12,2z M12,20c-4.42,0-8-3.58-8-8 c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z" /></g>
  </svg>
);

const filledCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" />
  </svg>
);

const rightArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

function TopBanner() {
  const { data } = useSWR('/api/landing/banner?state=true&type=web', async (url) => {
    const res = await axios.get<{result: {
      'idx': number,
      'title': string,
      'sequence_number': number,
      'link': string,
      'image': string,
      'is_used': number
    }[]}>(url);
    return res.data.result;
  });
  const [Now, setNow] = useState(0);
  const changeNum = (diff: number) => {
    if (!data) return;
    let newNum = Now + diff;
    if (newNum < 0) newNum += data.length;
    if (newNum >= data.length) newNum %= data.length;
    setNow(newNum);
  };
  useEffect(() => {
    changeNum(0);
  }, [data]);
  if (!data) return <div />;
  return (
    <div className={styles.topBannerDiv}>
      <div className={styles.topBannerInner} style={{ transform: `translateX(${Now * -100}vw)` }}>
        {
          data.map((d) => (
            <a href={d.link}><img src={d.image} alt={d.title} /></a>
          ))
        }
      </div>
      <button type="button" onClick={() => changeNum(1)} className={`${styles.topBannerNext} ${styles.topBannerNextNext}`}>
        {rightArrow}
      </button>
      <button type="button" onClick={() => changeNum(-1)} className={`${styles.topBannerNext} ${styles.topBannerNextBefore}`}>
        {rightArrow}
      </button>
      <div className={styles.topBannerCircles}>
        {
          data.map((d, i) => (
            <button type="button" className={`${styles.topBannerCircle} ${i === Now && styles.selected}`} onClick={() => setNow(i)}>
              {
                i === Now ? filledCircle : emptyCircle
              }
            </button>
          ))
        }
      </div>
    </div>
  );
}

function RecommendProduct() {
  const navigate = useNavigate();
  const { data } = useSWR('/api/landing/recommended', async (url) => {
    const res = await axios.get<{result: {
      'idx': number,
      'good_idx': number,
      'title': string,
      'price': number|null,
      'thumb_image': string,
      'first_departure_date': string | null | undefined
    }[]}>(url);
    return res.data.result;
  });
  const [Now, setNow] = useState(0);
  const changeNum = (diff: number) => {
    if (!data) return;
    let newNum = Now + diff;
    if (newNum < 0) newNum += data.length;
    if (newNum >= data.length) newNum %= data.length;
    setNow(newNum);
  };
  useEffect(() => {
    changeNum(0);
  }, [data]);
  if (!data) return <div />;
  return (
    <div className={styles.rpDiv}>
      <button onClick={() => changeNum(-1)} type="button" className={styles.rpArrow} style={{ transform: 'scaleX(-1)' }}>
        {rightArrow}
      </button>
      <div className={styles.rpInner}>
        <div className={styles.rpTopDiv}>
          <div className={styles.rpText}>오늘의 추천상품</div>
          <div className={styles.rpCircles}>
            {
              data.map((d, i) => (
                <button type="button" className={`${styles.rpCircle} ${i === Now && styles.selected}`} onClick={() => setNow(i)}>
                  {
                    i === Now ? filledCircle : emptyCircle
                  }
                </button>
              ))
            }
          </div>
        </div>
        <div className={styles.rpWrapper}>
          <div className={styles.rpContents} style={{ transform: `translateX(${Now * -510}px)` }}>
            {
              [...data, data[0]].map((d) => (
                <button
                  type="button"
                  className={styles.rpEachDiv}
                  onClick={() => {
                    if (d.first_departure_date) navigate(`/product/${d.good_idx}/${d.first_departure_date}`);
                    else alert('출발 가능한 일정이 없습니다.');
                  }}
                >
                  <img src={d.thumb_image} alt={d.title} />
                  <div className={styles.rpEachText}>
                    <div className={styles.rpEachTitle}>{d.title}</div>
                    <div className={styles.rpEachPrice}>{d.price ? `${d.price.toLocaleString()}원` : '요금문의'}</div>
                  </div>
                </button>
              ))
            }
          </div>
        </div>
      </div>
      <button onClick={() => changeNum(1)} type="button" className={styles.rpArrow}>
        {rightArrow}
      </button>
    </div>
  );
}

function Main() {
  const navigate = useNavigate();
  const { data } = useSWR('/api/board/notice?page=1&limit=10', async (url) => {
    const res = await axios.get<{result: {
      count: number
      notice: {
        idx: number
        title: string
        views: number
        created_at: string
      }[]
    }}>(url);
    return res.data.result.notice;
  });
  const { data: bottomData } = useSWR('/api/landing/bottom-banner?state&type=web', async (url) => {
    const res = await axios.get<{
      result: {
        'idx': number,
        'title': string,
        'sequence_number': number,
        'link': string,
        'image': string,
        'is_used': number
      }[]
    }>(url);
    return res.data.result;
  });
  return (
    <div className={styles.mainDiv}>
      <TopBanner />
      <BlankMaker height={100} />
      <RecommendProduct />
      <BlankMaker height={100} />
      <div className={styles.mainBottomDiv}>
        <div className={styles.mainBottomLeft}>
          <div className={styles.mainBottomLeftTitle}>이용가이드</div>
          <div className={styles.mainBottomLeftButtons}>
            <button type="button" onClick={() => navigate('/mypage/reservation')}>
              <img src={reservation} alt="" />
              <div>예약조회</div>
            </button>
            <button type="button" onClick={() => navigate('/review')}>
              <img src={review} alt="" />
              <div>여행후기</div>
            </button>
            <button type="button" onClick={() => navigate('/qna')}>
              <img src={qna} alt="" />
              <div>질문과 답변</div>
            </button>
            <button type="button" onClick={() => navigate('/faq')}>
              <img src={faq} alt="" />
              <div>자주 묻는 질문</div>
            </button>
            <button type="button" onClick={() => { location.href = 'http://www.alpstourbiz.com'; }}>
              <img src={sponsor} alt="" />
              <div>제휴문의</div>
            </button>
          </div>
        </div>
        <div className={styles.mainBottomRight}>
          <div className={styles.mainBottomRightTop}>
            <div className={styles.mainBottomRightTopLeft}>공지사항</div>
            <Link to="/notice" className={styles.mainBottomRightTopRight}>+ 더보기</Link>
          </div>
          <div className={styles.noticeListDiv}>
            {
              data?.filter((d) => (
                d.idx !== 18 && d.idx !== 19 && d.idx !== 20 && d.idx !== 21
              )).map((d) => (
                <Link to={`/notice/${d.idx}`}>{d.title}</Link>
              ))
            }
          </div>
        </div>
      </div>
      <BlankMaker height={100} />
      {
        (bottomData && bottomData.length > 0)
        && (
          <a className={styles.bottomBanner} href={bottomData[0].link}>
            <img src={bottomData[0].image} alt={bottomData[0].title} />
          </a>
        )
      }
    </div>
  );
}

export default Main;
