import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../Css/WithNav.module.css';

function WithNav(props: {
  title: string
  navList: { title: string, url: string }[]
  children: React.ReactChild | React.ReactChild[]
}) {
  const { title, navList, children } = props;
  const location = useLocation();
  return (
    <div className={styles.withNav}>
      <div className={styles.navDiv}>
        <div className={styles.title}>{title}</div>
        {
          navList.map((d) => (
            <div className={styles.nav}>
              <Link to={d.url} className={`${location.pathname.startsWith(d.url) && styles.selected}`}>{d.title}</Link>
            </div>
          ))
        }
      </div>
      <div className={styles.contentDiv}>
        {
          children
        }
      </div>
    </div>
  );
}

export default WithNav;
