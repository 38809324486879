import React from 'react';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../../Mobile/Components/LoadMoreButton';
import { productI } from '../../Mobile/Page/Category';
import EachProduct from '../Components/EachProduct';
import WithMypageNav from '../Components/WithMypageNav';

const PAGE_SIZE = 10;

function Like() {
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<productI>(
    (index) => `/api/good/like?page=${index + 1}&limit=${PAGE_SIZE}`,
    'cart',
    PAGE_SIZE,
  );
  return (
    <WithMypageNav>
      <>
        <div style={{
          width: '100%', fontSize: '26px', fontWeight: 'bold', textAlign: 'left', borderBottom: '2px solid #8F969F', paddingBottom: '6px',
        }}
        >
          관심상품
        </div>
        {
          issues.map((d) => <EachProduct info={d} />)
        }
        <LoadMoreButton {...{
          isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
        }}
        />
      </>
    </WithMypageNav>
  );
}

export default Like;
