import React, { useState, useEffect } from 'react';
import { ScrollingProvider } from 'react-scroll-section';
import Mobile from './Mobile';
import Desktop from './Desktop';

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1000;
  const forcedMobile = false;

  return (
    forcedMobile || isMobile
      ? <Mobile />
      : <ScrollingProvider><Desktop /></ScrollingProvider>
  );
}

export default App;
