import React from 'react';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../Components/LoadMoreButton';
import TopBar from '../Components/TopBar';
import styles from '../Css/FAQ.module.css';

const PAGE_SIZE = 10;

function FAQ() {
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<{
    idx: number, question: string, answer: string
  }>(
    (index) => `/api/board/faq?page=${index + 1}&limit=${PAGE_SIZE}`,
    'faq',
    PAGE_SIZE,
  );
  return (
    <div className={styles.faqDiv}>
      <TopBar text="자주 묻는 질문" noUnderLine />
      <div className={styles.grayInfo}>제목</div>
      {issues.map((data) => (
        <details>
          <summary>{data.question}</summary>
          <div dangerouslySetInnerHTML={{ __html: data.answer }} className="ck-content" />
        </details>
      ))}
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default FAQ;
