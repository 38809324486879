import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import BlankMaker from '../Components/BlankMaker';
import CustomTable from '../Components/CustomTable';
import Title from '../Components/Title';
import WithMypageNav from '../Components/WithMypageNav';

function MyMileage() {
  useLoginCheck();
  const { getLoginConfig } = useLogin();
  const { data } = useSWR('/api/user/mileage', async (url) => {
    const res = await axios.get<{
      result: { total: number, mileage: { amount: number, content: string, created_at: string}[]}
    }>(url, getLoginConfig());
    return res.data.result;
  });
  return (
    <WithMypageNav>
      <>
        <Title>적립금 내역</Title>
        <div style={{ fontSize: '24px' }}>
          현재 적립금은
          {' '}
          <b>{`${(data?.total || 0).toLocaleString()}원`}</b>
          입니다.
        </div>
        <BlankMaker height={20} />
        <CustomTable haveVerticalLine>
          <thead>
            <tr>
              <td>일시</td>
              <td>내용</td>
              <td>금액</td>
            </tr>
          </thead>
          <tbody>
            {
              data?.mileage.map((d) => (
                <tr>
                  <td>{d.created_at}</td>
                  <td>{d.content}</td>
                  <td>{`${d.amount > 0 ? '+' : ''}${d.amount.toLocaleString()}원`}</td>
                </tr>
              ))
            }
            <tr>
              <td colSpan={2}><b>현재 마일리지</b></td>
              <td><b>{`${(data?.total || 0).toLocaleString()}원`}</b></td>
            </tr>
          </tbody>
        </CustomTable>
      </>
    </WithMypageNav>
  );
}

export default MyMileage;
