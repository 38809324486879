import axios from 'axios';
import useSWR from 'swr';

export function useCategory() {
  const { data, isValidating } = useSWR('category', async () => {
    const firstRes = await axios.get<{
      result: {
        idx: number,
        title: string,
        state: number,
        category: string,
        count: number,
        icon_image: string
      }[]
    }>('/api/category/first?count=true&state=true');
    const firstData = firstRes.data;
    const firstResult = firstData.result;
    const firstIdxList = firstResult.map((fCate) => fCate.idx);
    const categoryInfo:{
      [firstIdx:number]: {
        idx: number,
        title: string,
        state: number,
        category: string,
        count: number,
        icon_image: string,
        secondCategory: {
          [secondIdx:number]: {
            idx: number,
            title: string,
            state: number,
            first_category: number,
            count: number
          }
        },
        secondCategoryIdxList: number[]
      }
      firstCategoryIdxList: number[]
    } = { firstCategoryIdxList: firstIdxList };
    async function firstToSecond(firstItem: {
      idx: number,
      title: string,
      state: number,
      category: string,
      count: number,
      icon_image: string
    }) {
      const { idx: firstIdx } = firstItem;
      const secondRes = await axios.get<{
        result: {
          idx: number,
          title: string,
          state: number,
          first_category: number,
          count: number
        }[]
      }>(`/api/category/second/${firstIdx}?count=true&state=true`);
      const secondData = secondRes.data;
      const secondResult = secondData.result;
      const secondCategoryInfo: {
        [secondIdx:number]: {
          idx: number,
          title: string,
          state: number,
          first_category: number,
          count: number
        }
      } = {};
      const secondIdxList = secondResult.map((item) => {
        const { idx: secondIdx } = item;
        secondCategoryInfo[secondIdx] = item;
        return secondIdx;
      });
      categoryInfo[firstIdx] = {
        ...firstItem,
        secondCategory: secondCategoryInfo,
        secondCategoryIdxList: secondIdxList,
      };
    }
    await Promise.all(firstResult.map((firstItem:any) => firstToSecond(firstItem)));
    return categoryInfo;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return data || { firstCategoryIdxList: [] };
}

export default useCategory;
