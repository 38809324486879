import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from './Desktop/Components/Footer';
import WithCustomerNav from './Desktop/Components/WithCustomerNav';
import WithMypageNav from './Desktop/Components/WithMypageNav';
import Category from './Desktop/Page/Category';
import Header from './Desktop/Page/Header';
import Like from './Desktop/Page/Like';
import Main from './Desktop/Page/Main';
import MyMileage from './Desktop/Page/MyMileage';
import MyReservation from './Desktop/Page/MyReservation';
import MyResInfo from './Desktop/Page/MyResInfo';
import Payment from './Desktop/Page/Payment';
import Product from './Desktop/Page/Product';
import Search from './Desktop/Page/Search';
import PageChangeEvent from './Middle/PageChangeEvent';
import WidthBlocker from './Mobile/Components/WidthBlocker';
import AccountDelete from './Mobile/Page/AccountDelete';
import EachNotice from './Mobile/Page/EachNotice';
import EachQNA from './Mobile/Page/EachQNA';
import EachReview from './Mobile/Page/EachReview';
import FAQ from './Mobile/Page/FAQ';
import Login from './Mobile/Page/Login';
import LoginCallback from './Mobile/Page/LoginCallback';
import Logout from './Mobile/Page/Logout';
import MyInfoChange from './Mobile/Page/MyInfoChange';
import MyQNA from './Mobile/Page/MyQNA';
import MyReview from './Mobile/Page/MyReview';
import Notice from './Mobile/Page/Notice';
import QNA from './Mobile/Page/QNA';
import QNANew from './Mobile/Page/QNANew';
import Reserve from './Mobile/Page/Reserve';
import Review from './Mobile/Page/Review';
import ReviewNew from './Mobile/Page/ReviewNew';
import Signup from './Mobile/Page/Signup';

function Desktop() {
  return (
    <div style={{
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <PageChangeEvent />
      <div style={{
        width: '1200px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      >
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/category/:firstIdx/:secondIdx" element={<Category isFixed={false} />} />
          <Route path="/fixed" element={<Category isFixed />} />
          <Route path="/login" element={<WidthBlocker><Login /></WidthBlocker>} />
          <Route path="/logincallback/kakao" element={<LoginCallback type="kakao" />} />
          <Route path="/logincallback/naver" element={<LoginCallback type="naver" />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/accountdelete" element={<AccountDelete />} />
          <Route path="/product/:idx/:date" element={<Product />} />
          <Route path="/reserve/:idx/:date" element={<WidthBlocker width={1000}><Reserve /></WidthBlocker>} />
          <Route path="/mypage/like" element={<Like />} />
          <Route path="/mypage/infochange" element={<WithMypageNav><WidthBlocker width={600}><MyInfoChange /></WidthBlocker></WithMypageNav>} />
          <Route path="/mypage/mileage" element={<MyMileage />} />
          <Route path="/mypage/reservation" element={<MyReservation />} />
          <Route path="/mypage/reservation/:idx" element={<MyResInfo />} />
          <Route path="/mypage/reservation/:idx/payment" element={<Payment />} />
          <Route path="/mypage/qna" element={<WithMypageNav><WidthBlocker width={600}><MyQNA /></WidthBlocker></WithMypageNav>} />
          <Route path="/mypage/review" element={<WithMypageNav><WidthBlocker width={600}><MyReview /></WidthBlocker></WithMypageNav>} />
          <Route path="/search/:keyword" element={<Search />} />
          <Route path="/signup" element={<WidthBlocker width={600}><Signup type="default" /></WidthBlocker>} />
          <Route path="/signup/kakao/:accessToken" element={<WidthBlocker><Signup type="kakao" /></WidthBlocker>} />
          <Route path="/signup/naver/:accessToken" element={<WidthBlocker><Signup type="naver" /></WidthBlocker>} />
          <Route path="/notice" element={<WithCustomerNav><WidthBlocker width={600}><Notice /></WidthBlocker></WithCustomerNav>} />
          <Route path="/notice/:idx" element={<WithCustomerNav><WidthBlocker width={800}><EachNotice /></WidthBlocker></WithCustomerNav>} />
          <Route path="/faq" element={<WithCustomerNav><WidthBlocker width={800}><FAQ /></WidthBlocker></WithCustomerNav>} />
          <Route path="/qna" element={<WithCustomerNav><WidthBlocker width={600}><QNA /></WidthBlocker></WithCustomerNav>} />
          <Route path="/qna/:idx" element={<WithCustomerNav><WidthBlocker width={800}><EachQNA /></WidthBlocker></WithCustomerNav>} />
          <Route path="/qna/new" element={<WithCustomerNav><WidthBlocker width={600}><QNANew /></WidthBlocker></WithCustomerNav>} />
          <Route path="/review" element={<WithCustomerNav><WidthBlocker width={600}><Review /></WidthBlocker></WithCustomerNav>} />
          <Route path="/review/:idx" element={<WithCustomerNav><WidthBlocker width={800}><EachReview /></WidthBlocker></WithCustomerNav>} />
          <Route path="/review/new" element={<WithCustomerNav><WidthBlocker width={600}><ReviewNew /></WidthBlocker></WithCustomerNav>} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default Desktop;
