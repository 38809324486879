/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import axios from 'axios';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { useCategory } from '../../Middle/useCategory';
import styles from '../Css/Main.module.css';
import fixedImage from '../../meta/checkFixed.jpeg';
import 'react-slideshow-image/dist/styles.css';

interface EachSlideInfo {
  idx: number,
  title: string,
  sequence_number: number,
  link: string,
  image: string,
  is_used: 0|1
}

function ImageSlide() {
  const [NowSeeNum, setNowSeeNum] = useState(0);
  const { data, isValidating } = useSWR<EachSlideInfo[]>('/api/landing/banner?state=true&type=mobile', async (url) => {
    const res = await axios.get<{result: EachSlideInfo[]}>(url);
    const resData = await res.data;
    return resData.result;
  });
  return (
    <div className={styles.imageSlideDiv}>
      {
        data
        && (
        <Slide
          easing="ease"
          transitionDuration="400"
          prevArrow={<div />}
          nextArrow={<div />}
          onChange={(prev: number, next: number) => {
            setNowSeeNum(next);
          }}
        >
          {data?.map((d) => <a href={d.link}><img className={styles.slideEachImage} src={d.image} alt="asdf" /></a>)}
        </Slide>
        )
      }

      <div className={styles.indicatorDiv}>
        <div className={styles.indicatorInner}>
          {data?.map((d, idx) => <div className={`${styles.eachIndicator} ${idx === NowSeeNum && styles.indiActive}`} />)}
        </div>
      </div>
    </div>
  );
}

function BottomBanner() {
  const { data } = useSWR('/api/landing/bottom-banner?state=true&type=mobile', async (url) => {
    const res = await axios.get<{result:{title:string, link:string, image:string}[]}>(url);
    return res.data.result;
  });
  return data && data.length > 0
    ? (
      <a href={data[0].link}>
        <img src={data[0].image} alt={data[0].title} className={styles.bottomBanner} />
      </a>
    )
    : <div />;
}

function Main() {
  const category = useCategory();
  return (
    <div className={styles.mainDiv}>
      <ImageSlide />
      <div className={styles.mainTextDiv}>
        <div>어떤 여행을</div>
        <div>떠나고 싶으신가요?</div>
      </div>
      <div className={styles.cateLinkDiv}>
        {
          category.firstCategoryIdxList.map((cateIdx) => {
            const { title, icon_image } = category[cateIdx];
            return (
              <Link to={`/category/${cateIdx}/0`}>
                <div className={styles.cateEachDiv}>
                  <img src={icon_image} alt={`${title} 아이콘`} />
                  <div>{title}</div>
                </div>
              </Link>
            );
          })
        }
      </div>
      <Link to="/fixed">
        <img src={fixedImage} alt="확정 여행 목록" className={styles.bottomBanner} />
      </Link>
      <BottomBanner />
    </div>
  );
}

export default Main;
