import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import TopBar from '../Components/TopBar';
import styles from '../Css/Mypage.module.css';

function Mypage() {
  const { loginData, loginIsValidating } = useLogin();
  const navigate = useNavigate();
  useLoginCheck();
  if (!(loginData?.login)) return <div />;
  return (
    <div className={styles.mypageDiv}>
      <TopBar text="마이페이지" noUnderLine />
      <div className={styles.mypageLinkDiv}>
        <div className={styles.mypageGrayTitle}>프로필</div>
        <div className={styles.mypageProfileDiv}>
          <div className={styles.mypageProfileName}>{`${loginData.info.name} 님`}</div>
          <button onClick={() => navigate('./infochange')} type="button" className={styles.mypageProfileBtn}>회원정보수정</button>
        </div>
        <div className={styles.mypageLine} />
        <div className={styles.mypageGrayTitle}>예약정보</div>
        <div className={styles.mypageLinkList}>
          <Link to="./reservation">예약내역</Link>
          <Link to="./like">찜 목록</Link>
        </div>
        <div className={styles.mypageLine} />
        <div className={styles.mypageGrayTitle}>내가 작성한 글</div>
        <div className={styles.mypageLinkList}>
          <Link to="./qna">내 질문</Link>
          <Link to="./review">내 여행후기</Link>
        </div>
        <div className={styles.mypageLine} />
        <div className={styles.mypageGrayTitle}>고객센터</div>
        <div className={styles.mypageLinkList}>
          <Link to="/notice">공지사항</Link>
          <Link to="/qna">질문과 답변</Link>
          <Link to="/faq">자주 묻는 질문</Link>
          <Link to="/review">후기 작성/조회</Link>
        </div>
      </div>
      <div className={styles.mypageBottomLinkDiv}>
        <Link to="/logout">로그아웃</Link>
        <div />
        <Link to="/accountdelete">회원탈퇴</Link>
      </div>
    </div>
  );
}

export default Mypage;
