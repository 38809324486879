/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useCategory from '../../Middle/useCategory';
import useMenuState from '../../Middle/useMenuState';
import styles from '../Css/TopBar.module.css';

function ModalFirstCategory(props: {
  firstIdx: number,
  selected: [number, number],
  changeSelected: (firstIdx:number, secondIdx:number)=>void
}) {
  const { firstIdx, selected, changeSelected } = props;
  const [Open, setOpen] = useState(false);
  const category = useCategory();
  const { closeMenuTrigger } = useMenuState();
  useEffect(() => {
    setOpen(false);
  }, [closeMenuTrigger]);
  const { title: firstTitle, secondCategory, secondCategoryIdxList } = category[firstIdx];
  const changeSelectedClick = (second: number) => changeSelected(firstIdx, second);
  const isSelected = (second: number) => selected[0] === firstIdx && selected[1] === second;
  return (
    <div className={`${styles.modalFirstWrapper} ${Open && styles.modalFirstOpen}`}>
      <button type="button" className={styles.modalFirstCateBtn} onClick={() => setOpen((val) => !val)}>
        <div className={styles.modalFirstTitleDiv}>{firstTitle}</div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
        </svg>
      </button>
      <div className={styles.modalSecondDiv}>
        <button type="button" className={`${isSelected(0) && styles.modalSecondSelected}`} onClick={() => changeSelectedClick(0)}><div>전체</div></button>
        {
          secondCategoryIdxList.map((idx) => <button className={`${isSelected(idx) && styles.modalSecondSelected}`} onClick={() => changeSelectedClick(idx)} type="button"><div>{secondCategory[idx].title}</div></button>)
        }
      </div>
    </div>
  );
}

function TopBar(props: {
  firstIdx: number,
  secondIdx: number,
  noUnderLine?: boolean
} | { text: string }) {
  const navigate = useNavigate();
  const category = useCategory();
  const modalContentsDiv = useRef<HTMLDivElement>(null);
  const {
    menuState, setShowBlur, setMenuState, closeAllMenu, closeMenuTrigger,
  } = useMenuState();
  const [Show, setShow] = useState(false);
  const topbarSimple = 'text' in props;
  const [ModalSelected, setModalSelected] = useState<[number, number]>([-1, -1]);
  useEffect(() => {
    setShow(false);
  }, [topbarSimple, (!topbarSimple) && props.firstIdx]);
  useEffect(() => {
    setModalSelected([-1, -1]);
    modalContentsDiv.current?.scrollTo(0, 0);
  }, [closeMenuTrigger]);
  const isModalSelectedChanged = ModalSelected[0] !== -1 && ModalSelected[1] !== -1;
  let subCateList: any[] = [];
  let title = '';
  let rowNum = 0;
  if (!topbarSimple) {
    const { firstIdx, secondIdx } = props;
    const firstCate = category[firstIdx];
    title = firstCate.title;
    const { secondCategory, secondCategoryIdxList } = firstCate;
    let tdList: any[] = secondCategoryIdxList.map((idx) => {
      const info = secondCategory[idx];
      return <td className={`${idx === secondIdx && styles.selected}`}><Link to={`/category/${firstIdx}/${idx}`}><div>{info.title}</div></Link></td>;
    });
    if (secondCategoryIdxList.length % 2 === 0) {
      subCateList.push(
        <tr>
          <td className={`${secondIdx === 0 && styles.selected}`} colSpan={2}><Link to={`/category/${firstIdx}/${0}`}><div>전체보기</div></Link></td>
        </tr>,
      );
      rowNum = secondCategoryIdxList.length / 2 + 1;
    } else {
      rowNum = (secondCategoryIdxList.length + 1) / 2;
      tdList = [<td className={`${secondIdx === 0 && styles.selected}`}><Link to={`/category/${firstIdx}/${0}`}><div>전체보기</div></Link></td>, ...tdList];
    }
    const bounded = tdList.map((val, idx) => {
      if (idx % 2 === 0) return false;
      return (
        <tr>
          {tdList[idx - 1]}
          {tdList[idx]}
        </tr>
      );
    });
    subCateList = [...subCateList, ...bounded];
  } else {
    const { text } = props;
    title = text;
  }
  return (
    <>
      <div className={`${styles.topbarWrapper} ${topbarSimple && styles.simple} ${Show && styles.topbarShow} ${('noUnderLine' in props && props.noUnderLine) && styles.noUnderLine}`}>
        <div className={styles.topbarDiv}>
          {
            topbarSimple ? (
              <div className={styles.titleDiv}>
                <div className={styles.title}>{title}</div>
              </div>
            ) : (
              <button type="button" className={styles.titleDiv} onClick={() => { setShow((val) => !val); }}>
                <div className={styles.titleBlankDiv} />
                <div className={styles.title}>{title}</div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                </svg>
              </button>
            )
          }
          <div className={styles.beforeBtnDiv}>
            <button type="button" className={styles.beforeBtn} onClick={() => { navigate(-1); }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
              </svg>
            </button>
          </div>
          {
            topbarSimple || (
            <div className={styles.changeBtnDiv}>
              <button
                type="button"
                onClick={() => {
                  setShowBlur(true);
                  setMenuState('product');
                }}
                className={styles.changeBtn}
              >
                여행지 변경

              </button>
            </div>
            )
          }
        </div>
      </div>
      {
        topbarSimple || (
        <div className={`${styles.secondCateDiv} ${Show && styles.show}`} style={{ maxHeight: `${(rowNum * 50) + 43}px` }}>
          <div className={styles.secondCateTableDiv}>
            <table>
              {subCateList}
            </table>
          </div>
        </div>
        )
      }
      {
        topbarSimple || (
          <div className={`${styles.modalDiv} ${menuState === 'product' && styles.showModal}`}>
            <div className={styles.modalTopDiv}>
              <div>여행지 변경</div>
              <button type="button" onClick={() => closeAllMenu()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                </svg>

              </button>

            </div>
            <div className={styles.modalContentsDiv} ref={modalContentsDiv}>
              {
              category.firstCategoryIdxList.map((idxF, idx) => (
                <>
                  <ModalFirstCategory
                    firstIdx={idxF}
                    selected={ModalSelected}
                    changeSelected={(a, b) => setModalSelected([a, b])}
                  />
                  {
                    idx < category.firstCategoryIdxList.length - 1
                      && <div className={styles.cateLine} />
                  }
                </>
              ))
              }
            </div>
            <Link
              className={`${styles.modalBtn} ${isModalSelectedChanged && styles.modalBtnSelected}`}
              to={
                isModalSelectedChanged ? `/category/${ModalSelected[0]}/${ModalSelected[1]}` : '#'
              }
            >
              <div>
                {isModalSelectedChanged ? `${category[ModalSelected[0]].title}-${
                  ModalSelected[1] === 0 ? '전체' : category[ModalSelected[0]].secondCategory[ModalSelected[1]].title
                } 선택` : '변경하기'}
              </div>

            </Link>
          </div>
        )
      }

    </>

  );
}

export default TopBar;
