/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLogin from '../../Middle/useLogin';

const LoginCallback = function (props: {type: 'kakao' | 'naver'}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loginMutate } = useLogin();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const getKakaoToken = async () => {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('client_id', '1b52996be5fdcc69f433d6121dfa6c9f');
    params.append('redirect_uri', `${location.origin}/logincallback/${props.type}`);
    params.append('code', `${code}`);
    const firstRes = await axios.post<{access_token: string}>('https://kauth.kakao.com/oauth/token', params);
    const { data } = firstRes;
    const { access_token } = data;
    return access_token;
  };
  const getNaverToken = async () => {
    const hashParams = new URLSearchParams(location.hash);
    return hashParams.get('#access_token');
  };
  useEffect(() => {
    (async () => {
      try {
        if (error) throw Error;
        const access_token = props.type === 'kakao' ? await getKakaoToken() : await getNaverToken();
        const secondRes = await axios.post<{result: {is_user: false} | {is_user: true, userId: string, token: {accessToken: string}}}>(`/api/user/sign-in/${props.type}`, { token: access_token });
        if (secondRes.data.result.is_user) {
          localStorage.setItem('accessToken', secondRes.data.result.token.accessToken);
          loginMutate();
          navigate('/');
        } else {
          navigate(`/signup/${props.type}/${access_token}`);
        }
      } catch {
        navigate('/');
      }
    })();
  }, []);

  // if (!code) navigate('/');
  return (
    <div>
      <h1>잠시만 기다려주세요</h1>
    </div>
  );
};

export default LoginCallback;
