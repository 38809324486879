import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLogin from '../../Middle/useLogin';
import styles from '../Css/Header.module.css';
import logo from '../../meta/logobig.png';
import banner from '../../meta/topbanner.png';
import useCategory from '../../Middle/useCategory';
import useMenuState from '../../Middle/useMenuState';

function Header() {
  const { loginData } = useLogin();
  const category = useCategory();
  const navigate = useNavigate();
  const { closeMenuTrigger } = useMenuState();
  const [Search, setSearch] = useState('');
  useEffect(() => {
    setSearch('');
  }, [closeMenuTrigger]);
  if (category.firstCategoryIdxList.length === 0) return <div />;
  return (
    <div className={styles.headerDiv}>
      <div className={styles.littleLinkDiv}>
        {
          loginData?.login
            ? <Link to="/mypage/reservation">{`${loginData.info.name} 님`}</Link>
            : <Link to="/login">로그인</Link>
        }
        <div className={styles.littleLine} />
        {
          loginData?.login
            ? <Link to="/logout">로그아웃</Link>
            : <Link to="/signup">회원가입</Link>
        }
      </div>
      <div className={styles.middleDiv}>
        <Link to="/"><img src={logo} alt="로고" /></Link>
        <div className={styles.searchDiv}>
          <form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const value = Search.trim();
            if (value.length > 0) {
              navigate(`/search/${value}`);
            }
          }}
          >
            <input type="text" placeholder="검색어를 입력하세요 (나라, 도시, 상품명으로 검색)" value={Search} onChange={(e) => setSearch(e.target.value)} />
            <button type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </button>
          </form>
        </div>
        <img src={banner} alt="2021 한국여행업협회 선정 우수여행상품" />
      </div>
      <div className={styles.navDiv}>
        <div className={styles.navInner}>
          <div className={styles.dropdown}>
            <Link className={styles.dropbtn} to="/fixed">출발확정여행</Link>
            <div className={styles.dropdownContent}>
              <Link to="/fixed">출발확정여행</Link>
            </div>
          </div>
          {
            category.firstCategoryIdxList.map((i) => (
              <div className={styles.dropdown}>
                <Link className={styles.dropbtn} to={`/category/${category[i].idx}/0`}>{category[i].title}</Link>
                <div className={styles.dropdownContent}>
                  <Link to={`/category/${category[i].idx}/0`}>전체보기</Link>
                  {
                    category[i].secondCategoryIdxList.map((j) => (
                      <Link to={`/category/${category[i].idx}/${category[i].secondCategory[j].idx}`}>{category[i].secondCategory[j].title}</Link>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <div className={styles.dropdown}>
            <Link className={styles.dropbtn} to="/notice">고객센터</Link>
            <div className={styles.dropdownContent}>
              <Link to="/notice">공지사항</Link>
              <Link to="/faq">자주 묻는 질문</Link>
              <Link to="/qna">1:1 문의</Link>
              <Link to="/review">여행후기</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
