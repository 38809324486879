import React from 'react';
import { Link } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLoginCheck from '../../Middle/useLoginCheck';
import BlankMaker from '../Components/BlankMaker';
import CustomTable from '../Components/CustomTable';
import Title from '../Components/Title';
import WithMypageNav from '../Components/WithMypageNav';

const PAGE_SIZE = 10;

function MyReservation() {
  useLoginCheck();
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<{
    idx: number, rsv_number: string, rsv_date: string, title: string, state: string
  }>(
    (index) => `/api/reservation?page=${index + 1}&limit=${PAGE_SIZE}`,
    'reservation',
    PAGE_SIZE,
  );
  return (
    <WithMypageNav>
      <>
        <Title>예약내역</Title>
        <BlankMaker height={10} />
        <CustomTable haveVerticalLine>
          <thead>
            <tr>
              <td>예약번호</td>
              <td>상품명</td>
              <td>예약일자</td>
              <td>상태</td>
            </tr>
          </thead>
          <tbody>
            {
              issues.map((d) => (
                <tr>
                  <td><Link to={`./${d.idx}`}>{d.rsv_number}</Link></td>
                  <td><Link to={`./${d.idx}`}>{d.title}</Link></td>
                  <td>{d.rsv_date}</td>
                  <td>{d.state}</td>
                </tr>
              ))
            }
          </tbody>
        </CustomTable>
      </>
    </WithMypageNav>
  );
}

export default MyReservation;
