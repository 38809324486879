import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLoginCheck from '../../Middle/useLoginCheck';
import LoadMoreButton from '../Components/LoadMoreButton';
import TopBar from '../Components/TopBar';
import styles from '../Css/Notice.module.css';

const PAGE_SIZE = 10;

function MyQNA() {
  const navigate = useNavigate();
  useLoginCheck();
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<{
    idx: number, title: string, is_answer: number, created_at: string, author_name: string
  }>(
    (index) => `/api/user/qna?page=${index + 1}&limit=${PAGE_SIZE}`,
    'qna',
    PAGE_SIZE,
  );
  return (
    <div className={styles.noticeDiv}>
      <TopBar text="내가 작성한 질문" noUnderLine />
      <div className={styles.grayTitleDiv}>
        <div className={styles.grayTitle}>제목</div>
        <div className={styles.grayDate}>작성일</div>
      </div>
      <div className={styles.noticeArticleDiv}>
        {
          issues.map((data) => (
            <Link to={`/qna/${data.idx}`} className={styles.noticeEachArticleDiv}>
              <div className={styles.badgeAndTitle}>
                <div className={`${styles.noticeBadge} ${data.is_answer === 1 && styles.answerBadge}`}>{data.is_answer === 1 ? '답변' : '질문'}</div>
                <div className={styles.noticeTitle}>{data.title}</div>
              </div>
              <div className={styles.noticeInfo}>
                <div>{`작성: ${data.author_name}`}</div>
                <div>{data.created_at}</div>
              </div>
            </Link>
          ))
        }
      </div>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
      <button type="button" onClick={() => navigate('/qna/new')} className={styles.deleteButton}>글쓰기</button>
    </div>
  );
}

export default MyQNA;
