/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useCategory from '../../Middle/useCategory';
import TopBar from '../Components/TopBar';
import styles from '../Css/Category.module.css';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLoginCheck from '../../Middle/useLoginCheck';
import useLogin from '../../Middle/useLogin';
import LoadMoreButton from '../Components/LoadMoreButton';

const PAGE_SIZE = 10;

interface product {
  idx: number,
  title: string,
  description: string,
  price: number,
  thumb_image: string,
  first_category: number,
  second_category?: number,
  first_departure_date?: string,
}

function EachProduct(props:{
  info: product
  deleteFunc: (idx: number) => void
}) {
  const { info, deleteFunc } = props;
  const category = useCategory();
  const {
    idx, title, description, price, thumb_image, first_category,
  } = info;
  return (
    <Link
      to={info.first_departure_date ? `/product/${idx}/${info.first_departure_date}` : ''}
      className={styles.eachProdLink}
      onClick={() => {
        if (!(info.first_departure_date)) {
          alert('출발 가능한 일정이 없습니다.');
        }
      }}
    >
      <div className={styles.eachProdDiv}>
        <div className={styles.prodImgDiv}>
          <img src={thumb_image} alt={`${title} 이미지`} />
        </div>
        <div className={styles.prodTextDiv}>
          <div className={styles.prodCateDiv}>
            {title}
          </div>
          <div className={styles.prodDescDiv}>{description}</div>
          <div className={styles.prodPriceWrapper}>
            <div className={styles.prodPriceDiv}>
              {price.toLocaleString()}
              원
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteFunc(idx);
              }}
              style={{
                padding: '1px 5px 1px 5px',
                border: '1px solid #DCDCE6',
                marginLeft: '10px',
                fontSize: '12px',
                color: '#B6BAC0',
              }}
            >
              삭제하기
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}

function CategoryInner() {
  const { loginData } = useLogin();
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<product>(
    (index) => `/api/good/like?page=${index + 1}&limit=${PAGE_SIZE}`,
    'cart',
    PAGE_SIZE,
  );
  const deleteFunc = async (idx: number) => {
    if (!loginData?.login) return;
    await axios.delete(`/api/good/like/${idx}`, loginData.axiosConfig);
    mutate();
  };
  return (
    <div className={styles.categoryDiv}>
      <TopBar text="찜 목록" />
      <div className={styles.infoLineDiv}>
        <div className={styles.prodNumDiv}>
          상품
          <span className={styles.prodNumColor}>
            {FullSize}
            개
          </span>
        </div>
        <div />

      </div>
      {issues.map((d, idx) => (
        <>
          <EachProduct info={d} deleteFunc={deleteFunc} />
          {idx < issues.length - 1 && <div className={styles.line} />}
        </>
      ))}
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

function MyLike() {
  const category = useCategory();
  useLoginCheck();
  const loaded = category.firstCategoryIdxList.length !== 0;
  return loaded ? <CategoryInner /> : <div />;
}

export default MyLike;
