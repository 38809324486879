/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from '../Css/Menu.module.css';
import useMenuState from '../../Middle/useMenuState';
import useCategory from '../../Middle/useCategory';
import useLogin from '../../Middle/useLogin';
import { CommunityIcon } from '../../meta/icons';

function Distinct() {
  return <div className={styles.menuDistinct} />;
}

function LittleMenu(props:{
  iconUrl: string,
  title: string,
  subList: {title: string, url: string}[],
 }) {
  const { iconUrl, title, subList } = props;
  const [Detail, setDetail] = useState<boolean>(false);
  const { closeMenuTrigger } = useMenuState();
  useEffect(() => {
    setDetail(false);
  }, [closeMenuTrigger]);
  return (
    <div className={`${styles.BigMenuDiv} ${Detail && styles.showDetail}`}>
      <div className={styles.littleMenuDiv} onClick={() => { setDetail((val) => !val); }}>
        <div className={styles.iconTitleDiv}>
          <img src={iconUrl} alt={`${title} 아이콘`} />
          <div>{title}</div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" fill="#000000">
          <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
        </svg>
      </div>
      <Distinct />
      <div className={styles.littleList} style={{ height: `${subList.length * 31}px` }}>
        {subList.map((data) => (
          // <Link to={`/category/${num}/${secondCategory[data].idx}`}>
          <Link to={data.url}>
            {data.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

function Menu() {
  const category = useCategory();
  const { menuState, closeAllMenu } = useMenuState();
  const { loginData } = useLogin();
  return (
    <div className={`${styles.menuDiv} ${menuState === 'header' && styles.menuOn}`}>
      <div className={styles.menuInner}>
        <div className={styles.loginTextLineDiv}>
          {
            loginData?.login
              ? <div>{`${loginData.info.name} 님`}</div>
              : <div>로그인하세요.</div>
          }
          <button type="button" onClick={closeAllMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
            </svg>
          </button>
        </div>
        <div className={styles.buttonsDiv}>
          <Link to={loginData?.login ? '/logout' : '/login'}><div>{loginData?.login ? '로그아웃' : '로그인'}</div></Link>
          <Link to={loginData?.login ? '/mypage' : '/signup'}><div>{loginData?.login ? '마이페이지' : '회원가입'}</div></Link>
        </div>
        {category.firstCategoryIdxList.map(
          (num) => (
            <LittleMenu
              title={category[num].title}
              iconUrl={category[num].icon_image}
              subList={[{
                title: '전체보기',
                url: `/category/${num}/${0}`,
              }, ...category[num].secondCategoryIdxList.map((secondNum) => ({
                title: category[num].secondCategory[secondNum].title,
                url: `/category/${num}/${secondNum}`,
              }))]}
            />
          ),
        )}
        <LittleMenu
          title="커뮤니티"
          iconUrl={CommunityIcon}
          subList={[
            { title: '공지사항', url: '/notice' },
            { title: '자주묻는질문', url: '/faq' },
            { title: '1:1 문의', url: '/qna' },
            { title: '여행후기', url: '/review' },
          ]}
        />
      </div>

    </div>
  );
}

export default Menu;
