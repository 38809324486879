import React, { useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import styles from '../Css/Footer.module.css';
import logo from '../../meta/logo.png';
import kakaotalkIcon from '../../meta/kakaotalkIcon.png';
import bandIcon from '../../meta/bandIcon.png';
import hanatourIcon from '../../meta/hanatourIcon.png';

function Footer() {
  const [Show, setShow] = useState(false);
  const { data } = useSWR('/api/landing/company', async (url) => {
    const res = await axios.get<{result:{
      company_name: string
      [x:string]: string
    }}>(url);
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return data
    ? (
      <div className={`${styles.footerDiv} ${Show && styles.detailShow}`}>
        <div className={styles.contactDiv}>
          <img src={logo} alt="" className={styles.logo} />
          <div className={styles.contactPhone}>{`고객센터 ${data.phone}`}</div>
          <div className={styles.workAndIconDiv}>
            <div className={styles.workTimeDiv}>
              <div>{`평일 ${data.officehour_weekday}`}</div>
              <div>{`${data.officehour_holiday} 휴무`}</div>
            </div>
            <div className={styles.iconDiv}>
              <a href="http://alps.hanatour.com">
                <img src={hanatourIcon} alt="하나투어 코브랜드 보기" />
              </a>
              <a href="http://pf.kakao.com/_xnukHC">
                <img src={kakaotalkIcon} alt="카카오 채널 보기" />
              </a>
              <a href="https://band.us/band/82768578">
                <img src={bandIcon} alt="네이버 밴드 바로가기" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.infoDiv}>
          <div className={styles.infoAlwaysDiv}>
            <div className={styles.infoLinksDiv}>
              <div>
                <a href="/notice/21">이용약관</a>
                <span className={styles.divideLine}>|</span>
                <a href="/notice/20">개인정보 처리방침</a>
                <span className={styles.divideLine}>|</span>
                <a href="/notice/18">사업자 정보확인</a>
              </div>
            </div>
            <div className={styles.rightsAndOpenDiv}>
              <div>{`ⓒ 2018 ${data.company_name} All Rights Reserved.`}</div>
              <button type="button" onClick={() => setShow((val) => !val)}>
                사업자 정보
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
          <div className={styles.infoDetailDiv}>
            <div className={styles.detailContactDiv}>
              <div>{`Tel: ${data.phone}`}</div>
              <div>{`Email: ${data.email}`}</div>
              <div>{`Fax: ${data.fax}`}</div>
            </div>
            <div className={styles.companyInfoDiv}>
              <div>{`${data.company_name} | 대표: ${data.ceo_name}`}</div>
              <div>{`사업자등록번호: ${data.business_number}`}</div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                </svg>
                {`${data.post_code} ${data.address}`}
              </div>
              <div>{`통신판매업신고번호: ${data.business_report}`}</div>
            </div>
          </div>
        </div>
      </div>
    ) : <div />;
}

export default Footer;
