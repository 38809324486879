/* eslint-disable react/require-default-props */
import React from 'react';
import argsToPX from '../../Middle/argsToPX';

function BlankMaker(props: {
  width?: number|string,
  height?: number|string
}) {
  const { width, height } = props;
  return (
    <div style={{
      width: argsToPX(width) || '1px',
      height: argsToPX(height) || '1px',
    }}
    />
  );
}

export default BlankMaker;
