/* eslint-disable no-empty */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../Middle/useLogin';

function Logout() {
  const { loginData, loginMutate, loginIsValidating } = useLogin();
  const navigate = useNavigate();
  const doLogout = async () => {
    // console.log(loginData, loginIsValidating);
    if (loginData?.login) {
      try {
        const res = await axios.post('/api/user/sign-out', '', loginData.axiosConfig);
        localStorage.removeItem('accessToken');
        loginMutate();
      } catch (e) {
        console.log(e);
      }
    }
    if (!loginIsValidating) navigate('/');
  };
  useEffect(() => {
    doLogout();
  }, [loginIsValidating]);

  return (
    <>
      <div style={{
        fontSize: '25px',
        marginTop: '30px',
      }}
      >
        로그아웃 하는 중...
      </div>
      <div>
        이 화면에서 계속 멈춰있다면 상단의 로고를 클릭해 홈화면으로 돌아가세요
      </div>
    </>
  );
}

export default Logout;
