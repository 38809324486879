/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import TopBar from '../Components/TopBar';
import styles from '../Css/EachNotice.module.css';

function ReviewNew() {
  const navigate = useNavigate();
  const { loginData, getLoginConfig } = useLogin();
  useLoginCheck(() => navigate('/review'));
  const [Data, setData] = useState<{
    title: string
    content: string
  }>({
    title: '', content: '',
  });
  const check = () => {
    const ans = {
      isError: false,
      errorMsg: '',
    };
    const setError = (msg: string) => {
      ans.isError = true;
      ans.errorMsg = `${msg}을(를) 확인하세요`;
    };
    if (Data.title.trim().length === 0) setError('제목');
    else if (Data.content.trim().length === 0) setError('내용');
    return ans;
  };
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="여행후기 작성" />
      <div className={styles.newQNADiv}>
        <input type="text" placeholder="제목" value={Data.title} onChange={(e) => setData({ ...Data, title: e.target.value })} />
        <textarea autoComplete="false" placeholder="내용" value={Data.content} onChange={(e) => setData({ ...Data, content: e.target.value })} />
      </div>
      <button
        className={styles.deleteButton}
        type="button"
        onClick={async () => {
          const checked = check();
          if (checked.isError) {
            alert(checked.errorMsg);
            return;
          }
          try {
            const res = await axios.post<{statusCode: number}>('/api/board/review', Data, getLoginConfig());
            if (res.data.statusCode !== 201) throw Error;
            alert('등록했습니다.');
            navigate('/review');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        글쓰기

      </button>
    </div>
  );
}

export default ReviewNew;
