/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ko } from 'date-fns/locale';
import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from 'react-scroll-section';
import 'react-datepicker/dist/react-datepicker.css';
import '../../Mobile/Css/Calendar.css';
import sstyles from '../../Mobile/Css/Product.module.css';
import styles from '../Css/Product.module.css';
import useLogin from '../../Middle/useLogin';
import '../../Mobile/Css/ckeditor.css';
import LeftRight from '../Components/LeftRight';
import CustomTable from '../Components/CustomTable';

export interface monthDataI {
  idx: number,
  departure_date: string,
  arrival_date: string,
  maximum: number,
  minimum: number,
  current: number,
  state: number
}

export interface eachDataI {
  'good_idx': number,
  'title': string,
  'images': string[],
  'description': string,
  'idx': number,
  'departure_start_time': string,
  'departure_start_time_korea': number,
  'departure_end_time': string,
  'departure_end_time_korea': number,
  'departure_transportation': string,
  'arrival_start_time': string,
  'arrival_start_time_korea': number,
  'arrival_end_time': string,
  'arrival_end_time_korea': number,
  'arrival_transportation': string,
  description_perks: string
  description_include: string
  description_exclude: string
  description_notice: string
  schedule_text: string
  'minimum': number,
  'maximum': number,
  'current': number,
  'nights': number,
  'days': number,
  'options': {
    'idx': number,
    'option_name': string,
    'adult_price': number,
    'child_price': number|null,
    'infant_price': number|null,
    'baby_price': number|null,
    'maximum': number|null
  }[],
  'additional_option': {
    'idx': number,
    'option_name': string,
    'price': number,
    'maximum': number|null
  }[],
}

const statusToString = ['마감임박', '예약가능', '출발확정', '예약마감'];

export const dateObjToString = (date: Date|null) => {
  if (!date) return 'null';
  date.setHours(22);
  return date.toISOString().slice(0, 10);
};

function Product() {
  const { idx, date } = useParams();
  const { loginData } = useLogin();
  const sections = useScrollSections();
  const [DateObj, setDateObj] = useState<Date | null>(new Date(`${date}`));
  useEffect(() => {
    setDateObj(new Date(`${date}`));
  }, [date]);
  const [YearMonth, setYearMonth] = useState<[string, string]>(date ? [date.slice(0, 4), date.slice(5, 7)] : ['0000', '00']);
  const [InfoPage, setInfoPage] = useState<'info'|'schedule'|'caution'>('info');
  const navigate = useNavigate();
  const { data: monthData } = useSWR(`/api/good/schedule?idx=${idx}&year=${YearMonth[0]}&month=${YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<{result: monthDataI[]}>(url);
    const { data } = res;
    const dataObj: {[dateString:string]:monthDataI} = {};
    data.result.map((ed) => {
      dataObj[ed.departure_date] = ed;
      return ed;
    });
    return dataObj;
  });
  const { data: eachData, error: eachError } = useSWR(`/api/good/schedule/info?idx=${idx}&date=${date}`, async (url: string) => {
    const res = await axios.get<{result: eachDataI}>(url);
    const { data } = res;
    return data.result;
  });
  useEffect(() => {
    if (eachError) navigate('/');
  }, [eachError]);
  console.log(sections);

  const changeMonth = (diff: number) => {
    const today = new Date();
    let year = parseInt(YearMonth[0], 10);
    let month = parseInt(YearMonth[1], 10);
    month += diff;
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
    if (today.getFullYear() > year
     || (today.getFullYear() === year && today.getMonth() + 1 > month)) return;
    const textMonth = `${month}`.length === 1 ? `0${month}` : `${month}`;
    setYearMonth([`${year}`, `${textMonth}`]);
  };
  const arrowSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
    </svg>
  );

  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
      monthDate,
      decreaseMonth, increaseMonth,
      prevMonthButtonDisabled, nextMonthButtonDisabled,
    } = props;
    return (
      <div className={sstyles.monthPickerDiv}>
        <button type="button" className={`${prevMonthButtonDisabled && sstyles.btnDisable}`} onClick={() => { if (prevMonthButtonDisabled) return; decreaseMonth(); changeMonth(-1); }}>{arrowSvg}</button>
        <div className={sstyles.monthPickerText}>{`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`}</div>
        <button type="button" className={`${nextMonthButtonDisabled && sstyles.btnDisable}`} onClick={() => { if (nextMonthButtonDisabled) return; increaseMonth(); changeMonth(1); }}>{arrowSvg}</button>
      </div>
    );
  };

  const renderDayContents = (dayOfMonth: number, dateDay: Date|undefined) => {
    if (!dateDay) return <div />;
    const dateString = dateObjToString(dateDay);
    let status = 4;
    if (monthData && monthData[dateString]) {
      status = monthData[dateString].state;
    }
    const style = { '--dotColor': ['#FF0057', '#ACD2FF', '#0068FF', '#191919', 'transparent'][status] } as React.CSSProperties;
    return (
      <div className={`${sstyles.calenDayDiv} ${(status === 4 || status === 3) && sstyles.dayDisabled}`} style={style}>
        {dateDay.getDate()}
        <div />
      </div>
    );
  };
  const datepicker = (
    <>
      <DatePicker
        selected={DateObj}
        onChange={(dt) => {
          if (monthData
          && monthData[dateObjToString(dt)]
          && monthData[dateObjToString(dt)].state !== 3) {
            setDateObj(dt);
            navigate(`/product/${idx}/${dateObjToString(dt)}`);
          }
        }}
        locale={ko}
        dateFormat="Pp"
        inline
        className="asdf"
        renderDayContents={renderDayContents}
        minDate={new Date()}
        renderCustomHeader={renderCustomHeader}
        disabledKeyboardNavigation
      />
      <div className={sstyles.dotInfoDiv}>
        <div>
          <div style={{ backgroundColor: '#FF0057' }} />
          마감임박
        </div>
        <div>
          <div style={{ backgroundColor: '#ACD2FF' }} />
          예약가능
        </div>
        <div>
          <div style={{ backgroundColor: '#0068FF' }} />
          출발확정
        </div>
        <div>
          <div style={{ backgroundColor: '#191919' }} />
          예약마감
        </div>
      </div>
    </>
  );

  const getTimeComponent = (str: string, isKorea: number) => (
    <div className={styles.time}>
      <span className={isKorea === 1 ? styles.blue : styles.red}>{isKorea === 1 ? '한국기준' : '현지기준'}</span>
      {str}
    </div>
  );

  if (!eachData) {
    return <div />;
  }

  return (
    <div className={styles.productWrapper}>
      <div className={styles.productSideDiv}>
        <div className={styles.sideLine}>
          <div className={styles.sideTitle}>예약상세</div>
          <button
            className={styles.likeBtn}
            type="button"
            onClick={async () => {
              try {
                if (!(loginData?.login)) {
                  alert('로그인이 필요합니다.');
                  return;
                }
                const res = await axios.post<{statusCode: number}>(`/api/good/like/${idx}`, {}, { ...loginData.axiosConfig, validateStatus: (status) => status < 500 });
                const { data } = res;
                const { statusCode } = data;
                if (statusCode === 201) alert('찜했습니다.');
                if (statusCode === 401) alert('이미 찜한 상품입니다.');
              } catch {
              // asdf
              }
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
            찜하기
          </button>
        </div>
        <div className={styles.sideDate}>{`출발일: ${eachData.departure_start_time}`}</div>
        <div className={styles.sideDate}>{`도착일: ${eachData.arrival_end_time}`}</div>
        <div className={styles.sideDash} />
        <div className={styles.sidePeople}>{`예약 가능 인원: ${eachData.maximum - eachData.current}명`}</div>
        <div className={styles.sidePeople}>{`현재 예약 인원: ${eachData.current}명`}</div>
        <div className={styles.sidePeople}>{`최소 출발 인원: ${eachData.minimum}명`}</div>
        <button onClick={() => navigate(`/reserve/${idx}/${date}`)} type="button" className={styles.sideBtn}>예약하기</button>
      </div>
      <div className={styles.productDiv}>
        <div className={styles.bigTitle}>{eachData.title}</div>
        <div className={styles.titleDesc}>{eachData.description}</div>
        <img className={styles.productImg} src={eachData.images[0]} alt={eachData.title} />
        <div className={styles.miniTitle}>일정선택</div>
        <div className={styles.dateWrapper}>
          {datepicker}
        </div>
        <div className={styles.miniTitle}>여행 주요 일정 및 요금</div>
        <div className={styles.leftRightWrapper}>
          <LeftRight leftWidth={80}>
            <div>
              <div>
                <div>여행기간</div>
                <div>{`${eachData.nights}박 ${eachData.days}일`}</div>
              </div>
            </div>
            <div>
              <div>
                <div>출발</div>
                <div>
                  {getTimeComponent(
                    eachData.departure_start_time,
                    eachData.departure_start_time_korea,
                  )}
                  {eachData.departure_transportation}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>도착</div>
                <div>
                  {getTimeComponent(
                    eachData.arrival_end_time,
                    eachData.arrival_end_time_korea,
                  )}
                  {eachData.arrival_transportation}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>예약현황</div>
                <div>
                  {`예약 가능 인원: ${eachData.maximum - eachData.current}명 / 현재 예약 인원: ${eachData.current}명 / 최소 출발 인원: ${eachData.minimum}명`}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>요금</div>
                <div>
                  <CustomTable haveVerticalLine>
                    <thead>
                      <tr>
                        <td>숙박구분</td>
                        <td>성인</td>
                        <td>소아</td>
                        <td>유아</td>
                        <td>영아</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      eachData.options.map((d) => (
                        <tr>
                          <td>{d.option_name}</td>
                          <td>{`${d.adult_price.toLocaleString()}원`}</td>
                          <td>{d.child_price ? `${d.child_price.toLocaleString()}원` : ''}</td>
                          <td>{d.infant_price ? `${d.infant_price.toLocaleString()}원` : ''}</td>
                          <td>{d.baby_price ? `${d.baby_price.toLocaleString()}원` : ''}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </CustomTable>
                </div>
              </div>
            </div>
            {
            eachData.additional_option.length > 0 ? (
              <div>
                <div>
                  <div>추가옵션</div>
                  <div>
                    {
                      eachData.additional_option.map((d) => (`${d.option_name}(${d.price.toLocaleString()}원)`)).join(' / ')
                    }
                  </div>
                </div>
              </div>
            ) : ''
          }
          </LeftRight>
        </div>
        <div className={styles.links}>
          {
            sections.map((d) => (
              <button type="button" onClick={d.onClick} className={`${d.selected && styles.selected}`}>{d.id}</button>
            ))
          }
        </div>
        <Section id="여행일정" className={styles.section}>
          <div className={styles.miniTitle}>여행일정</div>
          <div
            className={`${styles.htmlContent} ck-content`}
            dangerouslySetInnerHTML={{ __html: eachData.schedule_text }}
          />
        </Section>
        <Section id="상품특전" className={styles.section}>
          <div className={styles.miniTitle}>상품특전</div>
          <div
            className={`${styles.htmlContent} ck-content`}
            dangerouslySetInnerHTML={{ __html: eachData.description_perks }}
          />
        </Section>
        <Section id="포함사항" className={styles.section}>
          <div className={styles.miniTitle}>포함사항</div>
          <div
            className={`${styles.htmlContent} ck-content`}
            dangerouslySetInnerHTML={{ __html: eachData.description_include }}
          />
        </Section>
        <Section id="불포함사항" className={styles.section}>
          <div className={styles.miniTitle}>불포함사항</div>
          <div
            className={`${styles.htmlContent} ck-content`}
            dangerouslySetInnerHTML={{ __html: eachData.description_exclude }}
          />
        </Section>
        <Section id="필독유의사항" className={styles.section}>
          <div className={styles.miniTitle}>필독유의사항</div>
          <div
            className={`${styles.htmlContent} ck-content`}
            dangerouslySetInnerHTML={{ __html: eachData.description_notice }}
          />
        </Section>
      </div>
    </div>
  );
}

export default Product;
