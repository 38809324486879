import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';
import LoadMoreButton from '../Components/LoadMoreButton';
import TopBar from '../Components/TopBar';
import styles from '../Css/Notice.module.css';

const PAGE_SIZE = 10;

function Review() {
  const navigate = useNavigate();
  const { loginData } = useLogin();
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<{
    idx: number, title: string, views: number, name: string
  }>(
    (index) => `/api/board/review?page=${index + 1}&limit=${PAGE_SIZE}`,
    'review',
    PAGE_SIZE,
  );
  return (
    <div className={styles.noticeDiv}>
      <TopBar text="여행후기" noUnderLine />
      <div className={styles.grayTitleDiv}>
        <div className={styles.grayTitle}>제목</div>
        <div className={styles.grayDate}>작성자</div>
      </div>
      <div className={styles.noticeArticleDiv}>
        {
          issues.map((data) => (
            <Link to={`./${data.idx}`} className={styles.noticeEachArticleDiv}>
              <div className={styles.badgeAndTitle}>
                <div className={styles.noticeTitle}>{data.title}</div>
              </div>
              <div className={styles.noticeInfo}>
                <div>{`작성: ${data.name}`}</div>
                <div>{`조회수: ${data.views.toLocaleString()}회`}</div>
              </div>
            </Link>
          ))
        }
      </div>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
      <button
        type="button"
        onClick={() => {
          if (loginData?.login) navigate('./new');
          else alert('로그인이 필요합니다.');
        }}
        className={styles.deleteButton}
      >
        후기 작성
      </button>
    </div>
  );
}

export default Review;
