/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageChangeEvent from './Middle/PageChangeEvent';
import useMenuState from './Middle/useMenuState';
import menuStyles from './Mobile/Css/Menu.module.css';
import AccountDelete from './Mobile/Page/AccountDelete';
import Category from './Mobile/Page/Category';
import EachNotice from './Mobile/Page/EachNotice';
import EachQNA from './Mobile/Page/EachQNA';
import EachReview from './Mobile/Page/EachReview';
import FAQ from './Mobile/Page/FAQ';
import Fixed from './Mobile/Page/Fixed';
import Footer from './Mobile/Page/Footer';
import Header from './Mobile/Page/Header';
import Login from './Mobile/Page/Login';
import LoginCallback from './Mobile/Page/LoginCallback';
import Logout from './Mobile/Page/Logout';
import Main from './Mobile/Page/Main';
import MyInfoChange from './Mobile/Page/MyInfoChange';
import MyLike from './Mobile/Page/MyLike';
import Mypage from './Mobile/Page/Mypage';
import MyQNA from './Mobile/Page/MyQNA';
import MyReservation from './Mobile/Page/MyReservation';
import MyResInfo from './Mobile/Page/MyResInfo';
import MyReview from './Mobile/Page/MyReview';
import Notice from './Mobile/Page/Notice';
import Payment from './Mobile/Page/Payment';
import PaymentCallback from './Mobile/Page/PaymentCallback';
import Product from './Mobile/Page/Product';
import QNA from './Mobile/Page/QNA';
import QNANew from './Mobile/Page/QNANew';
import Reserve from './Mobile/Page/Reserve';
import Review from './Mobile/Page/Review';
import ReviewNew from './Mobile/Page/ReviewNew';
import Signup from './Mobile/Page/Signup';

function Mobile() {
  const { showBlur, closeAllMenu } = useMenuState();
  return (
    <div style={{
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <PageChangeEvent />
      <Header />
      <div
        className={`${menuStyles.blurDiv} ${showBlur && menuStyles.blurOn}`}
        onClick={() => { closeAllMenu(); }}
      />
      <div style={{
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxSizing: 'border-box',
      }}
      >
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logincallback/kakao" element={<LoginCallback type="kakao" />} />
          <Route path="/logincallback/naver" element={<LoginCallback type="naver" />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/accountdelete" element={<AccountDelete />} />
          <Route path="/signup" element={<Signup type="default" />} />
          <Route path="/signup/kakao/:accessToken" element={<Signup type="kakao" />} />
          <Route path="/signup/naver/:accessToken" element={<Signup type="naver" />} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/mypage/like" element={<MyLike />} />
          <Route path="/mypage/infochange" element={<MyInfoChange />} />
          <Route path="/mypage/reservation" element={<MyReservation />} />
          <Route path="/mypage/reservation/:idx" element={<MyResInfo />} />
          <Route path="/mypage/reservation/:idx/payment" element={<Payment />} />
          <Route path="/paymentcallback/:idx" element={<PaymentCallback />} />
          <Route path="/mypage/review" element={<MyReview />} />
          <Route path="/mypage/qna" element={<MyQNA />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:idx" element={<EachNotice />} />
          <Route path="/qna" element={<QNA />} />
          <Route path="/qna/:idx" element={<EachQNA />} />
          <Route path="/qna/new" element={<QNANew />} />
          <Route path="/review" element={<Review />} />
          <Route path="/review/:idx" element={<EachReview />} />
          <Route path="/review/new" element={<ReviewNew />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/product/:idx/:date" element={<Product />} />
          <Route path="/reserve/:idx/:date" element={<Reserve />} />
          <Route path="/category/:firstIdx/:secondIdx" element={<Category isSearch={false} />} />
          <Route path="/search/:keyword" element={<Category isSearch />} />
          <Route path="/fixed" element={<Fixed />} />
          <Route path="/test" element={<div>테스트</div>} />
          <Route path="/list" element={<div>리스트</div>} />
          <Route path="*" element={<div>없음</div>} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default Mobile;
