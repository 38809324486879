import React from 'react';
import WithNav from './WithNav';

function WithMypageNav(props: { children: React.ReactChild|React.ReactChild[] }) {
  const { children } = props;
  return (
    <WithNav
      title="마이페이지"
      navList={[
        {
          title: '예약내역',
          url: '/mypage/reservation',
        },
        {
          title: '적립금 내역',
          url: '/mypage/mileage',
        },
        {
          title: '찜 목록',
          url: '/mypage/like',
        },
        {
          title: '내 질문',
          url: '/mypage/qna',
        },
        {
          title: '내 여행후기',
          url: '/mypage/review',
        },
        {
          title: '회원정보 변경',
          url: '/mypage/infochange',
        },
        {
          title: '회원 탈퇴',
          url: '/accountdelete',
        },
      ]}
    >
      {children}
    </WithNav>
  );
}

export default WithMypageNav;
