import axios from 'axios';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import TopBar from '../Components/TopBar';
import styles from '../Css/EachNotice.module.css';

function EachNotice() {
  const { idx } = useParams();
  const navigate = useNavigate();
  const { data, error } = useSWR(`/api/board/notice/${idx}`, async (url) => {
    const res = await axios.get<{ result: {
       idx: number, title: string, content: string, views: number, created_at: number
      } }>(url);
    return res.data.result;
  });
  if (error) navigate(-1);
  if (!data) return <div />;
  return (
    <div className={styles.eachNoticeDiv}>
      <TopBar text="공지사항" />
      <div className={styles.infoDiv}>
        <div className={styles.infoTitleDiv}>{data.title}</div>
        <div className={styles.infoInfoDiv}>
          <div>작성자: 관리자</div>
          <div>{`작성일: ${data.created_at}`}</div>
          <div>{`조회수: ${data.views.toLocaleString('ko-kr')}회`}</div>
        </div>
      </div>
      <div className={`${styles.contentsDiv} ck-content`} dangerouslySetInnerHTML={{ __html: data.content }} />
    </div>
  );
}

export default EachNotice;
