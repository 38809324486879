import React from 'react';
import { useParams } from 'react-router-dom';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../../Mobile/Components/LoadMoreButton';
import { productI } from '../../Mobile/Page/Category';
import EachProduct from '../Components/EachProduct';
import WithNav from '../Components/WithNav';
import styles from '../Css/Search.module.css';

const PAGE_SIZE = 10;

function Search() {
  const { keyword } = useParams();
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<productI>(
    (index) => `/api/good?keyword=${keyword}&page=${index + 1}&limit=${PAGE_SIZE}`,
    'good',
    PAGE_SIZE,
  );
  return (
    <WithNav title="검색결과" navList={[]}>
      <>
        <div className={styles.title}>{`'${keyword}'에 대한 검색결과 총 ${FullSize}개입니다.`}</div>
        <div className={styles.blank} />
        {
          issues.map((d) => <EachProduct info={d} />)
        }
        <LoadMoreButton {...{
          isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
        }}
        />
      </>
    </WithNav>
  );
}

export default Search;
