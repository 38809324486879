import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useCategory from '../../Middle/useCategory';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import LoadMoreButton from '../../Mobile/Components/LoadMoreButton';
import { productI } from '../../Mobile/Page/Category';
import EachProduct from '../Components/EachProduct';
import Title from '../Components/Title';
import WithNav from '../Components/WithNav';
import styles from '../Css/Category.module.css';

const PAGE_SIZE = 10;

function CategoryInner(props: { url: string, title: string}) {
  const { url, title } = props;
  const navigate = useNavigate();
  const [Order, setOrder] = useState<'popular'|'cheap'|'expensive'|'default'>('popular');
  const orderQuery = Order === 'default' ? '' : `&order=${Order}`;
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize,
  } = useInfiniteLoad<productI>(
    (index) => `${url}${orderQuery}&page=${index + 1}&limit=${PAGE_SIZE}`,
    'good',
    PAGE_SIZE,
  );
  const { data: popularData } = useSWR(`${url}&order=popular&page=1&limit=4`, async (nowUrl) => {
    const res = await axios.get<{result: {count: number, good: productI[]}}>(nowUrl);
    return res.data.result;
  });
  const orderChange = (newOrder: typeof Order) => {
    if (Order !== newOrder) setOrder(newOrder);
  };
  return (
    <>
      {
        (popularData && popularData.count > 0) && (
        <>
          <Title>{`${title} 인기상품`}</Title>
          <div className={styles.popularListDiv}>
            {
              popularData.good.map((d) => (
                <button
                  type="button"
                  className={styles.popularDiv}
                  onClick={() => {
                    if (d.first_departure_date) {
                      navigate(`/product/${d.idx}/${d.first_departure_date}`);
                    } else {
                      alert('출발 가능한 일정이 없습니다.');
                    }
                  }}
                >
                  <img src={d.thumb_image} alt={d.title} />
                  <div className={styles.popularTitle}>{d.title}</div>
                  <div className={styles.popularDesc}>{d.description}</div>
                  <div className={styles.popularPrice}>{`${d.price.toLocaleString()}원`}</div>
                </button>
              ))
            }
          </div>
        </>
        )
      }
      <div className={styles.typeSelectDiv}>
        <div className={styles.typeTitle}>{title}</div>
        <div className={styles.orderListDiv}>
          <button onClick={() => orderChange('popular')} type="button" className={`${styles.orderDiv} ${Order === 'popular' && styles.selectedOrder}`}>인기순</button>
          <button onClick={() => orderChange('default')} type="button" className={`${styles.orderDiv} ${Order === 'default' && styles.selectedOrder}`}>최신순</button>
          <button onClick={() => orderChange('expensive')} type="button" className={`${styles.orderDiv} ${Order === 'expensive' && styles.selectedOrder}`}>가격높은순</button>
          <button onClick={() => orderChange('cheap')} type="button" className={`${styles.orderDiv} ${Order === 'cheap' && styles.selectedOrder}`}>가격낮은순</button>
        </div>
      </div>
      <div className={styles.productList}>
        {
          issues.map((d) => <EachProduct info={d} />)
        }
      </div>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </>
  );
}

function Category(props: { isFixed: boolean }) {
  const { isFixed } = props;
  const params = useParams();
  const category = useCategory();
  const firstIdx = parseInt(params.firstIdx as string, 10);
  const secondIdx = parseInt(params.secondIdx as string, 10);
  const loaded = category.firstCategoryIdxList.length !== 0;
  const navigate = useNavigate();
  let valid = isFixed;
  const notExistNotify = () => {
    navigate('/');
  };
  if (loaded && !isFixed) {
    const firstIdxInvalid = !category[firstIdx];
    if (firstIdxInvalid) {
      notExistNotify();
    } else if (secondIdx !== 0) {
      const secondIdxInvalid = !category[firstIdx].secondCategory[secondIdx];
      if (secondIdxInvalid) {
        notExistNotify();
      } else {
        valid = true;
      }
    } else {
      valid = true;
    }
  }

  if (!(loaded && valid)) {
    notExistNotify();
    return <div />;
  }
  if (!isFixed) {
    const firstCateTitle = category[firstIdx].title;
    const secondCateTitle = secondIdx === 0
      ? firstCateTitle
      : `${firstCateTitle} - ${category[firstIdx].secondCategory[secondIdx].title}`;
    const urlSecondAdder = secondIdx === 0 ? '' : `&second_category_idx=${secondIdx}`;
    return (
      <WithNav
        title={category[firstIdx].title}
        navList={[{
          title: '전체보기', url: `/category/${firstIdx}/0`,
        }, ...category[firstIdx].secondCategoryIdxList.map((j) => ({
          title: category[firstIdx].secondCategory[j].title,
          url: `/category/${firstIdx}/${category[firstIdx].secondCategory[j].idx}`,
        }))]}
      >
        <CategoryInner title={secondCateTitle} url={`/api/good?first_category_idx=${firstIdx}${urlSecondAdder}`} />
      </WithNav>
    );
  }
  return (
    <WithNav
      title="출발확정여행"
      navList={[{
        title: '출발확정여행', url: '/fixed',
      }]}
    >
      <CategoryInner title="출발확정여행" url="/api/good?fixed=true" />
    </WithNav>
  );
}

export default Category;
